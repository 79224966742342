import styled from 'styled-components';

import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';
import Variables from 'styles/Variables';
import { StyleProps } from './types';

export const BoxFeedback = styled.div`
	border-radius: 8px;
	background: #fff;
	width: 90vw;
	max-width: 900px;
	margin: 50px auto;
	padding: 15px;
	box-shadow: 0 2px 10px 0 rgba(0,0,0,.05);
`;

export const OrderInfo = styled.div`

	${breakAt(BreakpointsSizes.md)} {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`;

export const OrderInfoTitle = styled.div`
	font-size: 24px;
    margin-bottom: 20px;
	color: #5cb85c;
	display: flex;
	align-items: center;
`;

export const OrderInfoTitlePending = styled(OrderInfoTitle)`
	color: #EDE04D;
`;

export const OrderText = styled.div`
	font-size: 18px;
    margin-bottom: 10px;
`;

export const OrderNumberTitle = styled.div`
	font-size: 14px;
	text-transform: uppercase;
    margin-bottom: 5px;
`;

export const OrderNumber = styled.div`
	font-size: 16px;
	text-transform: uppercase;
	font-weight: bold;
`;

export const OrderStatusBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	white-space: nowrap;
`;

export const OrderStatus = styled.div`
	margin-bottom: 10px;
	display: flex;
	align-items: center;
`;

export const QRCode = styled.img`
	height: 180px;
	width: 180px;
`;

export const PixCodeKey = styled.textarea`
	margin-bottom: 0.75rem;
	min-height: 120px;
	min-width: 260px;
	padding: 0.5rem;
	resize: none;
`;

// ----------------
export const Event = styled.div`
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	padding: 30px 0 30px;
	margin-top: 15px;

	${breakAt(BreakpointsSizes.md)} {
		display: flex;
	}
`;

export const EventImage = styled.div`
	height: 180px;
	width: 100%;
	max-width: 300px;
	margin: 0 0 15px;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	background-image: url(${({ thumbnail }: StyleProps) => thumbnail || ''});

	${breakAt(BreakpointsSizes.md)} {
		margin: 0 15px 0 0;
	}
`;

export const Name = styled.div`
	font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
`;

export const EventInfo = styled.div`
	display: flex;
	align-items: center;
	font-size: 14px;
    margin-bottom: 15px;
`;

// ----------------
export const OrderLink = styled.a`
	color: ${Variables.colors.light};
	background: ${Variables.colors.main};
	padding: 10px;
	border-radius: 10px;
`;

export const OrderMessage = styled.div`
	text-align: center;
    padding: 25px 15px 15px;
	opacity: 0.75;
	font-size: 16px;
`;

// ----------------
export const PixModal = styled.div`
	text-align: center;
`;

export const PixModalTitle = styled.p`
	font-size: 20px;
	font-weight: 600;
    margin-bottom: 20px;
	text-align: center;
`;
export const PixModalMessage = styled.p`
	font-size: 16px;
    margin-bottom: 40px;
	text-align: center;
`;
