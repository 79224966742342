import styled from 'styled-components';

interface props {
	height: number;
	width: number;
	marginLeft?: number;
	marginRight?: number;
}

const IconWrapper = styled.div`
	height: ${({ height }: props) => `${height}px`};
	min-width: ${({ width }: props) => `${width}px`};
	margin-left: ${({ marginLeft }: props) => `${marginLeft || 0}px`};
	margin-right: ${({ marginRight }: props) => `${marginRight || 0}px`};
`;

export default IconWrapper;
