import React, { useEffect, useContext } from 'react';

import ReactPixel from 'react-facebook-pixel';

import PixelContext from 'context/pixel';
import EventService from 'services/EventService';

const PixelManagement = ({eventId}: any) => {
	const { setState } = useContext(PixelContext);

	useEffect(() => () => {
		setState({
			active: false,
			code: '',
			eventCheckout: false,
			eventInitiateCheckout: false,
			eventAddToCart: false,
		})
	}, [])

	useEffect(() => {
		EventService.getMarketingData(eventId)
			.then(({data}: any) => {
				const index = data.findIndex((el: any) => el.type === 'PIXEL');

				if (index !== -1) {
					setPixelValues(data[index]);

					if (data[index].status === 'ENABLED') {
						initPixel(data[index].options.code);
					}
				}
			})
	}, [eventId]);

	const setPixelValues = (pixel: any) => {
		setState({
			active: (pixel.status === 'ENABLED'),
			code: pixel.options.code,
			eventInitiateCheckout: pixel.options.initiateCheckout,
			eventCheckout: pixel.options.checkout,
			eventAddToCart: pixel.options.addToCart,
		})
	}

	const initPixel = (code: string) => {
		ReactPixel.init(code);
		ReactPixel.pageView();
	}

	return (<></>);
}

export default PixelManagement;
