import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Login, PhoneAuth, PasswordRecovery, Seo } from 'components/standalone';
import AuthService from 'services/AuthService';
import { RenderIf } from 'components/layout';
import SocialAuthButton from 'components/standalone/auth/SocialAuthButton/SocialAuthButton';
import AnalyticsService from 'services/AnalyticsService';

enum AUTH_VIEW {
	LOGIN_VIEW = 'LOGIN_VIEW',
	PASSWORD_RECOVERY_VIEW = 'PASSWORD_RECOVERY_VIEW',
}

const LoginContainer = styled.div`
	margin: 2rem auto;
	max-width: 600px;
	padding: 0 1rem;
	width: 100%;
`;

const ButtonsSeparator = styled.div`
	align-items: center;
	color: #BDBDBD;
	display: flex;
	justify-content: center;
	margin: 2rem 0;
	position: relative;

	&:before,
	&:after {
		background-color: #BDBDBD;
		content: '';
		height: 1px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 40%;
	}

	&:before { left: 0; }
	&:after { right: 0; }
`;

const LoginPage = () => {
	const [currentView, setCurrentView] = useState(AUTH_VIEW.LOGIN_VIEW);
	const [authType, setAuthType] = useState(process.env.REACT_APP_AUTH_TYPE);

	const history: any = useHistory();
	const { search } = useLocation();

	useEffect(() => {
		checkIsRecoveringPassword(search);

		if (AuthService.isAuthenticated() && AuthService.hasLocalUser()) {
			history.push({ pathname: '/' });
		}
	}, []);

	const loginSuccess = (type: string) => {
		const previousUrl = history.location.state?.previousUrl;
		let path = '/';

		if (previousUrl && previousUrl !== '/entrar') {
			path = previousUrl;
		}
		AnalyticsService.sendLoginEvent(type);
		history.push({ pathname: path });
	}

	const showRecoveryPasswordForm = () => {
		setCurrentView(() => AUTH_VIEW.PASSWORD_RECOVERY_VIEW);
	}

	const showLoginForm = () => {
		setCurrentView(() => AUTH_VIEW.LOGIN_VIEW);
	}

	const checkIsRecoveringPassword = (query: string) => {
		if (query.includes('recuperar-senha')) {
			showRecoveryPasswordForm();
		}
	}

	const goToRegister = () => {
		history.push({ pathname: '/cadastrar' });
	}

	return (
		<>
			<Seo title={`${process.env.REACT_APP_NAME} - Entrar`} />
			<LoginContainer>
				<RenderIf condition={currentView === AUTH_VIEW.LOGIN_VIEW}>
					<>
						<RenderIf condition={authType === 'EMAIL'}>
							<Login
								showBackButton={false}
								changeTypeFn={() => setAuthType('PHONE')}
								loginSuccessFn={loginSuccess}
								recoveryPasswordFn={showRecoveryPasswordForm}
								registerFn={goToRegister}
							/>
						</RenderIf>

						<RenderIf condition={authType === 'PHONE'}>
							<PhoneAuth
								showBackButton={false}
								changeTypeFn={() => setAuthType('EMAIL')}
								loginSuccessFn={loginSuccess}
								registerFn={goToRegister}
							/>
						</RenderIf>

						<RenderIf condition={process.env.REACT_APP_HAS_SOCIAL_AUTH === 'true'}>
							<ButtonsSeparator>ou</ButtonsSeparator>

							<SocialAuthButton type="FACEBOOK" authSuccessFn={loginSuccess} />

							<SocialAuthButton type="GOOGLE" authSuccessFn={loginSuccess} />
						</RenderIf>
					</>
				</RenderIf>

				<RenderIf condition={currentView === AUTH_VIEW.PASSWORD_RECOVERY_VIEW}>
					<PasswordRecovery
						backFn={showLoginForm}
						recoverySuccessFn={showLoginForm}
					/>
				</RenderIf>
			</LoginContainer>

		</>
	);
}

export default LoginPage;
