import { NavTypes } from './types';

export const showMobileOptions = () => ({
	type: NavTypes.SHOW_MOBILE_OPTIONS,
});

export const hideMobileOptions = () => ({
	type: NavTypes.HIDE_MOBILE_OPTIONS,
});

export const showFixedFooterContent = () => ({
	type: NavTypes.SHOW_FIXED_FOOTER_CONTENT,
});

export const hideFixedFooterContent = () => ({
	type: NavTypes.HIDE_FIXED_FOOTER_CONTENT,
});
