import styled from 'styled-components';

import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';
import { CategoryListProps } from './types';

export const CategoryListWrapper = styled.div`
	width: 100%;
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
	${({ inline }: CategoryListProps) => (inline ? 'max-width: 100%;' : '')};

	${breakAt(BreakpointsSizes.xl)} {
		max-width: 1100px;
	}
`;

export const CategoryList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 25%));


	${({ inline }: CategoryListProps) => (inline ? 'grid-auto-flow: column; grid-template-columns: unset; max-width: 100%; overflow: auto; margin-top: 30px;' : '')};

	&::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none;
	scrollbar-width: none;

	${breakAt(BreakpointsSizes.xl)} {
		grid-auto-flow: column;
		grid-template-columns: unset;
		grid-auto-columns: 1fr;
		${({ inline }: CategoryListProps) => (inline ? 'max-width: 1100px;' : '')}
	}
`;

