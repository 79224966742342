import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { breakAt, BreakpointsSizes } from 'styles/Breakpoint';

export const FooterWrapper = styled.footer`
    background-color: #333333;
    color: #FFF;
    margin-top: auto;
    padding-top: 4rem;
`;

export const FooterContent = styled.div`
	${breakAt(BreakpointsSizes.md)} {
		display: flex;
        justify-content: space-between;
	}
`;

export const FooterGroup = styled.div`
    padding-bottom: 2.5rem;

	${breakAt(BreakpointsSizes.md)} {
        padding-left: 2rem;

        &:first-child {
            padding-left: 0;
        }
    }
`;

export const FooterLogo = styled.img`
	display: block;
    height: auto;
    pointer-events: none;
    user-select: none;
	max-width: 200px;

    ${breakAt(BreakpointsSizes.md)} {
        margin-bottom: 4rem;
    }
`;

export const FooterTitle = styled.p`
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
`;

export const FooterLink = styled(Link)`
    display: block;
    cursor: pointer;
    margin-bottom: 1.5rem;
    transition: 0.2s;

    &:hover {
        filter: brightness(0.8);
    }
`;

export const FooterLinkExternal = styled.a`
    display: block;
    cursor: pointer;
    margin-bottom: 1.5rem;
    transition: 0.2s;

    &:hover {
        filter: brightness(0.8);
    }
`;


export const FooterSocialWeb = styled.div`
    display: none;

    ${breakAt(BreakpointsSizes.md)} {
        display: block;
    }
`;

export const FooterSocialMobile = styled.div`
    margin-bottom: 5rem;
    display: block;

    ${breakAt(BreakpointsSizes.md)} {
        display: none;
    }
`;

export const FooterSocialGroup = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1.5rem;
    justify-content: start;
`;

type SocialProps = {
	icon: string;
}
export const FooterSocialItem = styled.a`
    background-image: ${({ icon }: SocialProps) => 'url(' + icon +')'};
    background-position: center;
    background-repeat: no-repeat;
    background-size: 75%;
    height: 2.5rem;
    transition: 0.2s;
    width: 2.5rem;

    &:hover {
        filter: brightness(0.8);
    }
`;

export const FooterCompany = styled.div`
	color: #ffffff;
	margin-bottom: 5rem;
	margin-right: 4rem;

	@media(min-width: 530px) and (max-width: 830px) {
		max-width: 280px;
	}

	@media(min-width: 992px) {
		margin-bottom: 2rem;
		margin-right: 0;
	}
`;
