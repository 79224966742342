import { Reducer } from 'redux';
import { OrganizationState, OrganizationTypes, Organization } from './types';

const initialOrganization: Organization = {
	id: '',
	about: '',
	email: '',
	imageCover: '',
	imageProfile: '',
	name: '',
	phoneNumber: '',
	url: '',
	events: [],
	links: [],
};

const INITIAL_STATE: OrganizationState = {
	data: initialOrganization,
	loading: false,
	error: false,
	collaboratorInviteMessage: '',
	collaboratorInviteError: false,
};

const reducer: Reducer<OrganizationState> = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case OrganizationTypes.GET_ORGANIZATION:
			return {
				...state,
				loading: true,
			}

		case OrganizationTypes.GET_ORGANIZATION_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload.data,
			};

		case OrganizationTypes.GET_ORGANIZATION_FAILURE:
			return {
				...state,
				loading: false,
				error: true,
			};

		case OrganizationTypes.ACCEPT_COLLABORATOR_INVITE:
			return {
				...state
			}

		case OrganizationTypes.ACCEPT_COLLABORATOR_INVITE_MESSAGE:
			return {
				...state,
				collaboratorInviteMessage: action.payload.data,
			}

		case OrganizationTypes.ACCEPT_COLLABORATOR_INVITE_ERROR:
			return {
				...state,
				collaboratorInviteError: true,
			}

		default:
			return state;
	}
};

export default reducer;
