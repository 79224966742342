import styled from 'styled-components';

export const Overlay = styled.div`
	align-items: center;
	background-color: rgba(0, 0, 0, 0.25);
	bottom: 0;
	display: flex;
	justify-content: center;
	left: 0;
	padding: 2rem;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 9999;
`;

export const ModalHeader = styled.div`
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.5rem;
`;

export const ModalTitle = styled.div`
	font-weight: 600;
	font-size: 1rem;
`;

export const ModalClose = styled.div`
	cursor: pointer;
	height: 2rem;
	margin-left: 1rem;
	margin-top: -0.5rem;
	position: relative;
	width: 2rem;

	&:before,
	&:after {
		background-color: #001537;
		content: '';
		height: 1rem;
		left: 50%;
		margin-left: -1px;
		margin-top: -0.5rem;
		position: absolute;
		top: 50%;
		transform-origin: center;
		width: 2px;
	}

	&:before { transform: rotate(45deg); }
	&:after { transform: rotate(-45deg); }
`;
