import React, { ReactNode } from 'react';

interface renderIfProps {
	condition: boolean;
	children: any
}

const RenderIf = ({ condition, children }: renderIfProps) => (condition ? children : null);

export default RenderIf;
