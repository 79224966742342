import React, { useEffect, useState } from 'react';
import { Tabs, TabPanel } from 'react-tabs';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';

import { createFileUrl, downloadFile } from 'utils/appUtils';
import { Button, LinkButton } from 'components/standalone';
import { Container, Flex } from 'styles';
import PurchasesService from 'services/PurchasesService';
import PurchaseModal from '../PurchaseModal/PurchaseModal';
import TicketCard from '../TicketCard/TicketCard';
import * as S from './styles';

const TicketsContent = () => {
	const [ currentPurchase, setCurrentPurchase ] = useState({ isOpen: false, eventName: '', id: '' });
	const [ purchases, setPurchases ] = useState([]);
	const [ status, setStatus ] = useState({ isLoading: true, hasError: false, hasMore: false });

	useEffect(() => {
		getPurchases();
	}, [])

	const showTicketsModal = (eventName: string, id: string) => {
		setCurrentPurchase({ isOpen: true, eventName: eventName, id: id });
	}

	const closeTicketsModal = () => {
		setCurrentPurchase({ isOpen: false, eventName: '', id: '' });
	}

	const getPurchases = (isBuyer = true) => {
		const data = { eventStatuses: ['CANCELLED', 'FINISHED', 'PUBLISHED', 'UNPUBLISHED'], isBuyer };
		const params = { size: purchases.length + 20, sort: 'creationDate,DESC' };

		PurchasesService.getAllPurchases(data, params)
			.then(({ data }: any) => {
				setStatus({ isLoading: false, hasError: false, hasMore: !data.last })
				setPurchases(data.content)
			})
			.catch(() => setStatus({ isLoading: false, hasError: true, hasMore: false }))
	}

	const downloadAllTickets = (eventId: number, purchaseId: number) => {
		PurchasesService.downloadAllTicket(eventId, purchaseId)
			.then(({ data }: any) => downloadFile(createFileUrl(data), 'ingressos'))
			.catch((error) => {
				new Promise((resolve) => {
					let reader = new FileReader();
					reader.onload = () => {
						resolve(Promise.reject(error));
						//@ts-ignore
						const errorMessage = JSON.parse(reader.result);
						//@ts-ignore
						toast(errorMessage.detail, { type: toast.TYPE.ERROR });
					};

					reader.readAsText(error.data);
				});
			})
	}

	const getSkeletonLayout = () => {
		if (status.isLoading && !status.hasError && !purchases.length) {
			return (
				<S.TicketsList>
					<Skeleton height={250} />
					<Skeleton height={250} />
					<Skeleton height={250} />
				</S.TicketsList>
			)
		}
	}

	const getErrorLayout = () => {
		if (status.hasError) {
			return (
				<S.TicketsFallback>
					<S.TicketsFallbackImage error />
					<S.TicketsFallbackTitle>Houve um erro ao buscar seus ingressos</S.TicketsFallbackTitle>
					<S.TicketsFallbackSubtitle>Caso o erro persista, entre em contato com o suporte</S.TicketsFallbackSubtitle>

					<Button handleClick={() => getPurchases()}>Tentar novamente</Button>
				</S.TicketsFallback>
			)
		}
	}

	const getFallbackLayout = () => {
		if (!status.isLoading && !status.hasError && !purchases.length) {
			return (
				<S.TicketsFallback>
					<S.TicketsFallbackImage />
					<S.TicketsFallbackTitle>Não há ingressos disponíveis</S.TicketsFallbackTitle>

					<LinkButton to="/eventos">Encontrar eventos</LinkButton>
				</S.TicketsFallback>
			)
		}
	}

	const getHasMoreLayout = () => {
		if (status.hasMore) {
			return (
				<Flex isWrap={false} justifyContent='center' marginBottom={20}>
					<Button handleClick={() => getPurchases()}>Carregar mais</Button>
				</Flex>
			)
		}
	}

	const setTabView = (index: number) => {
		purchases.length = 0;
		setStatus({ isLoading: true, hasError: false, hasMore: false });
		getPurchases(index === 0);
	}

	return (
		<Container marginBottom={50}>
			<Tabs defaultIndex={0} onSelect={index => setTabView(index)}>
				<S.CustomTabList>
					<S.CustomTab>Meus pedidos</S.CustomTab>
					<S.CustomTab>Ingressos relacionados a mim</S.CustomTab>
				</S.CustomTabList>

				<TabPanel>
					{getSkeletonLayout()}

					{getErrorLayout()}

					{getFallbackLayout()}

					<S.TicketsList>
						{purchases.map((purchase: any) => (
							<TicketCard
								key={purchase.purchaseId}
								ticket={purchase}
								isOrder
								downloadAllTicketsFn={() => downloadAllTickets(purchase.event.id, purchase.purchaseId)}
							/>
						))}
					</S.TicketsList>

					{getHasMoreLayout()}
				</TabPanel>

				<TabPanel>
					{getSkeletonLayout()}

					{getErrorLayout()}

					{getFallbackLayout()}

					<S.TicketsList>
						{purchases.map((purchase: any) => (
							<TicketCard
								key={purchase.purchaseId}
								ticket={purchase}
								isOrder={false}
								seeTicketsFn={() => showTicketsModal(purchase.event.name, purchase.purchaseId)}
							/>
						))}
					</S.TicketsList>

					{getHasMoreLayout()}
				</TabPanel>
			</Tabs>

			<PurchaseModal
				isOpen={currentPurchase.isOpen}
				eventName={currentPurchase.eventName}
				id={currentPurchase.id}
				closeModalFn={() => closeTicketsModal()}
			/>
		</Container>
	);
}

export default TicketsContent;
