import styled, { keyframes } from 'styled-components';

import Variables from 'styles/Variables';

interface StyleProps {
	height?: string,
}

export const LoadingWrapper = styled.div`
	padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
	flex-direction: column;
    height: ${({ height }: StyleProps) => height || '350px'};
`;

export const Loading = styled.div`
	background-color: ${Variables.backgrounds.light};
    border-radius: 50%;
    height: 60px;
    width: 60px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    border: 5px solid ${Variables.backgrounds.light};
	border-top-color: ${Variables.colors.main};
	animation: ${rotationBuilder()} 1.5s linear infinite forwards;
`;

export const Text = styled.span`
	font-size: 16px;
	margin-top: 20px;
	opacity: 0.7;
`;

function rotationBuilder() {
	const rotation = keyframes`
		0% { transform: rotate(0deg) }
		100% { transform: rotate(360deg) }
	`;
	return rotation;
}
