import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import AuthService from 'services/AuthService';
import { CONSTANTS } from 'config/constants';

const SocialAuthPage = () => {

	useEffect(() => {
		const cookies = new Cookies();
		const token = AuthService.getAccessToken();
		const expToken = AuthService.getTokenExpirationDate();
		const refreshToken = AuthService.getRefreshToken();

		cookies.remove(CONSTANTS.CURRENT_TOKEN, { path: '/' });
		cookies.remove(CONSTANTS.TOKEN_EXPIRATION_DATE, { path: '/' });
		cookies.remove(CONSTANTS.REFRESH_TOKEN, { path: '/' });

		cookies.set(CONSTANTS.CURRENT_TOKEN, token, { path: '/', domain: process.env.REACT_APP_DOMAIN_COOKIE });
		cookies.set(CONSTANTS.TOKEN_EXPIRATION_DATE, expToken, { path: '/', domain: process.env.REACT_APP_DOMAIN_COOKIE });
		cookies.set(CONSTANTS.REFRESH_TOKEN, refreshToken, { path: '/', domain: process.env.REACT_APP_DOMAIN_COOKIE });

		window.close();
	}, []);

	return (<></>);
}

export default SocialAuthPage;
