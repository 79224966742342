import React, { useEffect, useRef, useState } from 'react';

import { Timer } from 'components/standalone';
import * as S from './styles';
import { RenderIf } from 'components/layout';

const CheckoutChallenge = ({ externalUrl, creq, concludeFn, expiredFn }: any) => {
	const challenge = useRef(null) as any;
	const [isExpired, setIsExpired] = useState(false);
	const [timer, setTimer] = useState(0);

	useEffect(() => {
		try {
			let iframe = document.createElement('iframe');
			iframe.name = 'challengeFrame';
			iframe.id = 'challengeFrame';
			iframe.height = '500px';
			iframe.width = '100%';
			challenge.current.appendChild(iframe);

			//@ts-ignore
			let iDocument = iframe.contentWindow.document;

			let challengeForm = iDocument.createElement('form');
			challengeForm.name = 'challengeForm';
			challengeForm.setAttribute('target', 'challengeFrame');
			challengeForm.setAttribute('method', 'post');
			challengeForm.setAttribute('action', externalUrl);

			let hiddenField = iDocument.createElement('input');
			hiddenField.setAttribute('type', 'hidden');
			hiddenField.setAttribute('name', 'creq');
			hiddenField.setAttribute('value', creq);
			challengeForm.appendChild(hiddenField);
			iframe.appendChild(challengeForm);

			challengeForm.submit();
			startListener();
			setTimer(300);
		} catch (error) {
			console.log('Challenge error', error);
		}
	}, [])

	const startListener = () => {
		window.addEventListener('message', (e) => {
			if (e.data.status === 'COMPLETE') {
				concludeFn();
			}
		});
	}

	const timeExpiredHandler = () => {
		setIsExpired(true);
		expiredFn();
	}

	return (
		<>
			<S.OrderText style={{ marginTop: 20 }}>Realize a validação da sua compra para concluí-la.</S.OrderText>
			<S.OrderText>Fique atento, o prazo para realizar a validação expira em:</S.OrderText>
			<RenderIf condition={timer > 0}>
				<Timer time={timer} endTimeFn={() => timeExpiredHandler()} />
			</RenderIf>
			<RenderIf condition={!isExpired}>
				<div id="3dsChallenge" ref={challenge}></div>
			</RenderIf>
		</>
	);
}

export default CheckoutChallenge;
