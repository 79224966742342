import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Search } from 'react-feather';

import Variables from 'styles/Variables';
import * as S from './styles';

const SearchBar = () => {
	const [name, setName] = useState('');
	const history = useHistory();

	const inputHandler = (ev: any) => {
		if (ev.keyCode === 13 && name.trim().length) {
			searchEvent();
		}

		setName(ev.target.value);
	}

	const searchEvent = () => {
		history.push({
			pathname: '/eventos',
			state: {name: name}
		});
	}

	return (
		<S.Root>
			<S.InputWrapper>
				<S.Input id="eventName" placeholder="Encontre um evento" onKeyUp={(ev: any) => inputHandler(ev)} />
				<S.SearchButton onClick={() => searchEvent()}>
					<Search color={Variables.colors.main} size={25} />
				</S.SearchButton>
			</S.InputWrapper>
		</S.Root>
	);
}

export default SearchBar;
