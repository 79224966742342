import React, { useState } from 'react';
import { Printer } from 'react-feather';
import { toast } from 'react-toastify';

import { downloadFile, createFileUrl } from 'utils/appUtils';
import { formatCurrency } from 'utils/currencyUtils';
import PurchasesService from 'services/PurchasesService';
import * as S from './styles';

interface Ticket {
	attendeeCode: string;
	attendeeFirstName: string;
	attendeeLastName: string;
	ticketBatch: string;
	ticketName: string;
	ticketPrice: number;
}

const IndividualTicket = ({ ticket }: any) => {
	const [ isDownloading, setIsDownloading ] = useState(false);

	const downloadTicket = () => {
		setIsDownloading(true);
		PurchasesService.downloadTicket(ticket.attendeeCode)
			.then(({ data }: any) => downloadFile(createFileUrl(data), 'ingresso'))
			.catch(({ data }: any) => toast(data.detail, { type: toast.TYPE.ERROR }))
			.finally(() => setIsDownloading(false))
	}

	return (
		<S.TicketWrapper>
			<S.TicketInfo>
				<S.Attendee>{ticket.attendeeFirstName} {ticket.attendeeLastName}</S.Attendee>
				<S.Text>{ticket.ticketName} {ticket.ticketBatch}</S.Text>
				<S.Text><S.BoldText>Código:</S.BoldText> <S.Code>{ticket.attendeeCode}</S.Code></S.Text>
				<S.Text><S.BoldText>Valor:</S.BoldText> {formatCurrency(ticket.ticketPrice)}</S.Text>
				<S.Small>(taxas não inclusas)</S.Small>
			</S.TicketInfo>

			{isDownloading
			? (<S.Downloading>Baixando</S.Downloading>)
			: (<S.DownloadTicket onClick={downloadTicket}><S.Text>Baixar</S.Text><Printer size={15} /></S.DownloadTicket>)}
		</S.TicketWrapper>
	);
}

export default IndividualTicket;
