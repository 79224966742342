import React from 'react';

import * as S from './styles';
import { StyleProps } from './types';

const SectionTitle = ({
	text,
	align,
	marginBottom,
	marginTop,
	size,
	shadow,
	weight,
}: StyleProps) => (
	<S.Title align={align} marginBottom={marginBottom} marginTop={marginTop} size={size} shadow={shadow} weight={weight}>
		{text}
	</S.Title>
);

export default SectionTitle;
