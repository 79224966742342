import styled from 'styled-components';

export const Title = styled.h1`
	font-size: 1.25rem;
	font-weight: 600;
	margin-bottom: 1rem;
`;

export const Footer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1.5rem;
`;
