import styled from 'styled-components';
import { TabList, Tab } from 'react-tabs';
import Variables from 'styles/Variables';

export const OrganizationEventList = styled.div`
	display: grid;
	grid-gap: 25px;
	grid-template-columns: repeat(auto-fit, minmax(100px, 350px));
	justify-content: center;
`;

export const CustomTabList = styled(TabList)`
	display: flex;
	border-bottom: 1px solid lightgray;
	margin-bottom: 20px;
`;

export const CustomTab = styled(Tab)`
	padding: 5px 15px;
	cursor: pointer;
	font-size: 1.15rem;

	&.react-tabs__tab--selected{
		background: none;
		border-bottom: 6px solid ${Variables.colors.main};
		margin-bottom: -3px;
	}
`;

export const OrganizationAbout = styled.div``;
