import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SearchState } from 'store/ducks/search/types';
import { getMore } from 'store/ducks/search/actions';

import * as S from './styles';
import { Button } from 'components/standalone';

interface StateProps {
	search: SearchState,
}

const SearchMore = () => {
	const select = (state: StateProps) => state.search;
	const dispatch = useDispatch();
	const { currentPage, filters, loadingMore } = useSelector(select);

	const handleMoreEvents = () => {
		const params = {page: currentPage + 1};
		dispatch(getMore(filters, params, true));
	}

	return (
		<S.SearchMoreWrapper>
			<Button handleClick={handleMoreEvents} loading={loadingMore}>Carregar mais resultados</Button>
		</S.SearchMoreWrapper>
	);
}

export default SearchMore;
