import { ordersApi } from './Api';

const AnalyticsService = (() => {
	function sendSearchEvent(searchTerm: string) {
		if (process.env.REACT_APP_GA_CODE) {
			window.gtag('event', 'search', {
				'send_to': process.env.REACT_APP_GA_CODE,
				'search_term': searchTerm,
			})
		}
	}

	function sendLoginEvent(type: string) {
		if (process.env.REACT_APP_GA_CODE) {
			window.gtag('event', 'login', {
				'send_to': process.env.REACT_APP_GA_CODE,
				'method': type,
			})
		}
	}

	function sendRegisterEvent(type: string) {
		if (process.env.REACT_APP_GA_CODE) {
			window.gtag('event', 'sign_up', {
				'send_to': process.env.REACT_APP_GA_CODE,
				'method': type,
			})
		}
	}

	return {
		sendSearchEvent: sendSearchEvent,
		sendLoginEvent: sendLoginEvent,
		sendRegisterEvent: sendRegisterEvent,
	}
})();

export default AnalyticsService;
