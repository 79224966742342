import styled from 'styled-components';
import Variables from 'styles/Variables';
import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';
import { StyleProps } from './types';

export const SelectWrapper = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 20px;
	grid-gap: 10px;
	width: fit-content;
	min-width: 110px;
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #d3d3d3;
	cursor: pointer;
	box-shadow: ${Variables.boxShadows.level1};
	background: ${({hasSelection}: StyleProps) => hasSelection !== null ? Variables.backgrounds.main : Variables.backgrounds.light};
	${({hasSelection}: StyleProps) => hasSelection !== null && 'color: #fff;'}
	${({mobile}: StyleProps) => mobile !== false && ('width: 100%; max-width: 100%;')}
`;

export const Arrow = styled.div`
	transform: rotate(45deg);
	border-radius: 2px;
	height: 12px;
	width: 12px;
	margin-left: 5px;
	margin-bottom: 5px;
	border-right: 2px solid ${Variables.colors.main};
	border-bottom: 2px solid ${Variables.colors.main};
`;

export const ClearSelected = styled.button`
	height: 18px;
	width: 18px;
	border-radius: 50%;
	background-color: #fff;
	position: relative;

	&:before,
	&:after{
		content: '';
		height: 90%;
		width: 4px;
		position: absolute;
		transform: rotate(45deg);
		border-radius: 4px;
		left: 5px;
		top: 1px;
		background: ${Variables.colors.main};
	}

	&:after{
		transform: rotate(-45deg);
	}
`;

export const ModalHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
`;

export const ModalTitle = styled.span`
	font-size: 1.15rem;
	font-weight: 600;
`;

export const ModalClose = styled.button`
	position: relative;
	height: 30px;
	width: 30px;

	&:before,
	&:after{
		content: '';
		height: 100%;
		width: 3px;
		position: absolute;
		top: 0;
		transform: rotate(45deg);
		border-radius: 4px;
		background: ${Variables.colors.main};
	}

	&:after{
		transform: rotate(-45deg);
	}
`;

export const ModalBody = styled.div``;

export const SelectOptions = styled.div`
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 600px;

	${breakAt(BreakpointsSizes.lg, true)} {
		max-height: 360px;
	}
`;

export const Option = styled.div`
	padding: 10px;
	cursor: pointer;
	transition: .2s ease;
	border-radius: 5px;

	&:hover{
		background: #f2f2f2;
	}
`;

