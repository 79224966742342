import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Container } from 'styles';
import { hideMobileOptions, showMobileOptions } from 'store/ducks/nav/actions';
import ValidateAccreditationCpf from './ValidateAccreditationCpf';
import ValidateAccreditationCode from './ValidateAccreditationCode';
import ValidateAccreditationUserData from './ValidateAccreditationUserData';
import AccreditationReserveConfirmed from './AccreditationReserveConfirmed';
import SendAccreditationImage from './SendAccreditationImage';
import AccreditationCompleted from './AccreditationCompleted';
import AccreditationContext from 'context/accreditation';
import { scrollToTop } from 'utils/appUtils';
import AccreditationService from 'services/AccreditationService';

enum ACCREDITATION_FLOW_STEP {
	VALIDATE_CPF = 'VALIDATE_CPF',
	VALIDATE_CODE = 'VALIDATE_CODE',
	VALIDATE_USER_DATA = 'VALIDATE_USER_DATA',
	RESERVE_CONFIRMED = 'RESERVE_CONFIRMED',
	SEND_IMAGE = 'SEND_IMAGE',
	FINISHED = 'FINISHED',
}
enum CURRENT_STEP {
	ACCREDITATION_PENDING  = 'ACCREDITATION_PENDING',
	ACCREDITATION_SEND_FACE_MATCH = 'ACCREDITATION_SEND_FACE_MATCH',
	ACCREDITATION_GUESTS_PENDING = 'ACCREDITATION_GUESTS_PENDING',
	ACCREDITATION_GUESTS_FINALIZED = 'ACCREDITATION_GUESTS_FINALIZED',
}

const AccreditationFlow = ({ event, onConclude, flowConcludedFn }: any) => {
	const dispatch = useDispatch();
	const { state, setState } = useContext(AccreditationContext);

	const [currentStep, setCurrentStep] = useState(ACCREDITATION_FLOW_STEP.VALIDATE_CPF);
	const [cpf, setCpf] = useState('');
	const [userDataObfuscated, setUserDataObfuscated] = useState({ email: '', phone: '' });

	useEffect(() => {
		dispatch(hideMobileOptions());
	}, []);

	useEffect(() => {
		return () => {
			dispatch(showMobileOptions());
		}
	}, []);

	// STEP 1
	const validatedDocument = (ev: any) => {
		setCpf(ev.cpf);
		setUserDataObfuscated(() => ({ email: ev.email, phone: ev.phone }));
		setCurrentStep(ACCREDITATION_FLOW_STEP.VALIDATE_CODE);
		scrollToTop();
	}

	// STEP 2
	const validatedCode = (ev: any) => {
		setState(() => ({ ...state, apiKey: ev.apiKey, reservation: ev.reservationId, currentStep: ev.currentStep }));

		if (ev.currentStep !== CURRENT_STEP.ACCREDITATION_PENDING) {
			getCurrentReservation(event.id, ev.reservationId, ev.apiKey, ev.currentStep);
		}

		if (ev.currentStep === CURRENT_STEP.ACCREDITATION_PENDING) {
			setCurrentStep(ACCREDITATION_FLOW_STEP.VALIDATE_USER_DATA);
			scrollToTop();
			return;
		}

		if (ev.currentStep === CURRENT_STEP.ACCREDITATION_SEND_FACE_MATCH) {
			goToImage();
			return;
		}

		if (ev.currentStep === CURRENT_STEP.ACCREDITATION_GUESTS_PENDING) {
			imageSent();
			return;
		}

		if (ev.currentStep === CURRENT_STEP.ACCREDITATION_GUESTS_FINALIZED) {
			flowConcludedFn();
		}
	}

	const getCurrentReservation = (eventId: string, reservationId: string, apiKey: string, currentStep: string) => {
		AccreditationService.getReservationData(eventId, reservationId, apiKey)
			.then(({ data }: any) => {
				setState(() => ({
					apiKey: apiKey,
					reservation: reservationId,
					currentStep: currentStep,
					guestQuantity: data.category?.guestQuantity ?? 0,
					orderNumber: data.purchaseCode
				}));
			});
	}

	// STEP 3
	const validatedUserData = (ev: any) => {
		setState({ ...state, guestQuantity: ev.guestQuantity, orderNumber: ev.purchaseCode });
		setCurrentStep(ACCREDITATION_FLOW_STEP.RESERVE_CONFIRMED);
		scrollToTop();
	}

	// STEP 4
	const goToImage = () => {
		setCurrentStep(ACCREDITATION_FLOW_STEP.SEND_IMAGE);
		scrollToTop();
	}

	// STEP 5
	const imageSent = () => {
		setCurrentStep(ACCREDITATION_FLOW_STEP.FINISHED);
		scrollToTop();
	}

	const goToParticipants = () => {
		onConclude({ apiKey: state.apiKey, reservation: state.reservation });
	}

	return (
		<Container marginTop={50} marginBottom={50} style={{maxWidth: '600px'}}>
			{currentStep === ACCREDITATION_FLOW_STEP.VALIDATE_CPF && (
				<ValidateAccreditationCpf event={event} onValidate={validatedDocument} />
			)}

			{currentStep === ACCREDITATION_FLOW_STEP.VALIDATE_CODE && (
				<ValidateAccreditationCode event={event} cpf={cpf} obfuscatedData={userDataObfuscated} onValidate={validatedCode} />
			)}

			{currentStep === ACCREDITATION_FLOW_STEP.VALIDATE_USER_DATA && (
				<ValidateAccreditationUserData event={event} onValidate={validatedUserData} />
			)}

			{currentStep === ACCREDITATION_FLOW_STEP.RESERVE_CONFIRMED && (
				<AccreditationReserveConfirmed orderNumber={state.orderNumber} onConfirm={goToImage} />
			)}

			{currentStep === ACCREDITATION_FLOW_STEP.SEND_IMAGE && (
				<SendAccreditationImage event={event} onSend={imageSent} />
			)}

			{currentStep === ACCREDITATION_FLOW_STEP.FINISHED && (
				<AccreditationCompleted orderNumber={state.orderNumber} onConfirm={goToParticipants} />
			)}
		</Container>
	);
}

export default AccreditationFlow;
