import styled from 'styled-components';

export const MessageWrapper = styled.div`
	background-color: #eeeeee;
	border: 2px solid #e2e2e2;
    border-radius: 0.5rem;
    margin: 1rem 0;
    padding: 1rem;
`;

export const Title = styled.div`
	font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
`;

export const Message = styled.div`
	font-size: 0.9rem;
`;
