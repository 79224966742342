import { HomeTypes, Event } from './types';

export const getMainEvents = () => ({
	type: HomeTypes.GET_MAIN_EVENTS,
});

export const loadMainEvents = (mainEvents: Event[]) => ({
	type: HomeTypes.LOAD_MAIN_EVENTS,
	payload: { mainEvents }
});

export const failureMainEvents = () => ({
	type: HomeTypes.FAILURE_MAIN_EVENTS,
});

export const getFeaturedEvents = () => ({
	type: HomeTypes.GET_FEATURED_EVENTS,
});

export const loadFeaturedEvents = (featuredEvents: Event[]) => ({
	type: HomeTypes.LOAD_FEATURED_EVENTS,
	payload: { featuredEvents }
});

export const failureFeaturedEvents = () => ({
	type: HomeTypes.FAILURE_FEATURED_EVENTS,
});

export const getUpcomingEvents = () => ({
	type: HomeTypes.GET_UPCOMING_EVENTS,
});

export const loadUpcomingEvents = (upcomingEvents: Event[]) => ({
	type: HomeTypes.LOAD_UPCOMING_EVENTS,
	payload: { upcomingEvents }
});

export const failureUpcomingEvents = () => ({
	type: HomeTypes.FAILURE_UPCOMING_EVENTS,
});

export const getAbout = () => ({
	type: HomeTypes.GET_ABOUT
});

export const getAboutSuccess = (data: any) => ({
	type: HomeTypes.GET_ABOUT_SUCCESS,
	payload: { data },
});

export const getAboutFailure = () => ({
	type: HomeTypes.GET_ABOUT_FAILURE
});
