import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

import * as S from './styles';

const customStyles = {
	overlay: {
		zIndex: 9999,
		backgroundColor: 'rgba(0, 0, 0, 0.25)'
	},
	content : {
		top: '100px',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translateX(-50%)',
		maxWidth: '900px',
		width: '90%',
	},
};

const Select = ({title, options, state, changeHandler, clearHandler}: any) => {
	const [open, setOpen] = useState(false);
	const [name, setName] = useState(null);

	const selectOption = (option: any) => {
		setOpen(false);
		changeHandler(option);
	}

	const clear = (ev: any) => {
		ev.stopPropagation();
		clearHandler();
	}
	const getName = () => {
		const index = options.findIndex((el: any) => el.id === state);
		const name = index === -1 ? title : options[index].name;
		return name;
	}

	useEffect(() => {
		setName(getName());
	}, [state])

	return (
		<>
			<S.SelectWrapper hasSelection={state} onClick={() => setOpen(true)}>
				{name}
				{!state && (<S.Arrow />)}
				{state && (<S.ClearSelected onClick={(ev) => clear(ev)}/>)}
			</S.SelectWrapper>
			<Modal
				isOpen={open}
				style={customStyles}
				ariaHideApp={false}
			>
				<S.ModalHeader>
					<S.ModalTitle>{title}</S.ModalTitle>
					<S.ModalClose onClick={() => setOpen(false)} />
				</S.ModalHeader>

				<S.ModalBody>
					<S.SelectOptions>
						{options.map((option: any) => (
							<S.Option onClick={() => selectOption(option.id)}>{option.name}</S.Option>
						))}
					</S.SelectOptions>
				</S.ModalBody>
			</Modal>
		</>
	);
}

export default Select;
