import React from 'react';
import { Calendar, Clock, MapPin } from 'react-feather';
import { format } from 'date-fns';

import { dateGlobalPattern } from 'utils/dateUtils';

import { IconWrapper } from 'styles';
import * as S from './styles';

const iconSize = 15;

const OrganizationEventBanner = ({ event }: any) => (
	<S.OrganizationEventBannerWrapper>
		<S.OrganizationEventBanner to={event.url} banner={event.banner}>
			<S.EventTitle>{event.name}</S.EventTitle>
			<S.InfoWrapper>
				<S.EventDateTime>
					<IconWrapper height={iconSize} width={iconSize}>
						<Calendar size={iconSize} />
					</IconWrapper>
					<S.Text>{format(new Date(dateGlobalPattern(event.startDate)), 'dd/MM/yyyy')}</S.Text>

					<IconWrapper height={iconSize} width={iconSize}>
						<Clock size={iconSize} />
					</IconWrapper>
					<S.Text>{format(new Date(dateGlobalPattern(event.startDate)), 'HH:mm')}</S.Text>
				</S.EventDateTime>
				<S.EventAddress>
					<IconWrapper height={iconSize} width={iconSize}>
						<MapPin size={iconSize} />
					</IconWrapper>
					<S.Text>{event.locationType === 'PHYSICAL' ? event.fullAddress : 'Virtual'}</S.Text>
				</S.EventAddress>
			</S.InfoWrapper>
		</S.OrganizationEventBanner>
	</S.OrganizationEventBannerWrapper>
);

export default OrganizationEventBanner;
