import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';
import Variables from 'styles/Variables';

export const Wrapper = styled.div`
	padding: 25px;
	max-width: 1200px;
	margin: 0 auto;

	${breakAt(BreakpointsSizes.md)} {
		padding: 50px 25px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

export const Image = styled.div`
	height: 250px;
	width: 100%;
	margin: 0 auto;
	max-width: 600px;
	background-image: url(${process.env.PUBLIC_URL}/404.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;

	${breakAt(BreakpointsSizes.md)} {
		height: 500px;
	}
`;

export const ContentWrapper = styled.div`
	${breakAt(BreakpointsSizes.md)} {
		padding: 30px;
	}
`;

export const Title = styled.h1`
	margin-bottom: 30px;
	font-size: 2rem;
	font-weight: 700;
	opacity: 0.8;
`;

export const Message = styled.p`
	margin-bottom: 20px;
`;

export const NotFoundLink = styled(Link)`
	color: ${Variables.colors.light};
	background: ${Variables.colors.main};
	padding: 10px 20px;
	border-radius: 10px;
	margin-top: 20px;
    display: inline-block;
	transition: .2s ease;

	&:hover{
		filter: brightness(85%);
	}
`;

