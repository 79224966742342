import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ChevronsDown, ChevronsUp } from 'react-feather';

import * as S from './styles';
import { DescriptionProps } from './types';
import Tags from '../Tags/Tags';
import { RenderIf } from 'components/layout';
import EventService from 'services/EventService';

const Description = ({ eventId, status, tags }: DescriptionProps) => {
	const [showMore, setShowMore] = useState(false);
	const [alwaysShow, setAlwaysShow] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [eventDescription, setEventDescription] = useState<string>('');

	useEffect(() => {
		if (eventId) {
			getEventDescription();
		}
	}, [eventId]);

	useEffect(() => {
		if (status === 'PUBLISHED') {
			setAlwaysShow(false);
		}
	}, [status]);

	const getEventDescription = () => {
		EventService.getEventDescription(eventId)
			.then(({ data }) => setEventDescription(data.description))
			.catch(() => setEventDescription(''))
			.finally(() => setIsLoading(false))
	}

	return (
		<S.ContentWrapper>
			<S.DescriptionWrapper alwaysShow={alwaysShow} fullDescription={showMore}>
				<RenderIf condition={isLoading}>
					<Skeleton count={5} />
				</RenderIf>

				<RenderIf condition={eventDescription.length > 0}>
					<>
						<S.DescriptionTitle>Descrição</S.DescriptionTitle>
						<S.DescriptionContent data-test="eventDescription" dangerouslySetInnerHTML={{__html: eventDescription}}></S.DescriptionContent>

						<S.DescriptionToggle alwaysShow={alwaysShow} fullDescription={showMore} onClick={() => setShowMore(!showMore)}>
							<S.DescriptionToggleText>{showMore ? 'Mostrar menos' : 'Mostrar mais'}</S.DescriptionToggleText>
							{showMore ? <ChevronsUp /> : <ChevronsDown />}
						</S.DescriptionToggle>
					</>
				</RenderIf>
			</S.DescriptionWrapper>

			<Tags tags={tags} />
		</S.ContentWrapper>
	);
};

export default Description;
