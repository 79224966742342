import React, { useContext } from 'react';

import * as S from '../styles';
import { ButtonOutline } from 'styles/Form';
import { Bolder, Card } from 'styles/Commons';
import AccreditationContext from 'context/accreditation';

const AccreditationCompleted = ({ orderNumber, onConfirm }: any) => {
	const { state } = useContext(AccreditationContext);

	return (
		<>
			<Card>
				<S.AccreditationCardTitle>Seus ingressos já estão disponíveis!</S.AccreditationCardTitle>
				<S.AccreditationCardText>Seu processo de compra foi concluído! <br /> <span style={{'color': '#399C54'}}>Seus ingressos serão enviados para seu email e Whatsapp.</span></S.AccreditationCardText>
				<S.AccreditationCardText>Caso esteja logado, eles também ficarão disponíveis na nossa plataforma.</S.AccreditationCardText>

				<S.AccreditationCardText>Nº do pedido <br /><Bolder>{orderNumber.toUpperCase()}</Bolder></S.AccreditationCardText>
			</Card>

			{state.guestQuantity > 0 && (
				<Card  margin="1.5rem 0 0">
					<S.AccreditationCardTitle>Adicionar convidados</S.AccreditationCardTitle>
					{state.guestQuantity === 1 && (<S.AccreditationCardText>Você pode adicionar {state.guestQuantity} convidado para obter ingresso também.</S.AccreditationCardText>)}
					{state.guestQuantity > 1 && (<S.AccreditationCardText>Você pode adicionar {state.guestQuantity} convidados para obterem ingressos também.</S.AccreditationCardText>)}
					<S.AccreditationCardText>Eles receberão links para obter os ingressos.</S.AccreditationCardText>

					<ButtonOutline width="100%" onClick={onConfirm}>Adicionar convidados</ButtonOutline>
				</Card>
			)}
		</>
	);
}

export default AccreditationCompleted;
