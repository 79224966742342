import { Reducer } from 'redux';
import { CategoriesState, CategoriesTypes } from './types';

const INITIAL_STATE: CategoriesState = {
	categoryList: [],
	loading: false,
	error: false,
};

const reducer: Reducer<CategoriesState> = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CategoriesTypes.GET_CATEGORIES:
			return {
				...state,
				loading: true,
			}

		case CategoriesTypes.GET_CATEGORIES_SUCCESS:
			return {
				...state,
				loading: false,
				categoryList: action.payload.categories,
			};

		case CategoriesTypes.GET_CATEGORIES_FAILURE:
			return {
				...state,
				loading: false,
				error: true,
			};

		default:
			return state;
	}
};

export default reducer;
