import styled from 'styled-components';
import Variables from 'styles/Variables';
import { Element, Link } from 'react-scroll';

export const TicketsBoxWrapper = styled(Element)`
	width: 100%;
	margin-bottom: 30px;

	@media(min-width: 991px){
		justify-self: end;
		width: 380px;
		margin: 30px 0 30px;
	}
`;

export const TicketsBox = styled.div`
	align-items: center;
	background: #fff;
	border-radius: 8px;
	box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.05);
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1.25rem 1rem;
`;

export const TicketsBoxText = styled.p`
	margin-bottom: 1rem;
	text-align: center;
`;

export const NoTicketsText = styled.p`
	text-align: center;
`;
