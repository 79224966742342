import styled from 'styled-components';

import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';
import Variables from 'styles/Variables';
import { CategoryProps } from './types';

export const Root = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: 90px;
	cursor: pointer;

	${breakAt(BreakpointsSizes.xl, true)} {
		min-width: 80px;
		padding: 0 10px;
	}
`;

export const Icon = styled.div`
	width: 70px;
	height: 70px;
	border-radius: 50%;
	background: url(${({ background }: CategoryProps) => background});
	background-color: ${Variables.colors.main};
	background-size: cover;
	background-position: center;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	${({ grayscale }: CategoryProps) => grayscale ? 'filter: grayscale(1)' : ''};

	${breakAt(BreakpointsSizes.xl, true)} {
		width: 60px;
		height: 60px;

	}
`;

export const Text = styled.span`
	margin-top: 15px;
	text-align: center;

	${breakAt(BreakpointsSizes.xl, true)} {
		margin-top: 10px;
		margin-bottom: 15px;
		font-size: 0.75rem;
	}
`;
