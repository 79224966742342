import styled from 'styled-components';
import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';
import Variables from 'styles/Variables';

export const FilterBaseWrapper = styled.div`
	grid-gap: 15px;

	${breakAt(BreakpointsSizes.sm, true)} {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
	}

	${breakAt(BreakpointsSizes.sm)} {
		display: grid;
		grid-template-columns: 1fr 110px 110px;
	}
`;

export const SearchInput = styled.input`
	display: block;
	width: 100%;
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #d3d3d3;
	box-shadow: ${Variables.boxShadows.level1};
`;

export const OptionsWrapper = styled.div`
	display: grid;
	grid-gap: 15px;
	margin-top: 25px;

	${breakAt(BreakpointsSizes.md, true)} {
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	}

	${breakAt(BreakpointsSizes.md)} {
		grid-auto-flow: column;
		justify-content: end;
	}
`;
