import { Reducer } from 'redux';
import { HomeState, HomeTypes, UpcomingEvents } from './types';

const INITIAL_UPCOMING_EVENTS: UpcomingEvents = {
	onTheRise: [],
	otherEvents: [],
};

const INITIAL_STATE: HomeState = {
	mainEvents: [],
	loadingMainEvents: true,
	featuredEvents: [],
	loadingFeaturedEvents: true,
	upcomingEvents: INITIAL_UPCOMING_EVENTS,
	loadingUpcomingEvents: true,
	aboutData: null,
	aboutError: false
};

const reducer: Reducer<HomeState> = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case HomeTypes.GET_MAIN_EVENTS:
			return { ...state, loadingMainEvents: true };

		case HomeTypes.LOAD_MAIN_EVENTS:
			return { ...state, mainEvents: action.payload.mainEvents, loadingMainEvents: false };

		case HomeTypes.FAILURE_MAIN_EVENTS:
			return { ...state, mainEvents: [], loadingMainEvents: false };

		case HomeTypes.GET_FEATURED_EVENTS:
			return {...state, loadingFeaturedEvents: true};

		case HomeTypes.LOAD_FEATURED_EVENTS:
			return { ...state, featuredEvents: action.payload.featuredEvents, loadingFeaturedEvents: false };

		case HomeTypes.FAILURE_FEATURED_EVENTS:
			return { ...state, featuredEvents: [], loadingFeaturedEvents: false };

		case HomeTypes.GET_UPCOMING_EVENTS:
			return { ...state, loadingUpcomingEvents: true };

		case HomeTypes.LOAD_UPCOMING_EVENTS:
			return { ...state, upcomingEvents: action.payload.upcomingEvents, loadingUpcomingEvents: false };

		case HomeTypes.FAILURE_UPCOMING_EVENTS:
			return { ...state, upcomingEvents: INITIAL_UPCOMING_EVENTS, loadingUpcomingEvents: false };

		case HomeTypes.GET_ABOUT:
			return state

		case HomeTypes.GET_ABOUT_SUCCESS:
			return {
				...state,
				aboutData: action.payload.data,
			};

		case HomeTypes.GET_ABOUT_FAILURE:
			return {
				...state,
				aboutError: true,
			};

		case HomeTypes.SEND_RATING:
			return state;

		default:
			return state;
	}
};

export default reducer;
