import { eventApi, salesApi } from './Api';

const PurchasesService = (() => {
	function getAllPurchases(data: any, params: any) {
		return eventApi.post(`/user/my-purchase`, data, { params });
	}

	function getPurchaseTickets(id: string) {
		const params = { size: 200 };
		return eventApi.post(`/user/my-purchase/${id}`, {}, { params });
	}

	function downloadTicket(code: string) {
		return eventApi.get(`/user/my-tickets/export-pdf?attendeeCode=${code}`, { responseType: 'blob' });
	}

	function downloadAllTicket(eventId: number, purchaseId: number) {
		const params = { purchaseId };
		return eventApi.get(`/event/${eventId}/sales/ticket/export-pdf`, { params, responseType: 'blob' });
	}

	function listenPurchaseValidation(reserve: string) {
		return salesApi.get(`/public/checkout/sync/${reserve}`);
	}

	return {
		getAllPurchases: getAllPurchases,
		getPurchaseTickets: getPurchaseTickets,
		downloadTicket: downloadTicket,
		downloadAllTicket: downloadAllTicket,
		listenPurchaseValidation: listenPurchaseValidation,
	}
})();

export default PurchasesService;
