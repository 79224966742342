import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getOrganization } from 'store/ducks/organization/actions';
import { OrganizationState } from 'store/ducks/organization/types';

import { Seo } from 'components/standalone';
import PageNotFoundMessage from 'components/pageNotFound/PageNotFoundMessage/PageNotFoundMessage';
import { OrganizationCarousel, OrganizationInfo, OrganizationContent } from 'components/organization';

interface StateProps {
	organization: OrganizationState,
}

const OrganizationPage = () => {
	const { url } = useParams();
	const select = (state: StateProps) => state.organization;
	const dispatch = useDispatch();
	const { data, loading, error } = useSelector(select);

	useEffect(() => {
		dispatch(getOrganization(url));
	}, [url])

	return (
		<>
			{error ? <PageNotFoundMessage /> : (
				<>
					<Seo
						title={`${process.env.REACT_APP_NAME} - ${data.name}`}
						image={data.imageProfile}
						url={data.url}
					/>
					<OrganizationCarousel events={data.events} imageCover={data.imageCover} loading={loading} />
					<OrganizationInfo name={data.name} imageProfile={data.imageProfile} url={data.url} links={data.links} />
					<OrganizationContent events={data.events} about={data.about} />
				</>
			)}
		</>
	);
}

export default OrganizationPage;
