import React, { useEffect, useState } from 'react';

import { Timer } from 'components/standalone';
import * as S from './styles';
import TicketsList from 'components/event/TicketsList/TicketsList';
import LoginWarning from '../LoginWarning/LoginWarning';

export type Props = {
	eventId: string,
	affiliateCode?: string | null,
	ticketToken?: string | null,
	limitedByCpf: boolean,
	salesStartTime: number | null,
	hasMarketing: boolean,
	grouped: boolean,
	hasDiscount: boolean,
	meepId?: string,
}

const TicketsBox = ({ eventId, affiliateCode, ticketToken, limitedByCpf, salesStartTime, hasMarketing, grouped, hasDiscount, meepId }: Props) => {
	const [showTimer, setShowTimer] = useState(true);

	const startSales = () => {
		setShowTimer(false);
	}

	useEffect(() => {
		if (salesStartTime) {
			setShowTimer(true);
		}
	}, [salesStartTime]);

	const getTicketsLayout = () => {
		return (
			<TicketsList
				eventId={eventId}
				affiliateCode={affiliateCode}
				ticketToken={ticketToken}
				limitedByCpf={limitedByCpf}
				hasMarketing={hasMarketing}
				grouped={grouped}
				hasDiscount={hasDiscount}
				meepId={meepId}
			/>
		);
	}

	return (
		<S.TicketsBoxWrapper name="boxTop">
			<LoginWarning />

			{showTimer ? (
				<S.TicketsBox>
					<S.TicketsBoxText>O evento estará disponível para venda em</S.TicketsBoxText>
					<Timer time={salesStartTime} endTimeFn={startSales} />
				</S.TicketsBox>
			) : getTicketsLayout()
			}
		</S.TicketsBoxWrapper>
	)
}

export default TicketsBox;
