import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './App';

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DNS,
	integrations: [
		new Integrations.BrowserTracing({
			tracingOrigins: [(process.env.REACT_APP_URL || '')],
		})
	],
	tracesSampleRate: 1.0,
});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root'),
);
