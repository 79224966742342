import React, { useEffect, useState, useContext } from 'react';
import { Calendar, CheckCircle, MapPin } from 'react-feather';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { pt } from 'date-fns/locale';
import { toast } from 'react-toastify';
import Modal from 'react-modal';

import { ButtonLink, LinkButton, Button, Timer, Loading } from 'components/standalone';
import { dateGlobalPattern } from 'utils/dateUtils';
import { copyToClipboard } from 'utils/appUtils';
import PurchasesService from 'services/PurchasesService';
import * as S from './styles';
import PixelContext from 'context/pixel';
import CheckoutChallenge from './CheckoutChallenge';
import { RenderIf } from 'components/layout';

const customStyles = {
	overlay: {
		zIndex: 9999,
		backgroundColor: 'rgba(0, 0, 0, 0.25)'
	},
	content : {
		top: '100px',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translateX(-50%)',
		maxWidth: '450px',
		width: '90%',
	},
};

const CheckoutFeedback = ({ data }: any) => {
	const { state: pixelState } = useContext(PixelContext);
	const [purchaseType, setPurchaseType] = useState();
	const [isPurchasePaid, setIsPurchasePaid] = useState(false);
	const [timeToPay, setTimeToPay] = useState(data.reservationExpirationSeconds);
	const [showExpiredModal, setShowExpiredModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [challenge3ds, setChallenge3ds] = useState({ enabled: false, url: '', creq: '', resolved: false });
	let pixListener: any = null;
	let challengeListener: any = null;

	useEffect(() => {
		if (data && data.purchasePayments.length) {
			const purchasePayment = data.purchasePayments[0];

			setPurchaseType(purchasePayment.type);
			if (purchasePayment.type === 'PIX') {
				listenPix(data.reservationId);
				getPixTimer(data.reservationId);
			}

			if (purchasePayment.threeDsToken && purchasePayment.threeDsUrl) {
				setChallenge3ds({
					enabled: true,
					url: purchasePayment.threeDsUrl,
					creq: purchasePayment.threeDsToken,
					resolved: false,
				});
			}
		}
	}, [data])

	useEffect(() => {
		return () => {
			clearInterval(pixListener);
			clearInterval(challengeListener);
		}
	}, [])

	const getPixTimer = (reserve: string) => {
		PurchasesService.listenPurchaseValidation(reserve)
			.then(({data}: any) => {
				setTimeToPay(data.reservationExpirationSeconds);
			})
			.catch(err => console.log(err))
	}

	const listenPix = (reserve: string) => {
		pixListener = setInterval(() => {
			PurchasesService.listenPurchaseValidation(reserve)
				.then(({data}: any) => {
					if (data.purchasePayments[0].status === 'APPROVED') {
						setIsPurchasePaid(true);
						clearInterval(pixListener);
						return;
					}

					if (data.reservationExpirationSeconds <= 0) {
						setTimeToPay(0);
						clearInterval(pixListener);
					}
				})
				.catch(err => {
					console.log('Pix purchase validation', err)
					setShowErrorModal(true);
					clearInterval(pixListener);
				});
		}, 10000);
	}

	const copyPixCode = (code: string) => {
		copyToClipboard(code);
		toast('Código copiado com sucesso.', { type: toast.TYPE.SUCCESS });
	}

	const closePixReserve = () => {
		setShowExpiredModal(true);
	}

	const challenge3dsConcluded = () => {
		setChallenge3ds({
			...challenge3ds,
			enabled: false,
			resolved: true,
		});

		listen3dsPurchaseValidation();

		challengeListener = setInterval(() => {
			listen3dsPurchaseValidation();
		}, 10000);
	}

	const listen3dsPurchaseValidation = () => {
		PurchasesService.listenPurchaseValidation(data.reservationId)
			.then(({ data }: any) => {
				if (data.purchasePayments[0].status === 'APPROVED') {
					setIsPurchasePaid(true);
					setChallenge3ds({
						...challenge3ds,
						enabled: false,
						resolved: false,
					});
					clearInterval(challengeListener);
					return;
				}

				if (data.reservationExpirationSeconds <= 0) {
					setShowExpiredModal(true);
					clearInterval(challengeListener);
				}
			})
			.catch(err => {
				console.log('Challenge purchase validation', err);
				setShowErrorModal(true);
				clearInterval(challengeListener);
			});
	}

	return (
		<>
			<S.BoxFeedback>
				<S.OrderInfo>
					<div>
						{!isPurchasePaid && (purchaseType === 'TICKET' || purchaseType === 'CREDIT_CARD') && (data.purchasePayments.length > 0) && !['REJECTED', 'CANCELLED', 'REFUNDED', 'CHARGED_BACK'].includes(data.purchasePayments[0].status) &&  (
							<S.OrderInfoTitle>
								<CheckCircle size={24} /> &nbsp; <span>Pedido em processamento.</span>
							</S.OrderInfoTitle>
						)}

						{!isPurchasePaid && purchaseType === 'PIX' && (data.purchasePayments.length > 0) && !['REJECTED', 'CANCELLED', 'REFUNDED', 'CHARGED_BACK'].includes(data.purchasePayments[0].status) &&  (
							<S.OrderInfoTitlePending>
								<CheckCircle size={24} /> &nbsp; <span>Pedido aguardando pagamento.</span>
							</S.OrderInfoTitlePending>
						)}

						{isPurchasePaid && purchaseType === 'PIX' && (data.purchasePayments.length > 0) && !['REJECTED', 'CANCELLED', 'REFUNDED', 'CHARGED_BACK'].includes(data.purchasePayments[0].status) && (
							<S.OrderInfoTitle>
								<CheckCircle size={24} /> &nbsp; <span>Pedido efetuado com sucesso.</span>
							</S.OrderInfoTitle>
						)}

						{isPurchasePaid && purchaseType === 'CREDIT_CARD' && (data.purchasePayments.length > 0) && !['REJECTED', 'CANCELLED', 'REFUNDED', 'CHARGED_BACK'].includes(data.purchasePayments[0].status) && (
							<S.OrderInfoTitle>
								<CheckCircle size={24} /> &nbsp; <span>Pedido efetuado com sucesso.</span>
							</S.OrderInfoTitle>
						)}

						{(data.purchasePayments.length > 0) && ['REJECTED', 'CANCELLED', 'REFUNDED', 'CHARGED_BACK'].includes(data.purchasePayments[0].status) && (
							<S.OrderInfoTitle>
								<CheckCircle size={24} /> &nbsp; <span>Sua compra não foi aprovada</span>
							</S.OrderInfoTitle>
						)}

						{!data.purchasePayments.length && !data.baseValue && (
							<S.OrderText>Seus ingressos serão enviados no seu email. Eles também estão disponíveis na nossa plataforma.</S.OrderText>
						)}

						{(data.purchasePayments.length > 0) && (data.purchasePayments[0].type === 'TICKET') && (data.purchasePayments[0].status === 'PENDING') && (
							<S.OrderText>Seu boleto está disponível para download, após o pagamento em até três dias úteis seus ingressos estão disponíveis no email e na nossa plataforma.</S.OrderText>
						)}

						{(data.purchasePayments.length > 0) && (data.purchasePayments[0].type === 'CREDIT_CARD') && (data.purchasePayments[0].status === 'APPROVED') && (
							<S.OrderText>Seus ingressos serão enviados no seu email. Eles também estarão disponíveis na nossa plataforma.</S.OrderText>
						)}

						{(data.purchasePayments.length > 0) && (data.purchasePayments[0].type === 'CREDIT_CARD') && (['AUTHORIZED', 'IN_PROCESS', 'IN_MEDIATION'].includes(data.purchasePayments[0].status)) && (
							<S.OrderText>Estamos processando a sua compra, em breve você receberá um email com a finalização do seu pedido.</S.OrderText>
						)}

						{(data.purchasePayments.length > 0) && (data.purchasePayments[0].type === 'CREDIT_CARD') && (['REJECTED', 'CANCELLED', 'REFUNDED', 'CHARGED_BACK'].includes(data.purchasePayments[0].status)) && (
							<S.OrderText>Você pode acessar a tela do evento e realizar uma nova compra. Sugerimos que tente uma forma de pagamento diferente.</S.OrderText>
						)}

						{!isPurchasePaid && (data.purchasePayments.length > 0) && (data.purchasePayments[0].type === 'PIX') && (data.purchasePayments[0].status === 'PENDING') && (
							<>
								<S.OrderText>Abra seu aplicativo de banco, leia o QRCode ou copie a chave do PIX para finalizar o pagamento. Aguarde o processamento e seus ingressos estarão disponíveis aqui.</S.OrderText>
								<S.OrderText>Fique atento, o prazo para pagamento do seu pedido expira em:</S.OrderText>
								<Timer time={timeToPay} endTimeFn={closePixReserve}/>
								<br />
							</>
						)}

						{isPurchasePaid && (data.purchasePayments.length > 0) && (data.purchasePayments[0].type === 'PIX') && (
							<S.OrderText>Seus ingressos serão enviados no seu email. Eles também estão disponíveis na nossa plataforma.</S.OrderText>
						)}

						{isPurchasePaid && (data.purchasePayments.length > 0) && (data.purchasePayments[0].type === 'CREDIT_CARD') && (
							<S.OrderText>Seus ingressos serão enviados no seu email. Eles também estão disponíveis na nossa plataforma.</S.OrderText>
						)}

						<S.OrderNumberTitle>Nº do Pedido</S.OrderNumberTitle>
						<S.OrderNumber id="orderCode">{data.code}</S.OrderNumber>
					</div>

					<S.OrderStatusBox>
						{!data.purchasePayments.length && !data.baseValue && (
							<LinkButton to="/meus-ingressos">Ver meus ingressos</LinkButton>
						)}

						{(data.purchasePayments.length > 0) && (data.purchasePayments[0].type === 'CREDIT_CARD') && (data.purchasePayments[0].status === 'APPROVED') && (
							<LinkButton to="/meus-ingressos">Ver meus ingressos</LinkButton>
						)}

						{(data.purchasePayments.length > 0) && (data.purchasePayments[0].type === 'TICKET') && (data.purchasePayments[0].status === 'PENDING') && (
							<S.OrderLink download target="_blank" href={data.purchasePayments[0].bankSlipUrl}>Download do boleto</S.OrderLink>
						)}

						{!isPurchasePaid && (data.purchasePayments.length > 0) && (data.purchasePayments[0].type === 'PIX') && (data.purchasePayments[0].status === 'PENDING') && (
							<>
								<S.QRCode src={`data:image/jpeg;base64,${data.purchasePayments[0].qrCodeBase64}`}></S.QRCode>
								<S.PixCodeKey value={data.purchasePayments[0].qrCode} disabled></S.PixCodeKey>
								<Button handleClick={() => copyPixCode(data.purchasePayments[0].qrCode)}>Copiar código</Button>
							</>
						)}

						{isPurchasePaid && (data.purchasePayments.length > 0) && (data.purchasePayments[0].type === 'PIX') && (
							<>
								{pixelState.active
									? (<Button><a href={`${process.env.REACT_APP_URL}/meus-ingressos`}>Ver meus ingressos</a></Button>)
									: (<LinkButton to="/meus-ingressos">Ver meus ingressos</LinkButton>)
								}
							</>
						)}
					</S.OrderStatusBox>
				</S.OrderInfo>

				<RenderIf condition={challenge3ds.enabled}>
					<CheckoutChallenge
						externalUrl={challenge3ds.url}
						creq={challenge3ds.creq}
						concludeFn={challenge3dsConcluded}
						expiredFn={() => setShowExpiredModal(true)}
					/>
				</RenderIf>

				<RenderIf condition={challenge3ds.resolved}>
					<Loading text="Estamos finalizando sua compra, aguarde um momento" height='200px' />
				</RenderIf>

				<S.Event>
					<S.EventImage thumbnail={data.event.thumbnail}></S.EventImage>

					<div>
						<S.Name>{data.event.name}</S.Name>
						<S.EventInfo>
							<Calendar /> &nbsp; {format(new Date(dateGlobalPattern(data.event.startDate)), "dd 'de' MMMM 'de' yyyy',' HH:mm", {locale: pt})} - {format(new Date(dateGlobalPattern(data.event.endDate)), "dd 'de' MMMM 'de' yyyy',' HH:mm", {locale: pt})}
						</S.EventInfo>
						<S.EventInfo>
							<MapPin />  &nbsp; {data.event.locationType === 'VIRTUAL' ? 'Evento virtual' : data.event.fullAddress}
						</S.EventInfo>
						<ButtonLink>
							{pixelState.active
								? (<a href={`${process.env.REACT_APP_URL}/${data.event.url}`}>Ver evento</a>)
								: (<Link to={'/' + data.event.url}>Ver evento</Link>)
							}
						</ButtonLink>
					</div>
				</S.Event>

				<S.OrderMessage>Acompanhe seu email, toda a comunicação, avisos e ingressos serão feitos por lá!</S.OrderMessage>
			</S.BoxFeedback>

			<Modal
				isOpen={showExpiredModal}
				style={customStyles}
			>
				<S.PixModal>
					<S.PixModalTitle>O tempo para conclusão da compra expirou</S.PixModalTitle>
					<S.PixModalMessage>Isso é necessário para que uma reserva não fique presa e possa estar disponível para compra novamente. Você pode recomeçar clicando no botão abaixo.</S.PixModalMessage>

					<Button>
						{pixelState.active
							? (<a href={`${process.env.REACT_APP_URL}/${data.event.url}`}>Comprar novamente</a>)
							: (<Link to={'/' + data.event.url}>Comprar novamente</Link>)
						}
					</Button>
				</S.PixModal>
			</Modal>

			{/* TODO condicionar ao modal de cima */}
			<Modal
				isOpen={showErrorModal}
				style={customStyles}
			>
				<S.PixModal>
					<S.PixModalTitle>Não foi possível processar sua compra</S.PixModalTitle>
					<S.PixModalMessage>Você pode fazer uma nova clicando no botão abaixo.</S.PixModalMessage>

					<Button>
						{pixelState.active
							? (<a href={`${process.env.REACT_APP_URL}/${data.event.url}`}>Comprar novamente</a>)
							: (<Link to={'/' + data.event.url}>Comprar novamente</Link>)
						}
					</Button>
				</S.PixModal>
			</Modal>
		</>
	);
}

export default CheckoutFeedback;
