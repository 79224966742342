import { createStore, applyMiddleware, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './ducks/rootReducer';
import rootSaga from './ducks/rootSaga';

import { EventState } from './ducks/event/types';
import { Nav } from './ducks/nav/types';
import { HomeState } from './ducks/home/types';
import { SearchState } from './ducks/search/types';
import { TicketsState } from './ducks/tickets/types';

export interface ApplicationState {
    event: EventState;
    nav: Nav;
    home: HomeState;
    search: SearchState;
    tickets: TicketsState;
}

const sagaMiddleware = createSagaMiddleware();

const store: Store<ApplicationState> = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);

export default store;
