import React, { ReactNode } from 'react';

import * as S from './styles';

export type Props = {
	children: ReactNode,
}

const EventInformation = ({ children }: Props) => (
	<S.EventInformationWrapper>
		{children}
	</S.EventInformationWrapper>
);

export default EventInformation;
