type colors = {
	main: string,
	second: string,
	third: string,
	light: string,
	dark: string,
	info: string,
	danger: string,
	success: string,
	warning: string,
	muted: string,
};

type backgrounds = {
	main: string,
	light: string,
	info: string,
	danger: string,
	success: string,
	warning: string,
	muted: string,
};

type shadows = {
	level1: string,
	level2: string,
	level3: string,
	level4: string,
	level5: string,
};

type variables = {
	colors: colors,
	boxShadows: shadows,
	backgrounds: backgrounds,
};

const Variables: variables = {
	colors: {
		main: `${process.env.REACT_APP_COLOR_MAIN}`,
		second: `${process.env.REACT_APP_COLOR_SECOND}`,
		third: `${process.env.REACT_APP_COLOR_THIRD}`,
		light: '#ffffff',
		dark: '#212121',
		info: '#4BB1CF',
		danger: '#ED4F32',
		success: '#15CD72',
		warning: '#EDE04D',
		muted: '#ECECEC',
	},
	backgrounds: {
		main: `${process.env.REACT_APP_COLOR_MAIN}`,
		light: '#ffffff',
		info: '#4BB1CF',
		danger: '#ED4F32',
		success: '#15CD72',
		warning: '#EDE04D',
		muted: '#ECECEC',
	},
	boxShadows: {
		level1: '0 1px 3px rgba(80, 80, 80, 0.12), 0 1px 2px rgba(80, 80, 80, 0.24)',
		level2: '0 3px 6px rgba(80, 80, 80, 0.16), 0 3px 6px rgba(80, 80, 80, 0.23)',
		level3: '0 10px 20px rgba(80, 80, 80, 0.19), 0 6px 6px rgba(80, 80, 80, 0.23)',
		level4: '0 14px 28px rgba(80, 80, 80, 0.25), 0 10px 10px rgba(80, 80, 80, 0.22)',
		level5: '0 19px 38px rgba(80, 80, 80, 0.3), 0 15px 12px rgba(80, 80, 80, 0.22)',
	},
};

export default Variables;
