import styled from 'styled-components';
import Variables from 'styles/Variables';
import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';
import { StyleProps } from './types';

export const SectionWrapper = styled.section`
	width: 100%;
	max-width: 1100px;
	padding: ${({ noPadding }: StyleProps) => noPadding ? 0 : '0 15px'};
	margin-left: auto;
	margin-right: auto;
	margin-top: ${({ marginTop }: StyleProps) => `${marginTop || 0}px`};
	margin-bottom: ${({ marginBottom }: StyleProps) => `${marginBottom || 0}px`};
`;

export const AboutTitle = styled.h2`
	font-size: 1.25rem;
	font-weight: 600;
	margin-bottom: 15px;
	text-align: ${({ align }: StyleProps) => align || 'left'};
	color: ${({ textLight }: StyleProps) => textLight ? Variables.colors.light : Variables.colors.main};

	${breakAt(BreakpointsSizes.md)} {
		font-size: 1.6rem;
	}

	${breakAt(BreakpointsSizes.exl)} {
		font-size: 1.875rem;
	}
`;

export const AboutText = styled.p`
	font-size: 1rem;
	text-align: ${({ align }: StyleProps) => align || 'justify'};
	${({ colored }: StyleProps) => colored && `color: ${Variables.colors.main}`};
	margin-bottom: 10px;

	${breakAt(BreakpointsSizes.md)} {
		font-size: 1.1rem;
	}

	${breakAt(BreakpointsSizes.exl)} {
		font-size: 1.25rem;
	}
`;

export const AboutImage = styled.img`
	max-height: 250px;
	height: auto;
	max-width: 300px;
	width: auto;
	margin: 20px auto;
	display: block;
	padding: 15px;

	${breakAt(BreakpointsSizes.md)} {
		max-height: 300px;
		max-width: 350px;
		margin: 20px auto;
		padding: 30px;
	}
`;

// ---------- Section one
export const SectionOneWrapper = styled.section`
	background-color: ${Variables.backgrounds.main};
	color: ${Variables.colors.light};
	padding: 50px 15px;
	margin: 50px 0 75px;
`;

export const SectionOneCardWrapper = styled.div`
	display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(2, minmax(calc(50% - 20px), 300px));
	margin: 25px 0 -120px;

	${breakAt(BreakpointsSizes.md)} {
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
	}
`;

export const SectionOneCard = styled.div`
	background-color: ${Variables.backgrounds.light};
	color: ${Variables.colors.main};
	border-radius: 10px;
	box-shadow: ${Variables.boxShadows.level3};
	padding: 20px 5px 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const SectionOneCardTitle = styled.span`
	font-weight: 600;
	font-size: 0.70rem;
	text-align: center;

	${breakAt(BreakpointsSizes.md)} {
		font-size: 1rem;
	}
`;

export const SectionOneCardSubtitle = styled.span`
	font-size: 0.70rem;
	margin: 5px 0 10px;
	text-align: center;
`;

export const SectionOneCardSubtitle2 = styled.span`
	font-size: 1.2rem;
	margin: 15px 0 10px;
	text-align: center;

	${breakAt(BreakpointsSizes.md)} {
		margin-top: 0;
	}
`;

export const SectionOneCardValue = styled.span`
	font-size: 2.5rem;
	margin-top: auto;
`;

// ---------- Section three
export const SectionThreeItem =  styled.div`
	${breakAt(BreakpointsSizes.md)} {
		display: flex;
		align-items: center;
		flex-direction: ${({ invert }: StyleProps) => invert ? 'row' : 'row-reverse'}
	}
`;

export const SectionThreeInfo =  styled.div`
	flex-grow: 1;
	${breakAt(BreakpointsSizes.md)} {
		margin: 0 15px;
	}
`;

// ---------- Section five
export const SectionFiveWrapper = styled.div`
	display: grid;
	grid-gap: 20px;
	padding: 0 20px;

	${breakAt(BreakpointsSizes.md)} {
		grid-template-columns: 1fr 1fr;
	}

	${breakAt(BreakpointsSizes.lg)} {
		grid-template-columns: 1fr 1fr 1fr;
	}
`;

export const SectionFiveMobileWrapper = styled.div``;

export const SectionFiveCardWrapper = styled.div`
	min-height: 100%;
	display: flex;

	${breakAt(BreakpointsSizes.md)} {
		padding: 20px 10px;
	}
`;

export const SectionFiveCard = styled.div`
	background-color: ${Variables.backgrounds.light};
	border-radius: 10px;
	box-shadow: ${Variables.boxShadows.level3};
	padding: 30px;

	${breakAt(BreakpointsSizes.md)} {
		padding: 30px 50px;
	}
`;

export const SectionFiveCardHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;

	${breakAt(BreakpointsSizes.md)} {
		flex-direction: column;
	}
`;

export const SectionFiveCardTitle = styled.span`
	font-size: 1.25rem;

	${breakAt(BreakpointsSizes.md)} {
		font-size: 1.35rem;
		width: 100%;
		text-align: center;
	}

	${breakAt(BreakpointsSizes.exl)} {
		font-size: 1.5rem;
	}
`;

export const SectionFiveCardImage = styled.img`
	width: 50px;
	height: 50px;
	margin-left: 10px;

	${breakAt(BreakpointsSizes.md)} {
		width: 100px;
		height: 100px;
		margin: 25px;
	}

	${breakAt(BreakpointsSizes.exl)} {
		width: 130px;
		height: 130px;
		margin: 45px 0 30px;
	}
`;

export const SectionFiveCardContent = styled.div`
	text-align: justify;
	font-size: 0.75rem;
	margin-bottom: 10px;

	${breakAt(BreakpointsSizes.md)} {
		font-size: 1rem;
	}
`;
