import React, { createContext, useState } from 'react';

type PixelType = {
	active: boolean,
    code: string,
    eventCheckout: boolean,
    eventInitiateCheckout: boolean,
    eventAddToCart: boolean,
}

type PropsPixelContext = {
    state: PixelType,
    setState: React.Dispatch<React.SetStateAction<PixelType>>,
}

const DEFAULT_VALUE = {
    state: {
		active: false,
        code: '',
		eventCheckout: false,
		eventInitiateCheckout: false,
		eventAddToCart: false,
    },
    setState: () => {},
}

const PixelContext = createContext<PropsPixelContext>(DEFAULT_VALUE);

const PixelContextProvider: React.FC = ({ children }) => {
    const [state, setState] = useState(DEFAULT_VALUE.state);
    return (
        <PixelContext.Provider
            value={{
                state,
                setState,
            }}
        >{ children }</PixelContext.Provider>
    )
}

export { PixelContextProvider };
export default PixelContext;
