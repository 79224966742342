import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Modal from 'react-modal';

import { Button, Loading } from 'components/standalone';
import IndividualTicket from '../IndividualTicket/IndividualTicket';
import PurchasesService from 'services/PurchasesService';
import * as S from './styles';

const customStyles = {
	overlay: {
		zIndex: 9999,
		backgroundColor: 'rgba(0, 0, 0, 0.25)',
		overflow: 'auto',
	},
	content : {
		top: '100px',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translateX(-50%)',
		maxWidth: '600px',
		width: '90%',
	},
};

interface Ticket {
	attendeeCode: string;
	attendeeFirstName: string;
	attendeeLastName: string;
	ticketBatch: string;
	ticketName: string;
	ticketPrice: number;
}

const PurchaseModal = ({ isOpen, eventName, id, closeModalFn }: any) => {
	const [ tickets, setTickets ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(true);


	useEffect(() => {
		if (id) {
			setIsLoading(true);
			getTickets();
		}
	}, [id])

	const getTickets = () => {
		PurchasesService.getPurchaseTickets(id)
			.then(({ data }: any) => {
				setIsLoading(false);
				setTickets(data.content);
			})
			.catch(({ data }: any) => {
				closeModalFn();
				toast(data.detail, { type: toast.TYPE.ERROR });
			})
	}

	return (
		<Modal
			isOpen={isOpen}
			style={customStyles}
			ariaHideApp={false}
			onAfterOpen={() => document.body.style.overflow = 'hidden'}
			onAfterClose={() => document.body.style.overflow = 'unset'}>
			<S.Title>Ingressos - {eventName}</S.Title>

			{isLoading
				? <Loading text='Buscando seus ingressos' height='200px' />
				: tickets.map((ticket: Ticket) => <IndividualTicket key={ticket.attendeeCode} ticket={ticket} />)
			}

			<S.Footer>
				<Button handleClick={closeModalFn}>Fechar</Button>
			</S.Footer>
		</Modal>
	);
}

export default PurchaseModal;
