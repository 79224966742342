import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';

import { StyleProps } from './types';

export const OrganizationCarouselWrapper = styled.div`
	background: lightgray;
	overflow: hidden;

	${breakAt(BreakpointsSizes.md)} {
		padding: 20px 0;
	}
`;

// ---------- ORGANIZATION BANNER
export const OrganizationBanner = styled.div`
	background-image: url(${({ imageCover }: StyleProps) => imageCover || `${process.env.REACT_APP_BUCKET_URL}/images/banner-fallback.jpg`});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 280px;
	width: 100%;

	${breakAt(BreakpointsSizes.md)} {
		height: 380px;
	}
`;

// ---------- ORGANIZATION EVENT BANNER
export const OrganizationEventBannerWrapper = styled.section`
	margin: 0 auto;

	${breakAt(BreakpointsSizes.md)} {
		padding: 0 30px;
	}
`;

export const OrganizationEventBanner = styled(Link)`
	background-image: url(${({ banner }: StyleProps) => banner || `${process.env.REACT_APP_BUCKET_URL}/images/banner-fallback.jpg`});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 280px;
	width: 100%;
	max-width: 1200px;
	box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	position: relative;
	display: flex;
    flex-direction: column;
    justify-content: flex-end;

	&:before {
		content: '';
		position: absolute;
		height: 100%;
		width: 100%;
		background: linear-gradient(
			to top,
			rgba(0, 0, 0, 0.5) 0%,
			rgba(0, 0, 0, 0.3) 40%,
			transparent 100%
		);
		top: 0;
		left: 0;
	}

	${breakAt(BreakpointsSizes.md)} {
		border-radius: 20px;
		height: 380px;
	}
`;

export const EventTitle = styled.h1`
	color: #fff;
	font-size: 1.5rem;
	font-weight: 600;
	letter-spacing: 0.5px;
	padding: 0 20px;
	z-index: 1;
`;

export const InfoWrapper = styled.div`
	padding: 15px 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	${breakAt(BreakpointsSizes.md)} {
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-end;
	}
`;

export const EventDateTime = styled.span`
	position: relative;
	z-index: 1;
	color: #fff;
	font-size: 0.75rem;
	display: flex;
	align-items: center;
	margin-bottom: 5px;
`;

export const EventAddress = styled.span`
	position: relative;
	z-index: 1;
	color: #fff;
	font-size: 0.75rem;
	display: Flex;
	align-items: center;
	margin-bottom: 5px;
`;

export const Text = styled.span`
	padding: 0 10px;
	text-align: left;
`;
