import { RenderIf } from 'components/layout';
import React, { useEffect, useState } from 'react';
import { UserPlus, X } from 'react-feather';
import { Container } from 'styles';

import * as S from './styles';

const HomeLoginWarning = () => {
	const [isWarningOpen, setIsWarningOpen] = useState('SHOW');

	useEffect(() => {
		setIsWarningOpen(() => localStorage.getItem('LOGIN_WARNING_OPEN') || 'SHOW');
	}, [])

	const closeLoginWarning = () => {
		localStorage.setItem('LOGIN_WARNING_OPEN', 'HIDE');
		setIsWarningOpen(() => 'HIDE');
	}

	return (
		<RenderIf condition={isWarningOpen === 'SHOW'}>
			<Container style={{position: 'relative'}}>
				<S.NavLoginWarningWrapper>
					<S.NavLoginWarningIcon>
						<UserPlus size={24} color="#fff" />
					</S.NavLoginWarningIcon>

					<S.NavLoginWarningText>
						Atenção! Agora, para comprar seus ingressos, é necessário estar logado na sua conta!
					</S.NavLoginWarningText>

					<S.NavLoginWarningClose id="closeLoginWarning" onClick={closeLoginWarning}>
						<X size={18} color="#fff" />
					</S.NavLoginWarningClose>
				</S.NavLoginWarningWrapper>
			</Container>
		</RenderIf>
	);
}

export default HomeLoginWarning;
