import React from 'react';

import * as S from './styles';
import { LoadingProps } from './types';

const Loading = ({ text, height }: LoadingProps) => (
	<>
		<S.LoadingWrapper height={height}>
			<S.Loading />
			{text && <S.Text>{text}</S.Text>}
		</S.LoadingWrapper>
	</>
);

export default Loading;
