import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Variables from 'styles/Variables';
import { StyleProps } from './types';

type thumbProps = {
	thumbnail: string;
	short?: boolean;
	main?: boolean;
}

export const Card = styled(Link)`
	margin: 0 auto;
	border-radius: 10px;
	background: #fff;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	height: ${({ short }: StyleProps) => short ? '280px !important' : '330px'};
	height: ${({ main }: StyleProps) => main ? '350px' : '330px'};
	width: 100%;
	user-select: none;
	box-shadow: ${Variables.boxShadows.level1};
`;

export const Image = styled.div`
	background: ${({ thumbnail }: thumbProps) => `url('${thumbnail}')`}, ${Variables.colors.main};
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	min-height: 160px;
	border-radius: 10px;
	height: ${({ short }: thumbProps) => short ? '160px !important' : '210px'};
	height: ${({ main }: thumbProps) => main ? '210px' : '190px'};
`;

export const InfoWrapper = styled.div`
	background: #fff;
	padding: 15px 15px 15px 25px;
	flex-grow: 1;
	display: flex;
`;

export const EventTitle = styled.div`
	font-size: 1.25rem;
	margin-bottom: 10px;
	font-weight: 600;
	letter-spacing: 0.5px;
	max-height: 50px;
	overflow: hidden;
	position: relative;
	&:after {
		content: "";
		text-align: right;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 25%;
		height: 1.2em;
		background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
	}
`;

export const DateWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const DateText = styled.span`
	text-transform: uppercase;
	font-size: 0.75rem;
	font-weight: 700;
	margin-bottom: 5px;
	opacity: 0.75;
`;

export const DateDay = styled.span`
	font-size: 2rem;
    font-weight: 600;
    line-height: 1;
	margin-bottom: 5px;
    color: ${Variables.colors.main};
`;

export const DateTime = styled.span`
	opacity: 0.75;
	font-size: 0.9rem;
`;

export const NameAndAddress = styled.div`
	margin-left: 25px;
	flex-grow: 1;
`;

export const EventAddress = styled.div`
	font-size: 0.75rem;
	display: Flex;
	align-items: center;
`;

export const Text = styled.span`
	padding: 0 10px;
	opacity: 0.75;
`;
