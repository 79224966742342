import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { isMobile } from 'react-device-detect';

import { OrganizationInfoProps } from './types';

import { Link } from 'store/ducks/organization/types';
import { Container } from 'styles';
import * as S from './styles';

const OrganizationInfo = ({ name, imageProfile, url, links }: OrganizationInfoProps) => (
	<Container>
		<S.OrganizationInfoWrapper>
			{url
				? (<S.OrganizationProfilePicture imageProfile={imageProfile} />)
				: (<Skeleton circle width={isMobile ? 70 : 120} height={isMobile ? 70 : 120} />)
			}
			<S.OrganizationProfileInfo>
				<S.OrganizationName>{name || <Skeleton width={200} />}</S.OrganizationName>
				<S.OrganizationSocialList>
					{links.map((link: Link) => (
						<S.OrganizationLink key={link.id} href={link.url} title={link.text} data-type={link.type} target="_blank"></S.OrganizationLink>
					))}
				</S.OrganizationSocialList>
			</S.OrganizationProfileInfo>
		</S.OrganizationInfoWrapper>
	</Container>
);

export default OrganizationInfo;
