import styled from 'styled-components';

type inviteType = { image: string }

export const InviteResponseWrapper = styled.div`
	padding: 20px 20px 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const InviteResponseImage = styled.div`
	height: 350px;
	width: 100%;
	max-width: 450px;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	background-image: url(${({ image }: inviteType) => `${process.env.PUBLIC_URL}/images/${image}`});
`;

export const InviteMessage = styled.h1`
	margin: 20px 10px 30px;
	font-size: 1.5rem;
	font-weight: 700;
	opacity: 0.8;
`;
