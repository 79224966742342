import React from 'react';

import PageNotFoundMessage from 'components/pageNotFound/PageNotFoundMessage/PageNotFoundMessage';
import { Seo } from 'components/standalone';

const PageNotFound = () => (
	<>
		<Seo title={`${process.env.REACT_APP_NAME} - Página não encontrada`} />
		<PageNotFoundMessage />
	</>
);

export default PageNotFound;
