import styled from 'styled-components';

import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';
import { EventCardListProps } from './types';

export const Root = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-column-gap: ${({ gap }: EventCardListProps) => `${gap || 16}px`};
	grid-row-gap: 25px;
	margin-bottom: ${({ marginBottom }: EventCardListProps) => `${marginBottom || 0}px`};

	${breakAt(BreakpointsSizes.lg)} {
		display: grid;
		grid-template-columns: repeat(${({ lineItems }: EventCardListProps) => lineItems}, 1fr);
	}
`;
