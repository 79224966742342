import styled from 'styled-components';
import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';
import Variables from 'styles/Variables';

type StyleProps = {
	bottom: boolean;
}
export const HelpToggler = styled.div`
	align-items: center;
	bottom: ${({bottom}: StyleProps) => bottom ? '4.5rem' : '1rem'};
	cursor: pointer;
	display: flex;
	position: fixed;
	right: 1.5rem;
	z-index: 6000;

	${breakAt(BreakpointsSizes.lg)} {
		bottom: 1.5rem;
	}
`;

export const HelpButton = styled.div`
	align-items: center;
	background-color: ${Variables.backgrounds.main};
	border: 2px solid #FFF;
	border-radius: 50%;
	color: #FFF;
	display: flex;
	font-size: 1.125rem;
	font-weight: 700;
	height: 2.5rem;
    justify-content: center;
	line-height: 1;
	transition: 0.2s ease;
	width: 2.5rem;
`;

export const HelpText = styled.div`
	background-color: #FFF;
	border-radius: 0.625rem;
	box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
	font-size: 0.875rem;
	margin-right: 0.5rem;
	padding: 0.75rem;

	${breakAt(BreakpointsSizes.sm, true)} {
		display: none;
	}
`;

type HelpBoxProps = {
	isOpen: boolean;
}
export const HelpBox = styled.div`
	background-color: #FFF;
	border-radius: 1rem 1rem 0 0;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    bottom: ${({ isOpen }: HelpBoxProps) => isOpen ? '0' : '-100%'};
	padding: 2rem;
	position: fixed;
    right: 1.5rem;
	transition: 0.3s ease-in;
	width: 280px;
    z-index: 9000;

	${breakAt(BreakpointsSizes.sm, true)} {
		right: 0;
		width: 100%;
	}
`;

export const HelpBoxHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
`;

export const HelpBoxTitle = styled.div`
	color: #333;
	font-weight: 600;
`;

export const HelpBoxClose = styled.div`
	cursor: pointer;
	min-width: 2rem;
	text-align: right;
`;

export const HelpBoxLink = styled.a`
	color: #5F5F5F;
	display: block;
	font-size: 0.875rem;
	margin-bottom: 1rem;
	text-decoration: underline;
`;

export const HelpBoxButtonLink = styled.a`
	background-color: ${Variables.backgrounds.main};
	border: 1px solid ${Variables.colors.main};
	border-radius: 0.625rem;
	color: #FFF;
	cursor: pointer;
	display: block;
	font-size: 0.875rem;
	padding: 0.5rem;
	text-align: center;
`;

export const HelpBoxButtonLinkOutline = styled(HelpBoxButtonLink)`
	background-color: transparent;
	color: ${Variables.colors.main};
	margin-top: 0.5rem;
`;

export const ScreenFade = styled.div`
	background-color: rgba(0, 0, 0, 0.4);
	bottom: 0;
	display: ${({ isOpen }: HelpBoxProps) => isOpen ? 'block' : 'none'};
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 8000;
`;
