// Action types
export enum HomeTypes {
	GET_MAIN_EVENTS = '@home/GET_MAIN_EVENTS',
	LOAD_MAIN_EVENTS = '@home/LOAD_MAIN_EVENTS',
	FAILURE_MAIN_EVENTS = '@home/FAILURE_MAIN_EVENTS',

	GET_FEATURED_EVENTS = '@home/GET_FEATURED_EVENTS',
	LOAD_FEATURED_EVENTS = '@home/LOAD_FEATURED_EVENTS',
	FAILURE_FEATURED_EVENTS = '@home/FAILURE_FEATURED_EVENTS',

	GET_UPCOMING_EVENTS = '@home/GET_UPCOMING_EVENTS',
	LOAD_UPCOMING_EVENTS = '@home/LOAD_UPCOMING_EVENTS',
	FAILURE_UPCOMING_EVENTS = '@home/FAILURE_UPCOMING_EVENTS',

	GET_ABOUT = '@home/GET_ABOUT',
	GET_ABOUT_SUCCESS = '@home/GET_ABOUT_SUCCESS',
	GET_ABOUT_FAILURE = '@home/GET_ABOUT_FAILURE',

	SEND_RATING = '@home/SEND_RATING',
}

// Data types
export interface Event {
	name: string;
	banner: string;
	thumbnail: string;
	startDate: string;
	fullAddress?: string;
	locationName: string;
	locationType: string;
	url: string;
	seatsMapActived: boolean;
	featuredBanner?: string;
	hasFacebookPixel: boolean,
}

export interface UpcomingEvents {
	onTheRise: Event[],
	otherEvents: Event[],
}

export interface ExternalEvent {
	name: string;
	startDate: string;
	fullAddress?: string;
	locationName: string;
}

// State type
export interface HomeState {
	readonly mainEvents: Event[];
	readonly loadingMainEvents: Boolean;

	readonly featuredEvents: Event[];
	readonly loadingFeaturedEvents: boolean;

	readonly upcomingEvents: UpcomingEvents;
	readonly loadingUpcomingEvents: boolean;

	readonly aboutData: any;
	readonly aboutError: boolean;
}
