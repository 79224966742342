import { Loading } from 'components/standalone';
import AccreditationContext from 'context/accreditation';
import React, { useContext, useEffect, useState } from 'react';
import { Calendar, MapPin } from 'react-feather';
import { Link } from 'react-router-dom';
import AccreditationService from 'services/AccreditationService';

import { Container, IconWrapper } from 'styles';
import { Card } from 'styles/Commons';
import { Button, ButtonOutline } from 'styles/Form';
import { scrollToTop } from 'utils/appUtils';
import { getFrontDate } from 'utils/dateUtils';
import InviteParticipantForm from '../InviteParticipantForm/InviteParticipantForm';
import * as S from '../styles';

enum PARTICIPANTS_FLOW_STEP {
	REGISTER_PARTICIPANTS = 'REGISTER_PARTICIPANTS',
	FINISHED = 'FINISHED',
}

const InviteParticipantFlow = ({ event, continueOnInviteConclude }: any) => {
	const { state } = useContext(AccreditationContext);
	const [currentStep, setCurrentStep] = useState(PARTICIPANTS_FLOW_STEP.REGISTER_PARTICIPANTS);
	const [participants, setParticipants] = useState([]);
	const [accreditedName, setAccreditedName] = useState('');
	const [formActive, setFormActive] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		getReservationData();
	}, [])

	useEffect(() => {
		if (continueOnInviteConclude) {
			setCurrentStep(PARTICIPANTS_FLOW_STEP.FINISHED);
		}

	}, [continueOnInviteConclude])

	const updateParticipants = () => {
		setFormActive(() => false);
		getReservationData();
	}

	const getReservationData = () => {
		setIsLoading(true);
		AccreditationService.getReservationData(event.id, state.reservation, state.apiKey)
			.then(({ data }: any) => {
				const participantList = participantsHandler(data.attendees);

				setAccreditedName(data.userFirstName);
				setParticipants(() => participantList);
				setFormActive(() => participantList.length === 0);
			})
			.finally(() => setIsLoading(false))
	}

	const participantsHandler = (attendees: any) => {
		const participants: any = [];
		attendees.forEach((attendee: any) => {
			if (!attendee.isAssociate) {
				participants.push({
					id: attendee.id,
					name: attendee.userFirstName,
					email: attendee.userEmail,
					phone: attendee.userPhone,
					cpf: attendee.userCpf,
				})
			}
		});

		return participants;
	}

	const removeParticipant = (id: number) => {
		if (id) {
			getReservationData();
			return;
		}

		setFormActive(false);
	}

	const sendInvites = () => {
		AccreditationService.sendParticipantsInvite(event.id, state.reservation, state.apiKey)
			.then(() => {
				setCurrentStep(PARTICIPANTS_FLOW_STEP.FINISHED);
				scrollToTop();
			});
	}

	return (
		<Container marginTop={50} marginBottom={50} style={{maxWidth: '600px'}}>
			{currentStep === PARTICIPANTS_FLOW_STEP.REGISTER_PARTICIPANTS && (
				<>
					<S.EventInfo>
						<S.EventName>{event.name}</S.EventName>
						<S.EventTextWrapper>
							<IconWrapper height={18} width={18}>
								<MapPin size={18} color="gray" />
							</IconWrapper>
							<S.EventText>{getFrontDate(event.startDate)}</S.EventText>
						</S.EventTextWrapper>

						<S.EventTextWrapper>
							<IconWrapper height={18} width={18}>
								<Calendar size={18} color="gray" />
							</IconWrapper>
							<S.EventText>{event.fullAddress || 'Virtual'}</S.EventText>
						</S.EventTextWrapper>
					</S.EventInfo>

					{isLoading
						? <Loading height='200px' />
						: (
							<>
								<Card padding="1.5rem" margin="0 0 1.5rem">
									<S.CardHeader>
										<S.CardTitle>Associado</S.CardTitle>
									</S.CardHeader>
									<p>{accreditedName}</p>
								</Card>

								{participants.length > 0 && (
									<>
										{participants.map((participant: any, index) => (
											<InviteParticipantForm
												key={participant.id}
												participant={participant}
												unique={participants.length <= 1}
												eventId={event.id}
												apiKey={state.apiKey}
												reservation={state.reservation}
												position={index + 1}
												onConclude={updateParticipants}
												onRemove={($event: any) => removeParticipant($event)}
											/>
										))}
									</>
								)}

								{formActive && (
									<InviteParticipantForm
										eventId={event.id}
										apiKey={state.apiKey}
										reservation={state.reservation}
										unique={participants.length <= 1}
										position={participants.length + 1}
										onConclude={updateParticipants}
										onRemove={($event: any) => removeParticipant($event)}
									/>
								)}

								<ButtonOutline
									width="100%"
									margin="0 0 1rem"
									disabled={formActive || participants.length === state.guestQuantity}
									onClick={() => setFormActive(() => true)}>Cadastrar novo convidado
								</ButtonOutline>

								<Button width="100%" disabled={participants.length === 0} onClick={sendInvites}>Enviar convites</Button>
							</>
						)
					}
				</>
			)}

			{currentStep === PARTICIPANTS_FLOW_STEP.FINISHED && (
				<>
					<S.InviteParticipantFeedbackWrapper>
						<S.FeedbackIcon></S.FeedbackIcon>
						<S.FeedbackTitle>Convites enviados com sucesso!</S.FeedbackTitle>
						<S.FeedbackMessage>Seus convites foram enviados com sucesso. Os convidados devem acessar o link enviado para obterem o credenciamento.</S.FeedbackMessage>
					</S.InviteParticipantFeedbackWrapper>

					<S.InviteParticipantActions>
						<Button as={Link} to={`/${event.url}`} width="100%">Voltar para o evento</Button>
						{/* <ButtonOutline>Ver convidados</ButtonOutline> */}
					</S.InviteParticipantActions>
				</>
			)}
		</Container>
	);
}

export default InviteParticipantFlow;

