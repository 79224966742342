import styled from 'styled-components';
import Variables from 'styles/Variables';
import { Link } from 'react-scroll';

export const TicketsBoxAnchor = styled(Link)`
	position: fixed;
	left: 0;
	right: 0;
	bottom: -60px;
	height: 60px;
	padding: 20px 15px;
	text-align: center;
	z-index: 1;
	text-transform: uppercase;
	transition: .2s ease;
	font-weight: 600;
    letter-spacing: 0.5px;
	box-shadow: 0 0 15px 5px rgba(80, 80, 80, 0.15);
	color: ${Variables.colors.light};
	background: ${Variables.backgrounds.main};
`;
