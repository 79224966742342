import { Reducer } from 'redux';
import { TicketsState, TicketsTypes } from './types';

const INITIAL_STATE: TicketsState = {
	activeTickets: [],
	closedTickets: [],
	activeLoading: false,
	activeError: false,
	closedLoading: false,
	closedError: false,
	hasMoreActive: false,
	hasMoreClosed: false,
};

const reducer: Reducer<TicketsState> = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TicketsTypes.GET_TICKETS:
			return {
				...state,
				activeLoading: true,
				activeError: false,
				closedLoading: true,
				closedError: false,
			}

		case TicketsTypes.GET_TICKETS_ACTIVE_SUCCESS:
			return {
				...state,
				activeLoading: false,
				hasMoreActive: !action.payload.data.last,
				activeTickets: action.payload.data.content,
			};

		case TicketsTypes.GET_TICKETS_ACTIVE_FAILURE:
			return {
				...state,
				activeLoading: false,
				activeError: true,
			};

		case TicketsTypes.GET_TICKETS_CLOSED_SUCCESS:
			return {
				...state,
				closedLoading: false,
				hasMoreClosed: !action.payload.data.last,
				closedTickets: action.payload.data.content,
			};

		case TicketsTypes.GET_TICKETS_CLOSED_FAILURE:
			return {
				...state,
				closedLoading: false,
				closedError: true,
			};

		case TicketsTypes.DOWNLOAD_ALL_TICKETS:
			return {
				...state,
			};

		case TicketsTypes.DOWNLOAD_UNIQUE_TICKET:
			return {
				...state,
			};

		default:
			return state;
	}
};

export default reducer;
