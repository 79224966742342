import styled from 'styled-components';
import Variables from 'styles/Variables';
import { StyleProps } from './types';

export const TicketWrapper = styled.div`
	background: ${Variables.backgrounds.light};
	border-radius: 0.625rem;
	box-shadow: ${Variables.boxShadows.level2};
	overflow: hidden;
`;

export const TicketEventImage = styled.div`
	background-image: url(${({ thumbnail }: StyleProps) => thumbnail || `${process.env.REACT_APP_BUCKET_URL}/images/banner-fallback.jpg`});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 150px;
`;

export const TicketEventName = styled.div`
	font-weight: 600;
    padding: 1rem;
`;

export const TicketInfoWrapper = styled.div`
	align-items: center;
	display: flex;
	padding: 0 1rem 1rem;
`;

export const Text = styled.span`
	font-size: 0.8rem;
	padding-left: 0.625rem;
`;

export const TextBold = styled.span`
	font-size: 0.8rem;
	font-weight: 600;
	padding-left: 0.625rem;
`;

export const Small = styled.span`
	font-size: 0.65rem;
`;

export const TicketActionWrapper = styled.div`
	align-items: center;
	border-top: 1px solid #e4e4e4;
	display: flex;
	margin: 0 1rem;
`;

export const TicketAction = styled.button`
	align-items: center;
	border: none;
	color: ${Variables.colors.main};
	cursor: pointer;
	display: flex;
	font: inherit;
	margin: 1rem 0;
	padding: 0;
`;

export const StatusBadge = styled.div`
    background: gray;
	border-radius: 0.75rem;
	color: #fff;
    display: inline-block;
    font-size: 0.75rem;
	margin: 0.75rem 0;
	margin-left: auto;
    padding: 0.375rem 0.75rem;

	&[data-status=APPROVED] {
		background-color: ${Variables.colors.success};
	}

	&[data-status=PENDING] {
		background-color: ${Variables.colors.warning};
	}

	&[data-status=CANCELED] {
		background-color: ${Variables.colors.danger};
	}
`;
