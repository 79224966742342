import React from 'react';
import { isMobile } from 'react-device-detect';

import { Container } from 'styles';
import { SectionTitle } from 'components/standalone';
import * as S from './styles';

const Social = () => (
	<>
		<Container>
			<SectionTitle
				text="Fique por dentro de tudo que acontece aqui"
				marginBottom={35}
				marginTop={35}
				align="center"
				size={isMobile ? 20 : 30}
			/>
		</Container>
		<S.SocialWrapper>
			<S.SocialItem href={process.env.REACT_APP_SOCIAL_INSTAGRAM} target="_blank">
				<S.SocialItemImage image={`${process.env.PUBLIC_URL}/icons/icon-instagram.svg`} />
			</S.SocialItem>
			<S.SocialItem href={process.env.REACT_APP_SOCIAL_FACEBOOK} target="_blank">
				<S.SocialItemImage image={`${process.env.PUBLIC_URL}/icons/icon-facebook.svg`} />
			</S.SocialItem>
			<S.SocialItem href={process.env.REACT_APP_SOCIAL_TWITTER} target="_blank">
				<S.SocialItemImage image={`${process.env.PUBLIC_URL}/icons/icon-twitter.svg`} />
			</S.SocialItem>
		</S.SocialWrapper>
	</>
);

export default Social;
