import styled from 'styled-components';

import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';

export const Root = styled.div`
  background-color: #fff;
  padding: 25px 40px;
  border-radius: 20px;
  margin: 30px 0;

  ${breakAt(BreakpointsSizes.md)} {
    padding: 25px 100px;
  }
`;
