import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { isMobile } from 'react-device-detect';
import SwiperCore, { Autoplay, Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Event as ioEvent } from 'store/ducks/organization/types';
import * as S from './styles';
import OrganizationEventBanner from './OrganizationEventBanner';
import { SwiperContainer } from 'styles/Swiper';

SwiperCore.use([Autoplay, Navigation]);

const settings = {
	loop: true,
	centeredSlides: true,
	breakpoints: {
		992: { slidesPerView: 1.15 },
		1200: { slidesPerView: 1.25 },
		1400: { slidesPerView: 1.40 },
		1650: { slidesPerView: 1.60 }
	},
	onInit: () => {
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 1000);
	},
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
	},
	navigation: true
}

const OrganizationCarousel = ({ events, loading, imageCover }: any) => (
	<>
		{loading && (<Skeleton height={isMobile ? 280 : 380} />)}
		{!loading && events.length === 0 && (<S.OrganizationBanner imageCover={imageCover} />)}
		{!loading && (
			<S.OrganizationCarouselWrapper>
				<SwiperContainer>
					<Swiper {...settings}>
					{events && (
							events.map((event: ioEvent) => (
								<SwiperSlide>
									<OrganizationEventBanner key={event.url} event={event} />
								</SwiperSlide>
							))
						)}
					</Swiper>
				</SwiperContainer>
			</S.OrganizationCarouselWrapper>
		)}
	</>
);

export default OrganizationCarousel;
