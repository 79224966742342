export const BreakpointsSizes = {
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	exl: 1400,
	exxl: 1650,
};

export const breakAt = (size: number, onlyMobile = false) => {
	return `@media (${onlyMobile ? 'max' : 'min'}-width: ${size}px)`;
};
