import styled from 'styled-components';
import Variables from 'styles/Variables';
import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';

export const CallToActionWrapper = styled.div`
	background-color: ${Variables.backgrounds.main};
	color: ${Variables.colors.light};
	padding: 25px;
	margin-top: 50px;

	${breakAt(BreakpointsSizes.md)} {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

export const CallToActionText = styled.div`
	max-width: 400px;
	margin: 0 auto;
	text-align: center;

	${breakAt(BreakpointsSizes.md)} {
		font-size: 1.25rem;
		margin: 10px 15px;
	}
`;

export const CallToActionLink = styled.a`
	color: ${Variables.colors.main};
	background-color: ${Variables.backgrounds.light};
	text-transform: capitalize;
	padding: 10px;
	border-radius: 10px;
	font-size: 1rem;
	margin: 20px auto 0;
	display: block;
	transition: .2s ease;
	text-align: center;

	&:hover{
		filter: brightness(85%);
	}

	${breakAt(BreakpointsSizes.md)} {
		margin: 10px 15px;
		text-transform: uppercase;
		padding: 15px;
	}
`;
