import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';

import Variables from 'styles/Variables';
import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';
import { StyleProps } from './types';

export const NavBarWrapper = styled.nav`
	background-color: ${Variables.backgrounds.light};
	box-shadow: ${Variables.boxShadows.level1};
	height: 70px;
	width: 100%;
	z-index: 9999;
`;

export const NavBarContent = styled.div`
	height: 50px;
	margin: 10px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const NavLogo = styled(Link)`
	display: block;
	height: 50px;
	min-width: 120px;
	background-image: url(${process.env.REACT_APP_BUCKET_URL}/brand/logo-full-color.svg);
	background-size: contain;
	background-position: left center;
	background-repeat: no-repeat;

	${breakAt(BreakpointsSizes.md)} {
		min-width: 140px;
	}

	${breakAt(BreakpointsSizes.lg)} {
		min-width: 170px;
	}
`;

export const NavLogoExternal = styled.a`
	display: block;
	height: 50px;
	min-width: 120px;
	background-image: url(${process.env.REACT_APP_BUCKET_URL}/brand/logo-full-color.svg);
	background-size: contain;
	background-position: left center;
	background-repeat: no-repeat;

	${breakAt(BreakpointsSizes.md)} {
		min-width: 140px;
	}

	${breakAt(BreakpointsSizes.lg)} {
		min-width: 170px;
	}
`;

export const NavBarLinks = styled.ul`
	display: none;

	${breakAt(BreakpointsSizes.lg)} {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex: 1;
		height: 50px;
	}
`;

export const Title = styled.p`
	font-weight: 600;
	font-size: 1.1rem;
	text-align: center;
	margin-bottom: 15px;
`;

export const Subtitle = styled.p`
	font-size: 0.9rem;
	text-align: center;
	margin-bottom: 25px;
`;

// ---------- BUTTON and LINK
export const NavBarLinkButton = styled.a`
	display: block;
	color: ${Variables.colors.light};
	background: ${Variables.colors.main};
	padding: 10px;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
	transition: .2s ease;
	margin: 0 15px;
	text-align: center;
	cursor: pointer;

	&:hover{
		filter: brightness(85%);
	}

	${breakAt(BreakpointsSizes.lg, true)} {
		margin: 15px auto;
		max-width: 220px;
	}
`;

export const NavBarLink = styled(NavBarLinkButton)`
	color: ${Variables.colors.main};
	background: transparent;
	border: none;
`;

export const NavBarInternalLink = styled(Link)`
	display: block;
	color: ${Variables.colors.light};
	background: ${Variables.colors.main};
	padding: 10px;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
	transition: .2s ease;
	margin: 0 15px;
	text-align: center;
	cursor: pointer;

	&:hover{
		filter: brightness(85%);
	}

	${breakAt(BreakpointsSizes.lg, true)} {
		margin: 15px auto;
		max-width: 220px;
	}
`;

export const NavBarInternalLinkButton = styled(NavBarInternalLink)`
	background-color: initial;
	color: ${Variables.colors.main};
`;

// ---------- USER
export const UserWrapper = styled.div`
	position: relative;
	margin-right: 25px;
`;

export const UserImage = styled.div`
    height: 50px;
    width: 50px;
	margin-left: 1rem;
	border-radius: 50%;
	cursor: pointer;
	position: relative;
	background-image: url(${({ image }: StyleProps) => image ? image : `${process.env.PUBLIC_URL}/images/fallback-user.svg`});

	${breakAt(BreakpointsSizes.lg)} {
		&:after{
			content: '';
			position: absolute;
			height: 10px;
			width: 10px;
			border-bottom: 2px solid ${Variables.colors.dark};
			border-right: 2px solid ${Variables.colors.dark};
			transform: rotate(45deg);
			top: 50%;
			margin-top: -5px;
			right: -25px;
			border-radius: 2px;
		}
	}
`;

export const UserOptionList = styled.div`
	display: ${({ isMenuOpen }: StyleProps) => isMenuOpen ? 'block' : 'none'};
	background: ${Variables.backgrounds.light};
    position: absolute;
    top: calc(100% + 25px);
    right: -25px;
    width: 180px;
    height: auto;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: ${Variables.boxShadows.level1};
`;

export const UserOption = styled(Link)`
	display: block;
	padding: 10px 15px;
	transition: .2s ease;

	&:hover{
		color:  ${Variables.colors.light};
		background-color: ${Variables.backgrounds.main};
	}
`;

export const UserOptionExternal = styled.a`
	display: block;
	padding: 10px 15px;
	transition: .2s ease;

	&:hover{
		color:  ${Variables.colors.light};
		background-color: ${Variables.backgrounds.main};
	}
`;

// ---------- NAV MOBILE
export const NavBarMobileWrapper = styled.div`
	display: grid;
	position: fixed;
	left: 0;
	right: 0;
	height: 60px;
	z-index: 9999;
	background-color: ${Variables.backgrounds.light};
	border-top: 1px solid #ececec;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 15px;
	transition: .2s ease;
	${({ display }: StyleProps) => display ? 'bottom: 0px' : 'bottom: -60px;'};

	${breakAt(BreakpointsSizes.lg)} {
		display: none;
	}
`;

export const NavMobileItem = styled(NavLink)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&.active{
		color: ${Variables.colors.main} !important;
	}
`;

export const MobileItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const NavMobileItemIcon = styled.img`
	height: 30px;
	width: 50px;
`;

export const NavMobileItemText = styled.div`
	font-size: 0.65rem;
	margin-top: 5px;
`;


// ---------- PROFILE MOBILE
export const ProfileWrapper = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	z-index: 5000;
	padding: 75px 25px;
	background-color: ${Variables.backgrounds.light};
	overflow-y: auto;
	display: ${({ isMenuMobileOpen }: StyleProps) => isMenuMobileOpen ? 'block' : 'none'};

`;

export const UserInfoWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 15px 0;
	margin-bottom: 15px;
`;

export const UserInfo = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 20px;
`;

export const UserName = styled.span`
	font-size: 1.1rem;
	font-weight: 600;
`;

export const UserEmail = styled.span`
	font-size: 0.8rem;
	color: #848c9b;
`;

// ---------- LOGIN MOBILE
export const LoginImage = styled.div`
	width: 220px;
    height: 180px;
	margin: 15px auto;
	background-image: url(${process.env.PUBLIC_URL}/images/image-login.svg);
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
`;

// ---------- MODAL
export const ModalImage = styled.div`
	max-width: 300px;
	width: 100%;
	height: 200px;
	margin: 20px auto;
	background-image: url(${process.env.PUBLIC_URL}/images/image-login-error.svg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
`;

export const ModalActions = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;


