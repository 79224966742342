import styled from 'styled-components';

import { BreakpointsSizes, breakAt } from './Breakpoint';

interface containerProps {
	display?: string;
	padding?: number;
	marginTop?: number;
	marginBottom?: number;
}

const Container = styled.div`
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	margin-top: ${(props: containerProps) => `${props.marginTop || 0}px`};
	margin-bottom: ${(props: containerProps) => `${props.marginBottom || 0}px`};
	padding-left: ${(props: containerProps) => `${props.padding || 10}px`};
	padding-right: ${(props: containerProps) => `${props.padding || 10}px`};
	display: ${(props: containerProps) => props.display || 'block'};

	${breakAt(BreakpointsSizes.md)} {
		width: 90%;
		max-width: 900px;
	}

	${breakAt(BreakpointsSizes.lg)} {
		width: 90%;
		max-width: 1200px;
	}

	${breakAt(BreakpointsSizes.xl)} {
		width: 100%;
	}
`;

export default Container;
