import styled from 'styled-components';

export const CloseButtonWrapper = styled.div`
	position: relative;
`;

export const CloseButton = styled.div`
    align-items: center;
	background: #FFFFFF;
    border-radius: 1rem;
	cursor: pointer;
    display: flex;
	height: 2rem;
    justify-content: center;
    line-height: 1;
	position: absolute;
	right: -1rem;
	top: -4rem;
    width: 2rem;

	@media (min-width: 768px) {
		right: -1.5rem;
		top: -5rem;
	}
`;

export const SelectLoginText = styled.p`
	font-weight: 700;
	margin-bottom: 2.25rem;
`;

export const ButtonsSeparator = styled.div`
	align-items: center;
	color: #BDBDBD;
	display: flex;
	justify-content: center;
	margin: 2rem 0;
	position: relative;

	&:before,
	&:after {
		background-color: #BDBDBD;
		content: '';
		height: 1px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 40%;
	}

	&:before { left: 0; }
	&:after { right: 0; }
`;
