import { combineReducers } from 'redux';
import nav from './nav';
import home from './home';
import search from './search';
import event from './event';
import organization from './organization';
import tickets from './tickets';
import checkout from './checkout';
import categories from './categories';

export default combineReducers({
	nav,
	home,
	search,
	event,
	organization,
	tickets,
	checkout,
	categories,
});
