import React from 'react';
import { Container } from 'styles';

import * as S from './styles';

const TicketsHeader = () => (
	<S.TicketsHeaderWrapper>
		<Container>
			<S.TicketsHeaderTitle>Meus ingressos</S.TicketsHeaderTitle>
		</Container>
	</S.TicketsHeaderWrapper>
);

export default TicketsHeader;
