import styled, { keyframes } from 'styled-components';

import Variables from 'styles/Variables';
import { ButtonProps } from './types';

export const ButtonStyle = styled.button`
	color: ${Variables.colors.light};
	background: ${Variables.colors.main};
	padding: 10px;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
	transition: .2s ease;
	margin-right: ${({ marginRight }: ButtonProps) => `${marginRight || 0}px`};
	margin-left: ${({ marginLeft }: ButtonProps) => `${marginLeft || 0}px`};
	${({ width }: ButtonProps) => (width ? `width: ${width}%` : '')};

	${({ loading }: ButtonProps) => loading
		? `
			pointer-events: none;
			&:before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background:${Variables.colors.main};
			}
			&:after{
				content: '';
				height: 20px;
				width: 20px;
				border: 4px solid #fff;
				border-top-color: ${Variables.colors.second};
				border-radius: 50%;
				position: absolute;
				top: 50%;
				left: 50%;
				margin-top: -14px;
				margin-left: -14px;
			}
		`
		: ``
	}

	&:after{
		animation: ${rotationBuilder()} 1.5s linear infinite forwards;
	}

	&:hover{
		filter: brightness(85%);
	}
`;

export const ButtonLinkStyle = styled(ButtonStyle)`
	color: ${Variables.colors.main};
	background: transparent;
	padding: 4px;
	border: none;
`;


function rotationBuilder() {
	const rotation = keyframes`
		0% { transform: rotate(0deg) }
		100% { transform: rotate(360deg) }
	`;
	return rotation;
}
