import styled from 'styled-components';
import Variables from 'styles/Variables';
import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';

export const Root = styled.div`
	background: ${Variables.colors.main};
	padding: 20px;

	${breakAt(BreakpointsSizes.md)} {
		width: 90%;
		max-width: 980px;
		height: 120px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		border-bottom-left-radius: 30px;
		border-bottom-right-radius: 30px;
	}

	${breakAt(BreakpointsSizes.exl)} {
		height: 150px;
		max-width: 1180px;
	}
`;

export const InputWrapper = styled.div`
	background: ${Variables.backgrounds.light};
	display: flex;
	align-items: center;
	padding-right: 10px;
	border-radius: 5px;
	overflow: hidden;
	width: 100%;
	max-width: 670px;
`;

export const Input = styled.input`
	background: ${Variables.backgrounds.light};
	outline-color: transparent;
	border: 0;
	padding: 10px;
	flex: 1;
	height: 40px;
	font-size: 1rem;

	${breakAt(BreakpointsSizes.md)} {
		height: 45px;
	}

	${breakAt(BreakpointsSizes.exl)} {
		height: 50px;
	}

	&::placeholder{
		color: ${Variables.colors.main};
	}
`;

export const SearchButton = styled.button`
	padding: 0;
    border: none;
    outline: none;
`;
