import {
	CheckoutTypes,
} from './types';

export const loadSuccess = () => ({
	type: CheckoutTypes.LOAD_SUCCESS,
});

export const loadFailure = () => ({
	type: CheckoutTypes.LOAD_FAILURE,
});
