import { Reducer } from 'redux';
import { NavState, NavTypes } from './types';

const INITIAL_STATE: NavState = {
	isShowMobileOptions: true,
	hasFixedFooterContent: false,
};

const reducer: Reducer<NavState> = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case NavTypes.SHOW_MOBILE_OPTIONS:
			return {
				...state,
				isShowMobileOptions: true,
			};

		case NavTypes.HIDE_MOBILE_OPTIONS:
			return {
				...state,
				isShowMobileOptions: false,
			};

		case NavTypes.SHOW_FIXED_FOOTER_CONTENT:
			return {
				...state,
				hasFixedFooterContent: true,
			};

		case NavTypes.HIDE_FIXED_FOOTER_CONTENT:
			return {
				...state,
				hasFixedFooterContent: false,
			};

		default:
			return state;
	}
};

export default reducer;
