import React from 'react';

import { ButtonProps } from './types';
import * as S from './styles';

export const Button = ({
	width,
	marginLeft,
	marginRight,
	children,
	loading,
	handleClick
}: ButtonProps) => (
	<S.ButtonStyle
		width={width}
		marginLeft={marginLeft}
		marginRight={marginRight}
		loading={loading}
		onClick={handleClick}>
		{children}
	</S.ButtonStyle>
);

export const ButtonLink = ({
	width,
	marginLeft,
	marginRight,
	children,
	handleClick
}: ButtonProps) => (
	<S.ButtonLinkStyle
		width={width}
		marginLeft={marginLeft}
		marginRight={marginRight}
		onClick={handleClick}
	>
		{children}
	</S.ButtonLinkStyle>
);
