import React from 'react';

import * as S from './styles';
import { EventCardListProps } from './types';

const EventCardList = ({ children, marginBottom, lineItems, gap }: EventCardListProps) => (
	<S.Root lineItems={lineItems} marginBottom={marginBottom} gap={gap}>{children}</S.Root>
);

export default EventCardList;
