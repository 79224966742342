import React from 'react';

import { StyleProps } from './types';
import * as S from './styles';

export const LinkButton = ({
	to,
	width,
 	ml,
	mr,
	mt,
   	mb,
	children,
}: StyleProps) => (
	<S.LinkStyle to={to} width={width} ml={ml} mr={mr} mt={mt} mb={mb}>
		{children}
	</S.LinkStyle>
);
