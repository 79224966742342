import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';

import Variables from './Variables';

type InputWrapper = {
	hasError?: boolean;
}
export const InputWrapper = styled.div`
	margin-bottom: 1rem;

	${({ hasError }: InputWrapper) => hasError && `
		${Input} { border-color: tomato; outline-color: tomato; }
		${InputErrorMessage} { display: block !important; }
	`}
`;

export const Label = styled.label`
	color: #333333;
	display: block;
	font-size: 0.875rem;
	margin-bottom: 0.5rem;

	&[data-required]::after {
		color: tomato;
		content: '*';
	}
`;

export const Input = styled.input`
	border: 1px solid #E0E0E0;
	border-radius: 0.5rem;
	color: #5F5F5F;
	display: block;
	font-size: 1rem;
	padding: 0.75rem;
	width: 100%;

	&:disabled {
		background-color: #E0E0E0;
		cursor: not-allowed;
	}
`;

export const MaskInput = styled(InputMask)`
	border: 1px solid #E0E0E0;
	border-radius: 0.5rem;
	color: #5F5F5F;
	display: block;
	font-size: 1rem;
	padding: 0.75rem;
	width: 100%;

	&:disabled {
		background-color: #E0E0E0;
		cursor: not-allowed;
	}
`;

export const InputErrorMessage = styled.span`
	color: tomato;
	display: none;
	font-size: 0.75rem;
`;

type Button = {
	width?: string;
	margin?: string;
	loading?: boolean;
}
export const Button = styled.button`
	align-items: center;
	background-color: ${Variables.colors.main};
	border: 2px solid ${Variables.colors.main};
	border-radius: 0.5rem;
	color: ${Variables.colors.light};
	display: flex;
	font-size: 0.875rem;
    font-weight: 600;
	justify-content: center;
    letter-spacing: 0.5px;
	padding: 0.75rem;
	position: relative;
	text-align: center;
	transition: .2s ease;
	${({ margin }: Button) => (`margin: ${margin || 'initial'};`)}
	${({ width }: Button) => (`width: ${width || 'initial'};`)}

	&:not(:disabled):hover {
		filter: brightness(0.85);
	}

	&:disabled {
		filter: grayscale(1) opacity(.5);
		cursor: not-allowed;
	}

	${({ loading }: Button) => loading && (`
		user-select: none;
		pointer-events: none;
		color: transparent !important;

		&:after {
			border: 2px solid transparent;
			border-top-color: #fff;
			border-radius: 50%;
			content: '';
			display: inline-block;
			height: 0.75rem;
			position: absolute;
			width: 0.75rem;
		}
	`)}

	&:after {
		animation: ${rotationBuilder()} 1.5s linear infinite forwards;
	}

`;

export const ButtonOutline = styled(Button)`
	background-color: transparent;
	color: ${Variables.colors.main};

	&:not(:disabled):hover {
		background-color: ${Variables.colors.main};
		color: ${Variables.colors.light};
		filter: brightness(0.85);
	}

	&:after {
		border-top-color: ${Variables.colors.main};
	}
`;

export const ButtonLink = styled(Link)`
	color: ${Variables.colors.main};
	display: flex;
	font-size: 0.875rem;
    font-weight: 600;
	justify-content: center;
    letter-spacing: 0.5px;
	padding: 0.75rem;
	position: relative;
	text-align: center;
	transition: .2s ease;
	${({ margin }: Button) => (`margin: ${margin || 'initial'};`)}
	${({ width }: Button) => (`width: ${width || 'initial'};`)}

	&:not(:disabled):hover {
		filter: brightness(0.85);
		text-decoration: underline;
	}
`;

function rotationBuilder() {
	const rotation = keyframes`
		0% { transform: rotate(0deg) }
		100% { transform: rotate(360deg) }
	`;
	return rotation;
}

