import React from 'react';
import { isMobile } from 'react-device-detect';

import { SearchBar, CategoryList, Social, About, CallToAction, HomeLoginWarning } from 'components/home';
import { LinkButton, SectionTitle, Seo } from 'components/standalone';
import { Container } from 'styles';

import HomeMainEvents from './HomeMainEvents';
import HomeFeaturedEvents from './HomeFeaturedEvents';
import HomeUpcomingEvents from './HomeUpcomingEvents'

const HomePage = () => {

	return (
		<>
			<Seo />
			<HomeLoginWarning />
			<SearchBar />
			<Container>
				<SectionTitle
					text="Os melhores eventos você encontra aqui!"
					marginBottom={isMobile ? 20 : 30}
					marginTop={isMobile ? 20 : 30}
					weight={isMobile ? 400 : 500}
					shadow={!isMobile}
					size={isMobile ? 20 : 30}
					align="center"
				/>
			</Container>

			<CategoryList inline={false} />

			<HomeMainEvents />

			<HomeFeaturedEvents />

			<HomeUpcomingEvents />

			<CategoryList inline grayscale />

			{/* <Container>
				<EventCardBox events={externalEvents} />
			</Container> */}

			<LinkButton
				to={{pathname: "/eventos", state: {name: null, categoryId: null}}}
				width="230px"
				ml="auto"
				mr="auto"
				mt="50px"
			>Mais eventos</LinkButton>

			<Social />

			<About />

			<CallToAction />
		</>
	);
};

export default HomePage;
