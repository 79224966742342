import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';

import * as S from './styles';
import Category from '../../standalone/Category/Category';
import { CategoryListProps } from './types';
import { CategoriesState, Category as CategoryType } from 'store/ducks/categories/types';
import { getCategories } from 'store/ducks/categories/actions';

interface StateProps {
	categories: CategoriesState,
}

const CategoryList = ({ inline, grayscale }: CategoryListProps) => {
	const dispatch = useDispatch();
	const { categoryList, loading, error } = useSelector((state: StateProps) => state.categories);

	useEffect(() => {
		dispatch(getCategories());
	}, [])

	return (
		<S.CategoryListWrapper inline={inline}>
			<S.CategoryList inline={inline}>
				{!loading && !error && (
					<>
						{categoryList.map((category: CategoryType, index: number) => {
							if (index < 8) {
								return (
									<Link key={category.name} to={{pathname: "/eventos", state: {categoryId: category.id}}}>
										<Category grayscale={grayscale || false} text={category.name} background={category.imgUrl} />
									</Link>
								)
							}
						})}
						{isBrowser && (
							<Link to="/eventos">
								<Category grayscale={false} text="Mais eventos" background={`${process.env.PUBLIC_URL}/categories/more.png`} />
							</Link>
						)}
					</>
				)}
			</S.CategoryList>
		</S.CategoryListWrapper>
	);
}

export default CategoryList;
