import { call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getTicketsActiveSuccess, getTicketsActiveFailure, getTicketsClosedSuccess, getTicketsClosedFailure } from './actions';
import { eventApi } from '../../../services/Api';

import { downloadFile, createFileUrl } from 'utils/appUtils';

export function* getTicketsSaga(action: any) {
	const data = { eventStatuses: action.payload.type === 'ACTIVE' ? ['PUBLISHED', 'UNPUBLISHED'] : ['FINISHED'] };
	const params = { size: action.payload.size + 20, sort: action.payload.sort };

	try {
		const response = yield call(eventApi.post, `/user/my-tickets`, data, { params });
		if (action.payload.type === 'ACTIVE') {
			yield put(getTicketsActiveSuccess(response.data));
		} else {
			yield put(getTicketsClosedSuccess(response.data));
		}
	} catch (err) {
		if (action.payload.type === 'ACTIVE') {
			yield put(getTicketsActiveFailure());
		} else {
			yield put(getTicketsClosedFailure());
		}
	}
}

export function* downloadAllTicketsSaga(action: any) {
	try {
		const response = yield call(eventApi.get, `/event/${action.payload.eventId}/sales/ticket/export-pdf?purchaseId=${action.payload.purchaseId}`, { responseType: 'blob' });
		downloadFile(createFileUrl(response.data), 'ingressos');
	} catch (err) {
		toast('Houve um erro ao baixar seus ingressos. Por favor, tente novamente.', { type: toast.TYPE.ERROR });
	}
}

export function* downloadUniqueTicketSaga(action: any) {
	try {
		const response = yield call(eventApi.get, `/user/my-tickets/export-pdf?attendeeCode=${action.payload.attendeeCode}`, { responseType: 'blob' });
		downloadFile(createFileUrl(response.data), 'ingresso');
	} catch (err) {
		toast('Houve um erro ao baixar seu ingresso. Por favor, tente novamente', { type: toast.TYPE.ERROR });
	}
}
