import React from 'react';
import * as S from './styles';

interface TagsProps {
	tags: string[]
}

const Tags = ({ tags }: TagsProps ) => (
	<>
		{tags.length !== 0 &&
			(<S.TagsWrapper>
				<S.TagsTitle>Tags</S.TagsTitle>

				<S.TagList>
					{tags.map((tag) => <S.Tag key={tag}>{tag}</S.Tag>)}
				</S.TagList>
			</S.TagsWrapper>)
		}
	</>
);

export default Tags;
