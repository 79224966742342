import React, { createContext, useState } from 'react';

type AnalyticsType = {
	active: boolean,
    code: string,
    name: string,
}

type PropsAnalyticsContext = {
    state: AnalyticsType,
    setState: React.Dispatch<React.SetStateAction<AnalyticsType>>,
}

const DEFAULT_VALUE = {
    state: {
		active: false,
        code: '',
		name: '',
    },
    setState: () => {},
}

const AnalyticsContext = createContext<PropsAnalyticsContext>(DEFAULT_VALUE);

const AnalyticsContextProvider: React.FC = ({ children }) => {
    const [state, setState] = useState(DEFAULT_VALUE.state);
    return (
        <AnalyticsContext.Provider
            value={{
                state,
                setState,
            }}
        >{ children }</AnalyticsContext.Provider>
    )
}

export { AnalyticsContextProvider };
export default AnalyticsContext;
