import React from 'react';
import { Calendar, Clock, MapPin } from 'react-feather';
import { format } from 'date-fns';

import { Event } from 'store/ducks/home/types';
import { dateGlobalPattern } from 'utils/dateUtils';
import { IconWrapper } from 'styles';
import * as S from './styles';

const iconSize = 15;

const Banner = ({ banner, name, startDate, locationType, url, featuredBanner, locationName, seatsMapActived }: Event) => (
	<S.Root to={`/${url}`} banner={featuredBanner ? featuredBanner : banner}>
		<S.EventTitle>{name}</S.EventTitle>
		<S.InfoWrapper>
			<S.EventDateTime>
				<IconWrapper height={iconSize} width={iconSize}>
					<Calendar size={iconSize} color="#fff" />
				</IconWrapper>
				<S.Text>{format(new Date(dateGlobalPattern(startDate)), 'dd/MM/yyyy')}</S.Text>

				<IconWrapper height={iconSize} width={iconSize}>
					<Clock size={iconSize} color="#fff" />
				</IconWrapper>
				<S.Text>{format(new Date(dateGlobalPattern(startDate)), 'HH:mm')}</S.Text>
			</S.EventDateTime>
			<S.EventAddress>
				<IconWrapper height={iconSize} width={iconSize}>
					<MapPin size={iconSize} color="#fff" />
				</IconWrapper>
				<S.Text>{locationType === 'PHYSICAL' ? locationName : 'Virtual'}</S.Text>
			</S.EventAddress>
		</S.InfoWrapper>
	</S.Root>
);

export default Banner;
