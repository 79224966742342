import React from 'react';

import * as S from './styles';

const PageNotFoundMessage = () => (
	<>
		<S.Wrapper>
			<S.Image/>
			<S.ContentWrapper>
				<S.Title>A página que você está procurando não foi encontrada</S.Title>
				<S.Message>Infelizmente, ela pode ter mudado de endereço ou não está mais disponível.</S.Message>

				<S.Message>Enquanto tentamos descobrir o que aconteceu, você pode ver outros eventos no nosso site</S.Message>

				<S.NotFoundLink to="/">Ver outros eventos</S.NotFoundLink>
			</S.ContentWrapper>
		</S.Wrapper>
	</>
);

export default PageNotFoundMessage;
