import React from 'react';

import * as S from './styles';

const AccreditationMessage = ({eventName, eventUrl}: any) => {
	const link = `${process.env.REACT_APP_URL}/${eventUrl}/credenciar`;

	return (
		<S.AccreditationMessageWrapper>
			<S.AccreditationTitle>Área de credenciamento</S.AccreditationTitle>
			<S.AccreditationMessage>Acesse o link e realize seu credenciamento para o evento {eventName}</S.AccreditationMessage>
			<S.AccreditationLink to={`/${eventUrl}/credenciar`}>{link}</S.AccreditationLink>
		</S.AccreditationMessageWrapper>
	);
}

export default AccreditationMessage;
