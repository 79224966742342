import React from 'react';
import { isMobile } from 'react-device-detect';

import * as S from './styles';

const CallToAction = () => (
	<S.CallToActionWrapper>
		<S.CallToActionText>Pronto para melhorar a experiência de seu público e aumentar a sua receita?</S.CallToActionText>
		<S.CallToActionLink href={`${process.env.REACT_APP_PRODUCER_URL}/evento/novo`}>{isMobile ? 'Criar evento' : 'Cadastre seu evento gratuitamente'}</S.CallToActionLink>
	</S.CallToActionWrapper>
);

export default CallToAction;
