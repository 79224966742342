import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SearchState } from 'store/ducks/search/types';
import { getSearch } from 'store/ducks/search/actions';

import { Container } from 'styles';
import { EventCardList } from 'components/home';
import { EventCard, Seo } from 'components/standalone';
import SearchStatus from 'components/search/SearchStatus/SearchStatus';
import SearchMore from 'components/search/SearchMore/SearchMore';
import { Loading } from 'components/standalone';
import { SearchFilters } from 'components/search';

interface StateProps {
	search: SearchState,
}

const SearchPage = () => {
	const { state }: any = useLocation();
	const select = (state: StateProps) => state.search;
	const dispatch = useDispatch();
	const { data, loading, error, filters, currentPage, lastPage, quantityFound } = useSelector(select);

	useEffect(() => {
		const params = {page: currentPage};
		const filters = state && {name: state.name || null, category: state.categoryId || null};
		dispatch(getSearch(filters, params));
	}, [state]);

	return (
		<>
			<Seo title={`${process.env.REACT_APP_NAME} - Buscar eventos`} />
			<SearchFilters categoryId={state && state.categoryId} name={state && state.name} />
			{loading && <Loading />}

			{!loading && (
				<Container>
					<SearchStatus searchTerm={filters.name || ''} eventsFound={quantityFound} />

					<EventCardList lineItems={4} marginBottom={50}>
						{data.map(({
							name,
							banner,
							thumbnail,
							startDate,
							locationName,
							locationType,
							url,
							seatsMapActived,
							hasFacebookPixel,
						}: any) => (
							<EventCard
								key={url}
								name={name}
								banner={banner}
								thumbnail={thumbnail}
								startDate={startDate}
								locationName={locationName}
								locationType={locationType}
								url={url}
								seatsMapActived={seatsMapActived}
								short={false}
								main={false}
								hasFacebookPixel={hasFacebookPixel}
							/>
						))}
					</EventCardList>

					{!lastPage && <SearchMore />}
				</Container>
			)}
		</>
	);
}

export default SearchPage;
