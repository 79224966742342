import { Reducer } from 'redux';
import { SearchState, SearchTypes } from './types';

const INITIAL_STATE: SearchState = {
	data: [],
	quantityFound: 0,
	filters: {},
	lastPage: true,
	currentPage: 0,
	loading: false,
	error: false,
	loadingMore: false,
};

const reducer: Reducer<SearchState> = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SearchTypes.GET_SEARCH:
			return {
				...state,
				loading: true,
			}

		case SearchTypes.GET_SEARCH_SUCCESS:
			return {
				...state,
				loading: false,
				loadingMore: false,
				lastPage: action.payload.data.last,
				currentPage: action.payload.data.number,
				quantityFound: action.payload.data.totalElements,
				data: action.payload.data.content,
			};

		case SearchTypes.GET_SEARCH_FAILURE:
			return {
				...state,
				loading: false,
				error: true,
			};

		case SearchTypes.GET_MORE:
			return {
				...state,
				loadingMore: true,
			}

		case SearchTypes.GET_MORE_SUCCESS:
			return {
				...state,
				loading: false,
				loadingMore: false,
				lastPage: action.payload.data.last,
				currentPage: action.payload.data.number,
				quantityFound: action.payload.data.totalElements,
				data: [...state.data, ...action.payload.data.content],
			};

		case SearchTypes.SET_FILTERS:
			return {
				...state,
				filters: action.payload.filters
			}

		default:
			return state;
	}
};

export default reducer;
