import styled, { keyframes } from 'styled-components';
import Variables from 'styles/Variables';

export const TicketWrapper = styled.div`
	align-items: center;
	display: flex;
	margin-bottom: 0.5rem;
	padding: 1rem 0;
`;

export const TicketInfo = styled.div`
	flex-grow: 1;
	margin-right: 1rem;
`;

export const Attendee = styled.p`
	font-weight: 600;
	margin-bottom: 0.25rem;
`;

export const Code = styled.span`
	text-transform: uppercase;
`;

export const BoldText = styled.span`
	font-weight: 600;
`;

export const Text = styled.p`
	font-size: 0.875rem;
	margin-bottom: 0.125rem;
`;

export const Small = styled.p`
	font-size: 0.65rem;
`;

export const DownloadTicket = styled.button`
	color: ${Variables.colors.main};
	display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
`;

export const Downloading = styled.p`
	color: ${Variables.colors.main};
	font-size: 0.875rem;
	padding-right: 1.25rem;
	position: relative;

	&:after {
		animation: ${rotationBuilder()} 1.5s linear infinite forwards;
		border-radius: 50%;
		border: 3px solid transparent;
		border-top-color: ${Variables.colors.main};
		content: '';
		height: 0.625rem;
		margin-left: 0.375rem;
		position: absolute;
		width: 0.625rem;
	}
`;

function rotationBuilder() {
	const rotation = keyframes`
		0% { transform: rotate(0deg) }
		100% { transform: rotate(360deg) }
	`;
	return rotation;
}
