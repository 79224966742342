import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MetaTags from 'react-meta-tags';

import { SeoProps } from './types';

const Seo = ({ title, description, ogDescription, image, url, keywords }: SeoProps) => {

	const baseTitle = title || process.env.REACT_APP_NAME;
	const baseDescription = description || process.env.REACT_APP_DESCRIPTION;
	const metaImage = image || `${process.env.REACT_APP_BUCKET_URL}/brand/logo-square.png`;
	const metaDescription = ogDescription || process.env.REACT_APP_DESCRIPTION;
	const metaUrl = url || process.env.REACT_APP_URL;
	const baseKeywords = keywords?.join(' ') || process.env.REACT_APP_KEYWORDS;

	return (
		<MetaTags>
			<title>{baseTitle}</title>
			<meta name="description" content={baseDescription} />
			<meta name="keywords" content={baseKeywords} />
			<meta property="og:title" content={baseTitle} />
			<meta property="og:description" content={metaDescription} />
			<meta property="og:image" content={metaImage} />
			<meta property="og:image:secure_url" content={metaImage} />
			<meta property="og:image:width" content="200" />
			<meta property="og:image:height" content="200" />
			<meta property="og:url" content={metaUrl} />
			<meta property="og:type" content="website" />

			<meta property="twitter:title" content={baseTitle} />
			<meta property="twitter:description" content={metaDescription} />
			<meta property="twitter:image" content={metaImage} />
			<meta property="twitter:image:secure_url" content={metaImage} />
			<meta property="twitter:site" content={process.env.REACT_APP_SOCIAL_TWITTER_ID} />
			<meta property="twitter:creator" content={process.env.REACT_APP_SOCIAL_TWITTER_ID} />
		</MetaTags>
	);
};

export default Seo;
