import styled from 'styled-components';

import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';
import { StyleProps } from './types';

export const ContentWrapper = styled.div`
	grid-row: 2;
	margin: 25px 0;
`;

export const DescriptionWrapper = styled.div`
	position: relative;
	overflow: hidden;
	transition: max-height .3s ease;

	${({ fullDescription }: StyleProps) => ( `max-height: ${fullDescription ? 'initial' : '350px'}`)}
	${({ alwaysShow }: StyleProps) => (alwaysShow && 'max-height: initial !important;')}
`;

export const DescriptionTitle = styled.h2`
	font-size: 1.25rem;
	margin-bottom: 15px;
	font-weight: 600;

	${breakAt(BreakpointsSizes.lg)} {
		font-size: 1.5rem;
	}
`;

export const DescriptionContent = styled.div`
	margin-bottom: 50px;
	padding-bottom: 40px;
	max-width: calc(100vw - 20px);
	overflow-x: hidden;

	.ql-size-small{font-size: .75em;}
	.ql-size-large{font-size: 1.5em;}
	.ql-size-huge{font-size: 2.5em;}
	strong{font-weight: bold;}
	em{font-style: italic;}
	sub{vertical-align: sub; font-size: smaller;}
	sup{vertical-align: super; font-size: smaller;}
	blockquote{border-left: 4px solid #ccc; margin-bottom: 5px; margin-top: 5px; padding-left: 16px;}
	ol{list-style: decimal; padding-left: 1.5em;}
	ul{list-style: disc; padding-left: 1.5em;}

	.ql-direction-rtl{direction: rtl;}
	.ql-align-right{text-align: right;}
	.ql-align-center{text-align: center;}
	.ql-align-left{text-align: left;}
	.ql-align-justify{text-align: justify;}

	a{color: #06c; text-decoration: underline;}
	img{max-width: 100% !important;}
	iframe{width: 100%; max-width: 560px !important; height: 360px !important;}
`;

export const DescriptionToggle = styled.div`
    padding: 30px 30px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	cursor: pointer;

	${({ fullDescription }: StyleProps) => (fullDescription
		? 'background: #f2f2f2;'
		: 'background: linear-gradient(180deg, rgba(242, 242, 242, 0.5) 0%, #f2f2f2 30%);'
	)}

	${({ alwaysShow }: StyleProps) => (alwaysShow && 'display: none;')}
`;

export const DescriptionToggleText = styled.span`
	font-weight: bold;
`;
