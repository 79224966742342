import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Seo } from 'components/standalone';
import { TicketsHeader, TicketsContent } from 'components/tickets';
import { Loading } from 'components/standalone';
import { RenderIf } from 'components/layout';
import AuthService from 'services/AuthService';

const TicketsPage = () => {
	const [isAuth, setIsAuth] = useState(false);
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		if (AuthService.isAuthenticated()) {
			setIsAuth(true);
			return;
		}
		history.push({ pathname: '/entrar', state: {previousUrl: location.pathname} });
	}, []);

	return (
		<>
			<RenderIf condition={!isAuth}>
				<Loading />
			</RenderIf>

			<RenderIf condition={isAuth}>
				<>
					<Seo title={`${process.env.REACT_APP_NAME} - Meus ingressos`} />
					<TicketsHeader />
					<TicketsContent />
				</>
			</RenderIf>
		</>
	);
}

export default TicketsPage;
