import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const AccreditationMessageWrapper = styled.div`
	background-color: #F2F8FF;
    border-radius: 0.5rem;
    margin: 1rem 0;
    padding: 1rem;
`;

export const AccreditationTitle = styled.h4`
	font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
`;

export const AccreditationMessage = styled.p`
	font-size: 0.875rem;
	margin-bottom: 0.5rem;
`;

export const AccreditationLink = styled(Link)`
	word-break: break-all;
	color: #4598CF;
	font-size: 0.875rem;
	text-decoration: underline;
`;
