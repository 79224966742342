import styled from 'styled-components';
import { TabList, Tab } from 'react-tabs';
import Variables from 'styles/Variables';
import { StyleProps } from './types';

export const TicketsList = styled.div`
	display: grid;
	grid-gap: 25px;
	grid-template-columns: repeat(auto-fit, minmax(100px, 350px));
	justify-content: center;
	margin-bottom: 20px;
`;


export const CustomTabList = styled(TabList)`
	display: flex;
	border-bottom: 1px solid lightgray;
	margin-bottom: 20px;
`;

export const CustomTab = styled(Tab)`
	padding: 5px 15px;
	cursor: pointer;
	font-size: 1.15rem;

	&.react-tabs__tab--selected{
		background: none;
		border-bottom: 6px solid ${Variables.colors.main};
		margin-bottom: -3px;
	}
`;

// ---------- FALLBACK
export const TicketsFallback = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const TicketsFallbackImage = styled.div`
	width: 220px;
    height: 180px;
	margin: 30px auto;
	background-image: url(${({ error }: StyleProps) =>  error ? `${process.env.PUBLIC_URL}/images/image-error.svg` : `${process.env.PUBLIC_URL}/images/image-no-tickets.svg`});
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
`;

export const TicketsFallbackTitle = styled.p`
	font-weight: 600;
	font-size: 1.1rem;
	text-align: center;
	margin-bottom: 15px;
`;

export const TicketsFallbackSubtitle = styled.p`
	font-size: 0.9rem;
	text-align: center;
	margin-bottom: 30px;
`;
