import React from 'react';
import { Switch, Route } from 'react-router-dom';

import HomePage from 'containers/Home/HomePage';
import SearchPage from 'containers/Search/SearchPage';
import TicketsPage from 'containers/Tickets/TicketsPage';
import CheckoutPage from 'containers/Checkout/CheckoutPage';
import CheckoutFinishedPage from 'containers/CheckoutFinished/CheckoutFinishedPage';
import OrganizationPage from 'containers/Organization/OrganizationPage';
import CollaboratorInvitePage from 'containers/CollaboratorInvite/CollaboratorInvitePage';
import EventPage from 'containers/Event/EventPage';
import PageNotFound from 'containers/PageNotFound/PageNotFound';
import AccreditationPage from 'containers/Accreditation/AccreditationPage';
import LoginPage from 'containers/Login/LoginPage';
import RegisterPage from 'containers/Register/RegisterPage';
import SocialAuthPage from 'containers/SocialAuthPage/SocialAuthPage';

const Routes = () => (
	<Switch>
		<Route exact path="/" component={HomePage} />
		<Route exact path="/aceite-colaborador/:token" component={CollaboratorInvitePage} />
		<Route exact path="/entrar" component={LoginPage} />
		<Route exact path="/cadastrar" component={RegisterPage} />
		<Route exact path="/login-success" component={SocialAuthPage} />
		<Route exact path="/eventos" component={SearchPage} />
		<Route exact path="/meus-ingressos" component={TicketsPage} />
		<Route exact path="/inscricao/:reserve" component={CheckoutPage} />
		<Route exact path="/inscricao-sucesso" component={CheckoutFinishedPage} />
		<Route exact path="/o-:url" component={OrganizationPage} />
		<Route exact path="/:url/credenciar" component={AccreditationPage} />
		<Route exact path="/:url" component={EventPage} />
		<Route component={PageNotFound} />
	</Switch>
);

export default Routes;
