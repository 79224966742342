import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { getSearch } from 'store/ducks/search/actions';

import * as S from './styles';
import { Container } from 'styles';
import { Select } from 'components/form';
import { Button, ButtonLink } from 'components/standalone';
import { CategoriesState } from 'store/ducks/categories/types';
import { getCategories } from 'store/ducks/categories/actions';
import AnalyticsService from 'services/AnalyticsService';

enum FILTER_TYPES {
	NAME = 'name',
	CATEGORY_ID = 'categoryId',
	TYPE = 'type',
	PERIOD = 'period'
}

const PERIODS = [
	{name: 'Hoje', id: 'TODAY'},
	{name: 'Amanhã', id: 'TOMORROW'},
	{name: 'Nesta semana', id: 'CURRENT_WEEK'},
	{name: 'Neste fim de semama', id: 'WEEKEND'},
	{name: 'Na próxima semana', id: 'NEXT_WEEK'},
	{name: 'No próximo mês', id: 'NEXT_MONTH'},
]

const TYPES = [
	{name: 'Aula', id: 'CLASS'},
	{name: 'Festa', id: 'PARTY'},
	{name: 'Show', id: 'PERFORMANCE'},
	{name: 'Networking', id: 'NETWORKING'},
	{name: 'Seminário', id: 'SEMINAR'},
	{name: 'Conferência', id: 'CONFERENCE'},
	{name: 'Turismo', id: 'TOUR'},
	{name: 'Festival', id: 'FESTIVAL'},
	{name: 'Gala', id: 'GALA'},
	{name: 'Jogo', id: 'GAME'},
	{name: 'Exposição', id: 'EXPO'},
	{name: 'Corrida', id: 'RACE'},
	{name: 'Retiro', id: 'RETREAT'},
	{name: 'Atração', id: 'ATTRACTION'},
	{name: 'Amostra', id: 'SCREENING'},
	{name: 'Aparência', id: 'APPEARANCE'},
	{name: 'Convenção', id: 'CONVENTION'},
	{name: 'Torneio', id: 'TOURNAMENT'},
	{name: 'Rally', id: 'RALLY'},
	{name: 'Outros', id: 'OTHER'},
	{name: 'Samba', id: 'SAMBA'},
	{name: 'Sertanejo', id: 'BACK_COUNTRY'},
	{name: 'Axé', id: 'AXE'},
	{name: 'Eletrônica', id: 'ELECTRONICS'},
	{name: 'Forró', id: 'LINING'},
	{name: 'Pagode', id: 'PAGODA'},
	{name: 'Hip-Hop', id: 'HIP_HOP'},
	{name: 'Rap', id: 'RAP_MUSIC'},
	{name: 'MPB', id: 'MPB'},
	{name: 'Gospel', id: 'GOSPEL'},
	{name: 'Rock', id: 'ROCK'},
	{name: 'Reggae', id: 'REGGAE'},
	{name: 'Futebol', id: 'SOCCER'},
	{name: 'Basquete', id: 'BASKETBALL'},
	{name: 'Volêi', id: 'VOLLEYBALL'},
	{name: 'Tênis', id: 'TENNIS'},
	{name: 'Olímpicos', id: 'OLIMPICS'},
	{name: 'Culto', id: 'CULT'},
	{name: 'Cerimônia', id: 'CEREMONY'},
	{name: 'Missa', id: 'MASS'},
]

interface StateProps {
	categories: CategoriesState,
}

const SearchFilters = ({categoryId, name}: any) => {
	const { categoryList, loading, error } = useSelector((state: StateProps) => state.categories);

	const [filters, setFilters] = useState({name: name, categoryId: categoryId, type: null, period: null});
	const [eventName, setEventName] = useState(name);
	const dispatch = useDispatch();

	const filter = (val: any, key: string) => {
		setFilters({...filters, [key]: val});
	}

	const clear = (key: string) => {
		setFilters({...filters, [key]: null});
	}

	const clearAll = () => {
		setEventName('');
		setFilters({name: null, categoryId: null, type: null, period: null});
		window.history.replaceState({}, document.title);
	}

	useEffect(() => {
		dispatch(getCategories());
	}, [])

	useEffect(() => {
		dispatch(getSearch(filters, {}));

		if (filters.name) {
			AnalyticsService.sendSearchEvent(filters.name);
		}
	}, [filters]);

	useEffect(() => {
		filter((categoryId || null), 'categoryId');
	}, [categoryId]);

	return (
		<Container marginTop={50}>
			<S.FilterBaseWrapper>
				<S.SearchInput id="eventName" placeholder="Nome do evento" value={eventName} onChange={(ev: any) => setEventName(ev.target.value)} />
				<ButtonLink handleClick={clearAll}>Limpar</ButtonLink>
				<Button handleClick={() => filter(eventName, FILTER_TYPES.NAME)}>Filtrar</Button>
			</S.FilterBaseWrapper>

			<S.OptionsWrapper>
				{!loading && !error && (
					<Select
						title="Categoria"
						options={categoryList}
						state={filters.categoryId}
						mobile={isMobile}
						changeHandler={(ev: any) => filter(ev, FILTER_TYPES.CATEGORY_ID)}
						clearHandler={() => clear(FILTER_TYPES.CATEGORY_ID)}
					/>
				)}
				<Select
					title="Tipo"
					options={TYPES}
					state={filters.type}
					mobile={isMobile}
					changeHandler={(ev: any) => filter(ev, FILTER_TYPES.TYPE)}
					clearHandler={() => clear(FILTER_TYPES.TYPE)}
				/>
				<Select
					title="Período"
					options={PERIODS}
					state={filters.period}
					mobile={isMobile}
					changeHandler={(ev: any) => filter(ev, FILTER_TYPES.PERIOD)}
					clearHandler={() => clear(FILTER_TYPES.PERIOD)}
				/>
			</S.OptionsWrapper>
		</Container>
	);
}

export default SearchFilters;
