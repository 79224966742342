import React, { useState } from 'react';
import { toast } from 'react-toastify';

import * as S from '../styles';
import { Input, InputWrapper, Label, Button } from 'styles/Form';
import { Card } from 'styles/Commons';
import AccreditationService from 'services/AccreditationService';

const CODE_SIZE = 5;

const ValidateAccreditationCode = ({ event, cpf, obfuscatedData, onValidate }: any) => {
	const supportNumber = process.env.REACT_APP_SUPPORT_WHATSAPP || null;
	const [code, setCode] = useState({ value: '', hasError: false });
	const [isValidating, setIsValidating] = useState(false);

	const changeCode = (ev: any) => {
		ev.persist();

		const cleanValue = ev.target.value.replace(/[^0-9]/g, '').substring(0, 5);
		setCode(() => ({
			hasError: cleanValue.length === 0 || cleanValue.length < CODE_SIZE,
			value: cleanValue
		}));
	}

	const resendCode = () => {
		AccreditationService.validateCpf(event.id, cpf)
			.then(() => toast('Código reenviado com sucesso.', { type: toast.TYPE.SUCCESS }))
			.catch(({ response }: any) => toast(response.data.detail, { type: toast.TYPE.ERROR }));
	}

	const validateCode = () => {
		setIsValidating(true);

		AccreditationService.validateCode(event.id, cpf, code.value)
			.then(({ data }) => checkCurrentStep(data.apiKey, data.reservationId))
			.catch(({ response }: any) => toast(response.data.detail, { type: toast.TYPE.ERROR }))
			.finally(() => setIsValidating(false));
	}

	const checkCurrentStep = (apiKey: string, reservationId: string) => {
		AccreditationService.getCurrentStep(event.id, apiKey)
			.then(({ data }: any) => onValidate({ apiKey, reservationId, currentStep: data.step}))
			.catch(({ response }: any) => toast(response.data.detail, { type: toast.TYPE.ERROR }))
			.finally(() => setIsValidating(false));
	}

	return (
		<Card>
			<S.AccreditationCardEventBanner image={event.banner} />
			<S.AccreditationCardTitle>{event.name}</S.AccreditationCardTitle>
			<S.AccreditationCardText>Enviamos o código de verificação para o email {obfuscatedData.email} e para o telefone {obfuscatedData.phone}.</S.AccreditationCardText>
			<S.AccreditationCardText>Digite abaixo para continuar com seu credenciamento:</S.AccreditationCardText>

			<InputWrapper>
				<Label>Código</Label>
				<Input id="code" placeholder="00000" value={code.value} onChange={changeCode} />
			</InputWrapper>
			<S.AccreditationResendCodeWrapper>
				<S.AccreditationResendCodeText>Não recebeu o código?&nbsp;</S.AccreditationResendCodeText>
				<S.AccreditationResendCodeLink onClick={resendCode}>Clique aqui para reenviar</S.AccreditationResendCodeLink>
			</S.AccreditationResendCodeWrapper>

			<Button
				width="100%"
				margin="7rem 0 1rem"
				disabled={code.hasError || !code.value || isValidating}
				loading={isValidating}
				onClick={validateCode}>
					Continuar
			</Button>
			<S.AccreditationCardTextLight>Caso não utilize o email e telefone acima, entre em contato com o suporte pelo número {supportNumber}.</S.AccreditationCardTextLight>
		</Card>
	);
};

export default ValidateAccreditationCode;
