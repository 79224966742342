import { SearchTypes, Event } from './types';

export const getSearch = (filters: any, params: any) => ({
	type: SearchTypes.GET_SEARCH,
	payload: { filters, params }
});

export const getSearchSuccess = (data: any) => ({
	type: SearchTypes.GET_SEARCH_SUCCESS,
	payload: { data },
});

export const getSearchFailure = () => ({
	type: SearchTypes.GET_SEARCH_FAILURE
});

export const getMore = (filters: any, params: any, isMore: boolean) => ({
	type: SearchTypes.GET_MORE,
	payload: { filters, params, isMore }
});

export const getMoreSuccess = (data: any) => ({
	type: SearchTypes.GET_MORE_SUCCESS,
	payload: { data },
});

export const setFilters = (filters: any) => ({
	type: SearchTypes.SET_FILTERS,
	payload: { filters },
});
