import { TicketsTypes } from './types';

export const getTickets = (type: string, size: number = 0, sort = '') => ({
	type: TicketsTypes.GET_TICKETS,
	payload: { type, size, sort },
});

export const getTicketsActiveSuccess = (data: any) => ({
	type: TicketsTypes.GET_TICKETS_ACTIVE_SUCCESS,
	payload: { data },
});

export const getTicketsActiveFailure = () => ({
	type: TicketsTypes.GET_TICKETS_ACTIVE_FAILURE
});

export const getTicketsClosedSuccess = (data: any) => ({
	type: TicketsTypes.GET_TICKETS_CLOSED_SUCCESS,
	payload: { data },
});

export const getTicketsClosedFailure = () => ({
	type: TicketsTypes.GET_TICKETS_CLOSED_FAILURE
});

export const downloadAllTickets = (eventId: number, purchaseId: number) => ({
	type: TicketsTypes.DOWNLOAD_ALL_TICKETS,
	payload: { eventId, purchaseId },
});

export const downloadUniqueTicket = (attendeeCode: string) => ({
	type: TicketsTypes.DOWNLOAD_UNIQUE_TICKET,
	payload: { attendeeCode },
});
