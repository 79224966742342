// ACTION TYPES
export enum NavTypes {
	SHOW_MOBILE_OPTIONS = '@nav/SHOW_MOBILE_OPTIONS',
	HIDE_MOBILE_OPTIONS = '@nav/HIDE_MOBILE_OPTIONS',
	SHOW_FIXED_FOOTER_CONTENT = '@nav/SHOW_FIXED_FOOTER_CONTENT',
	HIDE_FIXED_FOOTER_CONTENT = '@nav/HIDE_FIXED_FOOTER_CONTENT',
}

// DATA TYPES
export interface Nav {
	isShowMobileOptions: boolean;
	hasFixedFooterContent: boolean;
}

// STATE TYPES
export interface NavState {
	readonly isShowMobileOptions: boolean;
	readonly hasFixedFooterContent: boolean;
}
