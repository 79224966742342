import React from 'react';
import { isMobile } from 'react-device-detect';
import Skeleton from 'react-loading-skeleton';

import * as S from './styles';
import { EventBannerProps } from './types';

const EventBanner = ({ image }: EventBannerProps) => (
	<>
		{!isMobile && (
			<S.BannerWrapper>
				<S.UnderBannerWrapper>
					{image ? <S.UnderBanner image={image} /> : <Skeleton height={400} />}
				</S.UnderBannerWrapper>

				{image ? <S.HoverBanner image={image} /> : <S.IoSkeleton height={380} />}
			</S.BannerWrapper>
		)}

		{isMobile && (
			<>
				{ image ? <S.BannerMobile src={image} /> : <Skeleton height={180} /> }
			</>
		)}
	</>
);

export default EventBanner;
