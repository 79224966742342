import React, { createContext, useState } from 'react';

type AccreditationType = {
	apiKey: string,
	reservation: string,
	guestQuantity: number,
	orderNumber: string,
	currentStep: string,
}

type PropsAccreditationContext = {
    state: AccreditationType,
    setState: React.Dispatch<React.SetStateAction<AccreditationType>>,
}

const DEFAULT_VALUE = {
    state: {
		apiKey: '',
		reservation: '',
		guestQuantity: 0,
		orderNumber: '',
		currentStep: '',
    },
    setState: () => {},
}

const AccreditationContext = createContext<PropsAccreditationContext>(DEFAULT_VALUE);

const AccreditationContextProvider: React.FC = ({ children }) => {
    const [state, setState] = useState(DEFAULT_VALUE.state);
    return (
        <AccreditationContext.Provider
            value={{
                state,
                setState,
            }}
        >{ children }</AccreditationContext.Provider>
    )
}

export { AccreditationContextProvider };
export default AccreditationContext;
