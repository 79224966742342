// ACTION TYPES
export enum OrganizationTypes {
	GET_ORGANIZATION = '@organization/GET_ORGANIZATION',
	GET_ORGANIZATION_SUCCESS = '@organization/GET_ORGANIZATION_SUCCESS',
	GET_ORGANIZATION_FAILURE = '@organization/GET_ORGANIZATION_FAILURE',
	ACCEPT_COLLABORATOR_INVITE = '@organization/ACCEPT_COLLABORATOR_INVITE',
	ACCEPT_COLLABORATOR_INVITE_MESSAGE = '@organization/ACCEPT_COLLABORATOR_INVITE_MESSAGE',
	ACCEPT_COLLABORATOR_INVITE_ERROR = '@organization/ACCEPT_COLLABORATOR_INVITE_ERROR',
}

//DATA TYPES
export interface Organization {
	id: string,
	about: string,
	email: string,
	imageCover: string,
	imageProfile: string,
	name: string,
	phoneNumber: string,
	url: string,
	events: Event[],
	links: Link[],
}

export interface Event {
	name: string,
	url: string,
	startDate: string,
	banner: string,
	thumbnail: string,
	fullAddress?: string,
	locationName: string,
	locationType: string,
	seatsMapActived: boolean,
	hasFacebookPixel: boolean,
}

export interface Link {
	id: number,
	text: string,
	type: string,
	url: string,
}

// STATE TYPE
export interface OrganizationState {
	readonly data: Organization;
	readonly loading: boolean;
	readonly error: boolean;
	readonly collaboratorInviteMessage: string;
	readonly collaboratorInviteError: boolean;
}
