import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Variables from 'styles/Variables';
import { StyleProps } from './types';

export const LinkStyle = styled(Link)`
	display: block;
	color: ${Variables.colors.light};
	background: ${Variables.colors.main};
	padding: 10px;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
	text-align: center;
	transition: .2s ease;
	${({width}: StyleProps) => width ? `width: ${width}` : '' };
	${({ml}: StyleProps) => ml ? `margin-left: ${ml}` : '' };
	${({mr}: StyleProps) => mr ? `margin-right: ${mr}` : '' };
	${({mt}: StyleProps) => mt ? `margin-top: ${mt}` : '' };
	${({mb}: StyleProps) => mb ? `margin-bottom: ${mb}` : '' };

	&:hover{
		filter: brightness(85%);
	}
`;
