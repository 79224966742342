import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { AccreditationFlow, InviteParticipantFlow } from 'components/accreditation';
import EventService from 'services/EventService';
import { Loading } from 'components/standalone';
import { scrollToTop } from 'utils/appUtils';

enum ACCREDITATION_STEP {
	VALIDATE_USER = 'VALIDATE_USER',
	INVITE_PARTICIPANTS = 'INVITE_PARTICIPANTS',
}

const AccreditationPage = () => {
	const { url } = useParams();
	const [currentStep, setCurrentStep] = useState(ACCREDITATION_STEP.VALIDATE_USER);
	const [isLoading, setIsLoading] = useState(true);
	const [event, setEvent] = useState<any>(null);
	const [continueOnInviteConclude, setContinueOnInviteConclude] = useState(false);
	const history = useHistory();

	useEffect(() => {
		getEvent();
	}, []);

	const getEvent = () => {
		EventService.getEvent(url)
			.then(({ data }) => {
				if (data.isAccreditation) {
					setEvent(data);
					setIsLoading(false);
					return;
				}
				history.push({ pathname: `/${url}` });
			})
			.catch(() => history.push({ pathname: `/` }))
	};

	const updateReserveData = () => {
		setCurrentStep(ACCREDITATION_STEP.INVITE_PARTICIPANTS);
		scrollToTop();
	}

	const flowConcluded = () => {
		updateReserveData();
		setContinueOnInviteConclude(true);
	}

	return (
		<>
			{currentStep === ACCREDITATION_STEP.VALIDATE_USER && (
				<>
					{isLoading
						? <Loading height='200px' />
						: <AccreditationFlow event={event} onConclude={updateReserveData} flowConcludedFn={flowConcluded} />
					}
				</>
			)}

			{currentStep === ACCREDITATION_STEP.INVITE_PARTICIPANTS && (
				<>
					{isLoading
						? <Loading height='200px' />
						: <InviteParticipantFlow event={event} continueOnInviteConclude={continueOnInviteConclude} />
					}
				</>
			)}
		</>
	);
}

export default AccreditationPage;
