import styled from 'styled-components';

export const LoginWarningWrapper = styled.div`
	align-items: flex-start;
	background-color: #F44336;
	border-radius: 0.5rem;
	color: #fff;
	display: flex;
	margin-bottom: 1rem;
	padding: 1rem;
`;

export const LoginWarningIcon = styled.div`
	min-width: 1.5rem;
`;

export const LoginWarningText = styled.p`
	color: #ffffff;
	font-size: 0.875rem;
	line-height: 1.25rem;
	margin-left: 0.5rem;
`;

export const LoginWarningLink = styled.a`
	cursor: pointer;
	text-decoration: underline;
`;
