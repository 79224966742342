import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { Seo } from 'components/standalone';
import CheckoutFeedback from 'components/checkout/CheckoutFeedback';
import PixelManagement from 'components/layout/pixelManagement/PixelManagement';

const CheckoutFinishedPage = () => {
	const { state }: any = useLocation();
	const history = useHistory();

	useEffect(() => {
		if (!state) {
			history.push('/');
		}
	});

	return (
		<>
			<Seo title={`${process.env.REACT_APP_NAME} - Compra finalizada`} />
			{state && <CheckoutFeedback data={state.data} />}
			{state?.hasMarketing && (<PixelManagement eventId={state.data.event.id} />)}
		</>
	);
}

export default CheckoutFinishedPage;
