import styled from 'styled-components';

interface gridProps {
  gridTemplateColumns?: string;
  gridColumnGap?: number;
  gridRowGap?: number;
  gridColumns?: number;
  justifyItems?: string;
  alignItems?: string;
  marginTop?: string;
  marginBottom?: string;
}

const Grid = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-column-gap: ${({ gridColumnGap }: gridProps) => `${gridColumnGap}px` || 0};
  grid-row-gap: ${({ gridRowGap }: gridProps) => `${gridRowGap}px` || 0};
  grid-template-columns: ${({ gridColumns }: gridProps) => `repeat(${gridColumns}, 1fr)` || '1fr'};
  ${({ gridTemplateColumns }: gridProps) => `grid-template-columns: ${gridTemplateColumns}` || ''};
  ${({ justifyItems }: gridProps) => `justify-items: ${justifyItems}` || ''};
  ${({ alignItems }: gridProps) => `align-items: ${alignItems}` || ''};
  ${({ marginTop }: gridProps) => `margin-top: ${marginTop}` || ''};
  ${({ marginBottom }: gridProps) => `margin-bottom: ${marginBottom}` || ''};

`;

export default Grid;
