import React, { useEffect, useState } from 'react';
import { Clock } from 'react-feather';
import { RenderIf } from 'components/layout';
import * as S from './styles';

const PhoneAuthResendCode = ({hasOptions = true, resendCode, resendWhatsappFn, resendSmsFn}: any) => {
	const [displayOptions, setDisplayOptions] = useState(false);
	const [counter, setCounter] = useState(60);
	const [timer, setTimer] = useState('00:00');

	useEffect(() => {
		if (counter > 0) {
			setTimeout(() => {
				const current = counter - 1;
				const leftZero = current.toString().length === 1 ? '0' : '';

				setCounter(current);
				setTimer(`00:${leftZero}${current}`)
			}, 1000);
		}
	}, [counter]);

	const resendWhatsapp = () => {
		setCounter(60);
		resendWhatsappFn();
	}

	const resendSms = () => {
		setCounter(60);
		resendSmsFn();
	}

	const checkHasOptions = () => {
		if (hasOptions) {
			setDisplayOptions(true);
			return;
		}

		setCounter(60);
		resendCode();
	}

	return (
		<>
			<S.Wrapper>
				<Clock size={18} />
				<S.Timer>{timer}</S.Timer>
				<S.Text disabled={counter > 0} onClick={checkHasOptions}>{hasOptions ? 'Não recebi meu código' : 'Reenviar código de confirmação'}</S.Text>
			</S.Wrapper>

			<RenderIf condition={hasOptions}>
				<S.OptionsWrapper displayOptions={displayOptions} onClick={() => setDisplayOptions(false)}>
					<S.OptionsBox displayOptions={displayOptions}>
						<S.OptionItem onClick={resendWhatsapp}>Reenviar código de confirmação</S.OptionItem>
						<S.OptionItem onClick={resendSms}>Enviar SMS</S.OptionItem>
						<S.OptionItem onClick={() => setDisplayOptions(false)}>Cancelar</S.OptionItem>
					</S.OptionsBox>
				</S.OptionsWrapper>
			</RenderIf>
		</>
	)
};

export default PhoneAuthResendCode;
