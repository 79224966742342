import styled from 'styled-components';

interface flexProps {
	isWrap: boolean;
	justifyContent?: string;
	alignItems?: string;
	marginTop?: number;
	marginBottom?: number;
}

const Flex = styled.div`
	display: flex;

	${({ isWrap }: flexProps) => isWrap ? 'flex-wrap: wrap' : 'flex-wrap: no-wrap'};
	${({ justifyContent }: flexProps) => `justify-content: ${justifyContent}` || ''};
	${({ alignItems }: flexProps) => `align-items: ${alignItems}` || 'center'};
	${({ marginTop }: flexProps) => `margin-top: ${marginTop}px` || ''};
	${({ marginBottom }: flexProps) => `margin-bottom: ${marginBottom}px` || ''};
`;

export default Flex;
