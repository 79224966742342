import React, { useState } from 'react';
import styled from 'styled-components';
import Variables from 'styles/Variables';

type CheckboxStyleProps = {
	active: boolean;
}

type CheckboxProps = {
	currentValue?: boolean,
	id: string,
	label?: string,
	onChangeFn: Function,
}

const CheckboxWrapper = styled.div`
	display: flex;
	margin-right: 0.5rem;
`;

const CheckboxInput = styled.input`
	display: none;
`;

const CheckboxSelector = styled.label`
	border: 2px solid #333333;
	border-radius: 3px;
	cursor: pointer;
	display: block;
	height: 18px;
	min-width: 18px;
	position: relative;
	width: 18px;

	&:before {
		content: '';
		height: 4px;
		left: 2px;
		position: absolute;
		top: 2px;
		transform: rotate(-45deg);
		width: 8px;
	}

	${({ active }: CheckboxStyleProps) => active && `
		background-color: ${Variables.backgrounds.main};
		border-color: ${Variables.backgrounds.main};

		&:before {
			border-bottom: 2px solid #fff;
			border-left: 2px solid #fff;
		}
	`}
`;

const CheckboxLabel = styled.label`
	margin-left: 0.5rem;
	cursor: pointer;
`;

const Checkbox = ({ currentValue = false, id, label, onChangeFn }: CheckboxProps) => {
	const [value, setValue] = useState(currentValue);

	const change = (status: boolean) => {
		onChangeFn(status);
		setValue(() => status);
	}

	return (
		<CheckboxWrapper>
			<CheckboxInput id={id} type="checkbox" onChange={$event => change($event.target.checked)} />
			<CheckboxSelector htmlFor={id} active={value} />
			{label && (<CheckboxLabel htmlFor={id}>{label}</CheckboxLabel>)}
		</CheckboxWrapper>
	);
}

export default Checkbox;
