import React, { useEffect, useState } from 'react';

import EventService from 'services/EventService';
import * as S from './styles';

const AdditionalMessage = ({eventId}: any) => {
	const [message, setMessage] = useState({ hasData: false, title: '', description: '' });

	useEffect(() => {
		EventService.getAdditionalMessage(eventId)
			.then(({data}: any) => setMessage(() => ({
				hasData: true,
				title: data.title,
				description: data.description,
			})));
	}, [eventId]);

	return (
		<>
			{message.hasData && (
				<S.MessageWrapper>
					<S.Title data-test="eventAdditionalMessageTitle">{message.title}</S.Title>
					<S.Message data-test="eventAdditionalMessage">{message.description}</S.Message>
				</S.MessageWrapper>
			)}
		</>
	);
}

export default AdditionalMessage;
