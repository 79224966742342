import styled from 'styled-components';
import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';
import Variables from 'styles/Variables';

export const TagsWrapper = styled.div`
	margin: 30px 0 50px;
`;

export const TagsTitle = styled.h3`
	font-size: 1.15rem;
	margin-bottom: 15px;
	font-weight: 600;

	${breakAt(BreakpointsSizes.lg)} {
		font-size: 1.35rem;
	}
`;

export const TagList = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const Tag = styled.span`
	padding: 5px 10px;
	border-radius: 40px;
	margin: 0 15px 15px 0;
	font-size: 0.75rem;
	border: 2px solid ${Variables.colors.main};
	color: ${Variables.backgrounds.main};
`;
