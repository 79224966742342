import styled from 'styled-components';
import Variables from './Variables';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

export const SwiperContainer = styled.div`
	& .swiper-pagination-bullets{ bottom: 0; }

	& .swiper-pagination-bullet{
		height: 15px;
		width: 15px;
		background: gray;
		margin: 0 6px !important;
	}

	& .swiper-pagination-bullet-active{ background: ${Variables.colors.main}; }

	& .swiper-button-prev,
	& .swiper-button-next{
		cursor: pointer;
		height: 40px;
		width: 40px;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		margin-top: -20px;
		z-index: 8000;
		background-color: rgba(255, 255, 255, 0.75);

		&:before{
			content: '';
			font-size: 3.25rem;
			font-weight: 900;
			line-height: 0.65;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;

		}
	}

	& .swiper-button-prev{
		left: 10px;
		&:before{
			content: '‹';
			margin-right: 2px;
		}
	}

	& .swiper-button-next{
		right: 10px;
		&:before{
			content: '›';
			margin-left: 2px;
		}
	}
`;

export const SwiperEnd = styled.div`
	height: 35px;
`;
