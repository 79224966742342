import React from 'react';
import { Tabs, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { Event } from 'store/ducks/organization/types';

import { EventCard } from 'components/standalone';
import { Container } from 'styles';
import * as S from './styles';

const OrganizationEvents = ({ events, about }: any) => (
	<Container marginBottom={50}>
		<Tabs>
			<S.CustomTabList>
				{events.length > 0 && (<S.CustomTab>Eventos</S.CustomTab>)}
				<S.CustomTab>Sobre</S.CustomTab>
			</S.CustomTabList>

			{events.length > 0 && (
				<TabPanel>
					<S.OrganizationEventList>
						{events && (
							events.map((event: Event) => (
								<EventCard
									key={event.url}
									name={event.name}
									startDate={event.startDate}
									locationName={event.locationName}
									locationType={event.locationType}
									thumbnail={event.thumbnail}
									url={event.url}
									seatsMapActived={event.seatsMapActived}
									banner=""
									short={false}
									main={false}
									hasFacebookPixel={event.hasFacebookPixel}
								/>
							))
						)}
					</S.OrganizationEventList>
				</TabPanel>
			)}
			<TabPanel>
				<S.OrganizationAbout dangerouslySetInnerHTML={{__html: about}}></S.OrganizationAbout>
			</TabPanel>
		</Tabs>
	</Container>
);

export default OrganizationEvents;
