import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { acceptCollaboratorInvite } from 'store/ducks/organization/actions';

import { Loading } from 'components/standalone';
import { OrganizationState } from 'store/ducks/organization/types';
import { CollaboratorInviteResponse } from 'components/organization';

interface StateProps {
	organization: OrganizationState,
}

const CollaboratorInvitePage = () => {
	const { token } = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const { collaboratorInviteMessage, collaboratorInviteError } = useSelector((state: StateProps) => state.organization);

	useEffect(() => {
		dispatch(acceptCollaboratorInvite(token));
	}, [token])

	return (
		<>
			{collaboratorInviteMessage
				? <CollaboratorInviteResponse error={collaboratorInviteError} message={collaboratorInviteMessage} />
				: <Loading text="Validando colaborador" />}
		</>
	);
}

export default CollaboratorInvitePage;
