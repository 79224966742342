import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

// import { logout } from 'store/ducks/auth/actions';
import { NavState } from 'store/ducks/nav/types';
import { Button, ButtonLink } from 'styles/Form';
import * as S from './styles';

interface StateProps {
	nav: NavState,
}

const NavBarMobile = ({ user, logoutFn }: any) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const select = (state: StateProps) => state.nav;
	const { isShowMobileOptions } = useSelector(select);
	const [showProfile, setShowProfile] = useState(false);

	useEffect(() => {
		document.body.style.overflow = (showProfile ? 'hidden' : 'initial');
		if (showProfile) {
			window.scrollTo(0, 0);
		}
	}, [showProfile])

	return (
		<>
			<S.NavBarMobileWrapper display={isShowMobileOptions}>
				<S.NavMobileItem to="/" exact={true} onClick={() => setShowProfile(false)}>
					<S.NavMobileItemIcon src={`${process.env.PUBLIC_URL}/icons/icon-home.svg`} />
					<S.NavMobileItemText>Home</S.NavMobileItemText>
				</S.NavMobileItem>

				<S.NavMobileItem to={{pathname: "/eventos", state: {name: null, categoryId: null}}} onClick={() => setShowProfile(false)}>
					<S.NavMobileItemIcon src={`${process.env.PUBLIC_URL}/icons/icon-search.svg`} />
					<S.NavMobileItemText>Buscar</S.NavMobileItemText>
				</S.NavMobileItem>

				<S.NavMobileItem to="/meus-ingressos" onClick={() => setShowProfile(false)}>
					<S.NavMobileItemIcon src={`${process.env.PUBLIC_URL}/icons/icon-tickets.svg`} />
					<S.NavMobileItemText>Ingressos</S.NavMobileItemText>
				</S.NavMobileItem>

				<S.MobileItem onClick={() => setShowProfile(!showProfile)}>
					<S.NavMobileItemIcon src={`${process.env.PUBLIC_URL}/icons/icon-user.svg`} />
					<S.NavMobileItemText>Perfil</S.NavMobileItemText>
				</S.MobileItem>
			</S.NavBarMobileWrapper>

			<S.ProfileWrapper isMenuMobileOpen={showProfile}>
				{user && user.username && (
					<>
						<S.UserInfoWrapper>
							<S.UserImage image={user.photoUrl} />
							<S.UserInfo>
								<S.UserName>{user.firstName} {user.lastName}</S.UserName>
								<S.UserEmail>{user.username}</S.UserEmail>
							</S.UserInfo>
						</S.UserInfoWrapper>

						<S.UserOption id="mobileMyTickets" to="/meus-ingressos" onClick={() => setShowProfile(false)}>
							Meus ingressos
						</S.UserOption>

						<S.UserOptionExternal id="mobileMyEvents" href={`${process.env.REACT_APP_PRODUCER_URL}/meus-eventos`}>
							Meus eventos
						</S.UserOptionExternal>

						<S.UserOptionExternal id="mobileMyAccount" href={`${process.env.REACT_APP_PRODUCER_URL}/minha-conta`}>
							Minha conta
						</S.UserOptionExternal>

						<S.UserOption id="mobileLogout" to="#" onClick={() => {
							setShowProfile(false);
							logoutFn();
						}}>
							Sair
						</S.UserOption>

						<Button
							id="mobileGotoCreateEvent"
							as="a"
							margin="1rem auto"
							width="250px"
							href={`${process.env.REACT_APP_PRODUCER_URL}/evento/novo`}>
								Criar evento
						</Button>

						<ButtonLink
							id="desktopGotoProducerArea"
							as="a"
							margin="1rem auto"
							width="250px"
							href={`${process.env.REACT_APP_PRODUCER_URL}`}>
								Área do produtor
						</ButtonLink>
					</>
				)}

				{user && !user.username && (
					<>
						<S.LoginImage />
						<S.Title>Login necessário</S.Title>
						<S.Subtitle>Você precisa estar logado para ter acesso ao perfil</S.Subtitle>

						<Button
							id="mobileGotoLogin"
							as={Link}
							to={{ pathname: '/entrar', state: {previousUrl: location.pathname} }}
							margin="1rem auto"
							width="250px"
							onClick={() => setShowProfile(false)}>
								Entrar
						</Button>

						<Button
							id="mobileGotoRegister"
							as={Link}
							to="cadastrar"
							margin="1rem auto"
							width="250px"
							onClick={() => setShowProfile(false)}>
								Cadastre-se
						</Button>
					</>
				)}

			</S.ProfileWrapper>
		</>
	);
}

export default NavBarMobile;
