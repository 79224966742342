import styled from 'styled-components';
import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';

export const TicketsHeaderWrapper = styled.section`
	height: 120px;
	background-color: #e4e4e4;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
`;

export const TicketsHeaderTitle = styled.h1`
	font-size: 1.5rem;
	font-weight: 600;

	${breakAt(BreakpointsSizes.md)} {
		font-size: 2rem;
	}
`;
