import styled from 'styled-components';
import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';

export const NavLoginWarningWrapper = styled.div`
	align-items: flex-start;
	background-color: #F44336;
	border-radius: 0.5rem;
	bottom: 120px;
	color: #fff;
	display: flex;
	max-width: 340px;
	padding: 1rem;
	position: fixed;
	right: 1rem;
	z-index: 9999;

	${breakAt(BreakpointsSizes.lg)} {
		bottom: initial;
		position: absolute;
		top: 0px;
		right: 60px;

		&:before {
			border: 0.75rem solid transparent;
			border-bottom-color: #F44336;
			content: '';
			position: absolute;
			right: calc(50% - 0.75rem);
			top: -1.5rem;
		}
	}
`;

export const NavLoginWarningIcon = styled.div`
	min-width: 1.5rem;
`;

export const NavLoginWarningClose = styled.div`
	cursor: pointer;
	min-width: 1.25rem;
`;

export const NavLoginWarningText = styled.p`
	color: #ffffff;
	font-size: 0.875rem;
	line-height: 1.25rem;
	margin: 0 0.5rem;
`;
