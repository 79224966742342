import { OrganizationTypes, Organization } from './types';

export const getOrganization = (url: string) => ({
	type: OrganizationTypes.GET_ORGANIZATION,
	payload: { url }
});

export const getOrganizationSuccess = (data: Organization) => ({
	type: OrganizationTypes.GET_ORGANIZATION_SUCCESS,
	payload: { data },
});

export const getOrganizationFailure = () => ({
	type: OrganizationTypes.GET_ORGANIZATION_FAILURE
});

export const acceptCollaboratorInvite = (token: string) => ({
	type: OrganizationTypes.ACCEPT_COLLABORATOR_INVITE,
	payload: { token }
});

export const acceptCollaboratorInviteMessage = (data: any) => ({
	type: OrganizationTypes.ACCEPT_COLLABORATOR_INVITE_MESSAGE,
	payload: { data }
});

export const acceptCollaboratorInviteError = () => ({
	type: OrganizationTypes.ACCEPT_COLLABORATOR_INVITE_ERROR,
});
