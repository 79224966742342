import React from 'react';

import { UserContextProvider } from './user';
import { PixelContextProvider } from './pixel';
import { AnalyticsContextProvider } from './analytics';
import { AccreditationContextProvider } from './accreditation';

const GlobalContext: React.FC = ({ children }) => {
    return (
        <>
            <UserContextProvider>
				<AnalyticsContextProvider>
					<PixelContextProvider>
						<AccreditationContextProvider>
							{ children }
						</AccreditationContextProvider>
					</PixelContextProvider>
				</AnalyticsContextProvider>
			</UserContextProvider>
        </>
    )
}

export default GlobalContext;
