// ACTION TYPES
export enum SearchTypes {
	GET_SEARCH = '@search/GET_SEARCH',
	GET_SEARCH_SUCCESS = '@search/GET_SEARCH_SUCCESS',
	GET_SEARCH_FAILURE = '@search/GET_SEARCH_FAILURE',
	GET_MORE = '@search/GET_MORE',
	GET_MORE_SUCCESS = '@search/GET_MORE_SUCCESS',
	SET_FILTERS = '@search/SET_FILTERS',
}

//DATA TYPES
export interface Event {
	name: string,
	url: string,
	startDate: string,
	banner: string,
	thumbnail: string,
	fullAddress?: string,
	locationType: string,
	locationName: string,
}

export interface Filters {
    type?: string,
    categoryId?: string,
    name?: string,
    period?: string,
}

// STATE TYPE
export interface SearchState {
	readonly quantityFound: number,
	readonly filters: Filters,
	readonly lastPage: boolean,
	readonly currentPage: number,
	readonly data: Event[],
	readonly loading: boolean,
	readonly error: boolean,
	readonly loadingMore: boolean,
}
