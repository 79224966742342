import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';
import { EventBannerProps } from './types';

export const BannerMobile = styled.img`
	max-width: 100%;
	height: auto;
`;

export const BannerWrapper = styled.div`
	position: relative;

	${breakAt(BreakpointsSizes.lg)} {
		margin-bottom: 40px;
	}
`;

export const UnderBannerWrapper = styled.div`
	overflow: hidden;
`;

export const UnderBanner = styled.div`
	height: 180px;
	width: 100%;
	background-image: url(${({ image }: EventBannerProps) => image || ''});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	${breakAt(BreakpointsSizes.lg)} {
		height: 400px;
		filter: blur(5px);
		position: relative;
		transform: scale(1.02);

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background-color: rgba(0, 0, 0, 0.3);
		}
	}
`;

export const HoverBanner = styled.div`
	display: none;

	${breakAt(BreakpointsSizes.lg)} {
		background-image: url(${({ image }: EventBannerProps) => image || ''});
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: absolute;
		top: 60px;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		height: 380px;
		width: calc(100% - 20px);
		max-width: 1200px;
		border-radius: 20px;
		box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
	}
`;

export const IoSkeleton = styled(Skeleton)`
	position: absolute;
	top: 60px;
	left: 50%;
	transform: translateX(-50%);
	display: block;
	width: 100%;
	max-width: 1200px;
	border-radius: 20px;
`;
