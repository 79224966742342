import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactPixel from 'react-facebook-pixel';

import { loadTickets } from 'services/ScriptLoader';
import * as S from './styles';
import PixelContext from 'context/pixel';

export type Props = {
	eventId: string,
	affiliateCode?: string | null,
	ticketToken?: string | null,
	limitedByCpf: boolean,
	hasMarketing: boolean,
	grouped: boolean,
	hasDiscount: boolean,
	meepId?: string,
}

const TicketsList = ({ eventId, affiliateCode, ticketToken, limitedByCpf, hasMarketing, grouped, hasDiscount, meepId }: Props) => {
	const { state: pixelState } = useContext(PixelContext);
	const [ showAnchor, setShowAnchor ] = useState(false);
	const [ pixelValid, setPixelValid ] = useState(false);
	const history = useHistory();
	let ticketComponent: any;

	const handle = (comp: any) => ticketComponent = comp;

	const gotoCheckout = (ev: any) => {
		history.push({ pathname: `/inscricao/${ev.detail.reservationId}`, state: { eventId, hasMarketing } });
	}

	const reserveErrorHandler = (ev: any) => {
		toast(ev.detail.detail, { type: toast.TYPE.ERROR });
	}

	const ticketsErrorHandler = (ev: any) => {
		toast(ev.detail.detail, { type: toast.TYPE.ERROR });
	}

	useEffect(() => {
		loadTickets();
		ticketComponent.addEventListener('success', gotoCheckout);
		ticketComponent.addEventListener('get-tickets-error', ticketsErrorHandler);
		ticketComponent.addEventListener('generate-reserve-error', reserveErrorHandler);

		if (isMobile && typeof window !== 'undefined') {
			window.onscroll = () => {
				let currentScrollPos = window.pageYOffset;
				setShowAnchor(currentScrollPos > 650);
			}
		}
	}, []);

	const triggerAddTicketEvent = (ev: any) => {
		if (pixelState.active && pixelState.eventAddToCart) {
			const data = {
				content_name: ev.detail.name,
				currency: process.env.REACT_APP_CURRENCY,
				value: ev.detail.value,
				contents: ev.detail,
			}
			ReactPixel.track('AddToCart', data);
		}
	}

	useEffect(() => {
		if (pixelState.active && !pixelValid) {
			setPixelValid(true);
			ticketComponent.addEventListener('add-ticket', triggerAddTicketEvent);
		}
	}, [pixelState]);

	return (
		<>
			<io-tickets
				event={eventId}
				tenant={process.env.REACT_APP_TENANT_ID}
				type="WEB"
				validate={limitedByCpf}
				color={process.env.REACT_APP_COLOR_MAIN}
				affiliate={affiliateCode}
				token={ticketToken}
				grouped={grouped}
				discount={hasDiscount}
				meepid={meepId || null}
				ref={handle}
			/>
			<S.TicketsBoxAnchor style={{bottom: (showAnchor ? 0 : '-60px')}} to="boxTop" offset={-40} smooth={true}>Ver Ingressos</S.TicketsBoxAnchor>
		</>
	)
}

export default TicketsList;
