import { Reducer } from 'redux';

import { CheckoutState, CheckoutTypes } from './types';

const INITIAL_STATE: CheckoutState = {
	loading: true,
	error: false
};

const reducer: Reducer<CheckoutState> = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CheckoutTypes.LOAD_SUCCESS:
			return {
				...state,
				loading: false,
			}

		case CheckoutTypes.LOAD_FAILURE:
			return {
				...state,
				loading: false,
				error: true,
			};

		default:
			return state;
	}
};

export default reducer;
