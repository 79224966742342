import React from 'react';

import * as S from '../styles';
import { Button } from 'styles/Form';
import { Bolder, Card } from 'styles/Commons';

const AccreditationReserveConfirmed = ({ orderNumber, onConfirm }: any) => {

	return (
		<Card>
			<S.AccreditationCardTitle>Precisamos de uma foto do seu rosto! Ah, ela também estará visível no seu ingresso! </S.AccreditationCardTitle>
			<S.AccreditationCardText>Algumas dicas para sua foto:</S.AccreditationCardText>

			<S.AccreditationCardList>
				<S.AccreditationCardListItem>Escolha um ambiente iluminado;</S.AccreditationCardListItem>
				<S.AccreditationCardListItem>Evite utilizar óculos ou acessórios como bonés, gorros e outros;</S.AccreditationCardListItem>
				<S.AccreditationCardListItem>Verifique se a foto está focada e se é possível visualizar seu rosto.</S.AccreditationCardListItem>
			</S.AccreditationCardList>

			<S.AccreditationPhotoTitle>Por que precisamos da sua foto?</S.AccreditationPhotoTitle>

			<S.AccreditationCardText>A foto enviada será usada para validar sua a entrada no evento.</S.AccreditationCardText>

			<S.AccreditationCardText>Além disso, ela também estará visível no seu ingresso juntamente com o QR Code de acesso para melhorar sua experiência.</S.AccreditationCardText>

			<S.AccreditationCardText>Nº do pedido <br /><Bolder>{orderNumber.toUpperCase()}</Bolder></S.AccreditationCardText>

			<Button width="100%" onClick={onConfirm}>Tirar foto</Button>
		</Card>
	);
};

export default AccreditationReserveConfirmed;
