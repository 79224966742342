import Modal from 'components/layout/modal/Modal';
import React, { useState, useEffect } from 'react';
import { Edit2, Trash } from 'react-feather';
import { toast } from 'react-toastify';

import AccreditationService from 'services/AccreditationService';
import { Card } from 'styles/Commons';
import { Button, ButtonOutline, Input, InputErrorMessage, InputWrapper, Label, MaskInput } from 'styles/Form';
import { isValidCpf } from 'utils/appUtils';
import * as S from './styles';

interface Participant {
	id: number;
	userFirstName: string;
	userLastName: string;
	userEmail: string;
	userCpf: string;
	userPhone: string;
	isAssociate: true;
}

const  InviteParticipantForm = ({ participant, unique, eventId, reservation, apiKey, position, onConclude, onRemove }: any) => {
	const [id, setId] = useState({ value: '', hasError: false });
	const [name, setName] = useState({ value: '', hasError: false });
	const [email, setEmail] = useState({ value: '', hasError: false });
	const [phone, setPhone] = useState({ value: '', hasError: false });
	const [cpf, setCpf] = useState({ value: '', hasError: false });
	const [invalidForm, setInvalidForm] = useState(true);
	const [enabledForm, setEnabledForm] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	useEffect(() => {
		if (participant) {
			setParticipantValues();
			setEnabledForm(false);
		}
	}, [participant]);

	useEffect(() => {
		validateForm();
	}, [name, email, phone, cpf]);

	const setParticipantValues = () => {
		setId(() => ({ value: participant.id, hasError: false }));
		setName(() => ({ value: participant.name, hasError: false }));
		setEmail(() => ({ value: participant.email, hasError: false }));
		setPhone(() => ({ value: participant.phone, hasError: false }));
		setCpf(() => ({ value: participant.cpf, hasError: false }));
	}

	const changeName = (ev: any) => {
		ev.persist();
		setName(() => ({
			hasError: ev.target.value.length === 0,
			value: ev.target.value
		}));
	}

	const changeEmail = (ev: any) => {
		ev.persist();
		setEmail(() => ({
			hasError: ev.target.value.length === 0 || !ev.target.value.match(/^[\w\.\-_]{1,}@[\w\.\-]{6,}/),
			value: ev.target.value.trim()
		}));
	}

	const changePhone = (ev: any) => {
		ev.persist();

		const cleanValue = ev.target.value.replace(/\D/g,'').substring(0, 11);
		setPhone(() => ({
			hasError: cleanValue.length === 0 || cleanValue.length < 11,
			value: cleanValue
		}));
	}

	const changeCpf = (ev: any) => {
		ev.persist();

		const cleanValue = ev.target.value.replace(/\D/g,'').substring(0, 11);
		setCpf(() => ({
			hasError: cleanValue.length > 0 && !isValidCpf(cleanValue),
			value: cleanValue
		}));
	}

	const validateForm = () => {
		if (!name.value || !email.value || !cpf.value || !phone.value) {
			setInvalidForm(true);
			return;
		}

		if (name.hasError || email.hasError || cpf.hasError || phone.hasError) {
			setInvalidForm(true);
			return;
		}

		setInvalidForm(false);
	}

	const cancelEditParticipant = () => {
		setParticipantValues();
		setEnabledForm(false);
	}

	const addParticipant = (ev: any) => {
		ev.preventDefault();
		setIsSaving(true);

		const payload = {
			id: id.value,
			userFirstName: name.value.trim(),
			userEmail: email.value,
			userCpf: cpf.value,
			userPhone: phone.value,
		}

		AccreditationService.addParticipant(eventId, reservation, apiKey, payload)
			.then(() => {
				onConclude();
				setEnabledForm(false);
			})
			.catch(({ response }: any) => toast(response.data.detail, { type: toast.TYPE.ERROR }))
			.finally(() => setIsSaving(false));
	}

	const enableUpdate = () => {
		setEnabledForm(true);
	}

	const removeParticipant = () => {
		if (id.value) {
			setIsModalOpen(true);
			return;
		}

		onRemove();
	}

	const closeModal = () => {
		setIsModalOpen(false);
	}

	const removeParticipantWithId = () => {
		AccreditationService.removeParticipant(eventId, reservation, apiKey, id.value)
			.then(() => {
				closeModal();
				onRemove(id.value);
			});
	}

	return (
		<>
			<Card padding="1.5rem" margin="0 0 2rem">
				<S.CardHeader>
					<S.CardTitle>Convidado {position}</S.CardTitle>

					<div>
						{id.value && !enabledForm && (
							<S.ActionButton onClick={enableUpdate}>
								<Edit2 size={18} />
							</S.ActionButton>
						)}

						{!unique && (
							<S.ActionButton onClick={removeParticipant}>
								<Trash size={18} />
							</S.ActionButton>
						)}
					</div>
				</S.CardHeader>

				<InputWrapper hasError={name.hasError}>
					<Label>Nome completo</Label>
					<Input id="name" placeholder="Nome completo" value={name.value} disabled={!enabledForm} onChange={changeName} />
					<InputErrorMessage>Campo inválido</InputErrorMessage>
				</InputWrapper>

				<InputWrapper hasError={email.hasError}>
					<Label>E-mail</Label>
					<Input id="email" placeholder="E-mail" value={email.value} disabled={!enabledForm} onChange={changeEmail} />
					<InputErrorMessage>Campo inválido</InputErrorMessage>
				</InputWrapper>

				<InputWrapper hasError={phone.hasError}>
					<Label>Telefone</Label>
					<MaskInput id="phone" mask="(99) 99999-9999" value={phone.value} maskPlaceholder={null} placeholder="Telefone" disabled={!enabledForm} onChange={changePhone} />
					<InputErrorMessage>Campo inválido</InputErrorMessage>
				</InputWrapper>

				<InputWrapper hasError={cpf.hasError}>
					<Label>CPF</Label>
					<MaskInput id="cpf" mask="999.999.999-99" value={cpf.value} maskPlaceholder={null} placeholder="CPF" disabled={!enabledForm} onChange={changeCpf} />
					<InputErrorMessage>Campo inválido</InputErrorMessage>
				</InputWrapper>

				{!id.value && (
					<ButtonOutline width="100%" disabled={invalidForm} onClick={addParticipant} loading={isSaving}>Adicionar</ButtonOutline>
				)}

				{id.value && !enabledForm && (
					<S.ParticipantAdded>
						<S.ParticipantAddedIcon />
						<S.ParticipantAddedText>Adicionado</S.ParticipantAddedText>
					</S.ParticipantAdded>
				)}

				{id.value && enabledForm && (
					<div style={{display: 'grid', gridGap: '1rem', gridAutoFlow: 'column'}}>
						<ButtonOutline width="100%" disabled={isSaving} onClick={cancelEditParticipant}>Cancelar</ButtonOutline>
						<Button width="100%" disabled={invalidForm} onClick={addParticipant} loading={isSaving}>Alterar</Button>
					</div>
				)}
			</Card>

			<Modal isOpen={isModalOpen} title="Deseja continuar?" onClose={closeModal}>
				<>
					<S.RemoveParticipantText>Ao confirmar os dados do convidado serão descartados.</S.RemoveParticipantText>

					<Button width="100%" margin="2rem 0 0" onClick={removeParticipantWithId}>Excluir convidado</Button>
					<ButtonOutline width="100%" margin="1rem 0 0" onClick={closeModal}>Cancelar</ButtonOutline>
				</>
			</Modal>
		</>
	);
}

export default InviteParticipantForm;
