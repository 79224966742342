import { call, put } from 'redux-saga/effects';

import {
	getOrganizationSuccess,
	getOrganizationFailure,
	acceptCollaboratorInviteMessage,
	acceptCollaboratorInviteError } from './actions';
import { eventApi } from '../../../services/Api';

export function* getOrganizationSaga(action: any) {
	try {
		const response = yield call(eventApi.get, `/public/organization/events?organizationUrl=${action.payload.url}`);
		yield put(getOrganizationSuccess(response.data));
	} catch (err) {
		yield put(getOrganizationFailure());
	}
}

export function* acceptCollaboratorInviteSaga(action: any) {
	try {
		const response = yield call(eventApi.post, `/public/organization/collaborators/${action.payload.token}`, {});
		yield put(acceptCollaboratorInviteMessage(response.data.message));
	} catch (err) {
		yield put(acceptCollaboratorInviteError());
		yield put(acceptCollaboratorInviteMessage(err?.data?.detail ?? 'Não foi possível processar o aceite do convite'));
	}
}
