import React, { useContext, useState } from 'react';
import { UserPlus } from 'react-feather';

import UserContext from 'context/user';
import * as S from './styles';
import { RenderIf } from 'components/layout';
import { ModalAuth } from 'components/standalone';

const LoginWarning = () => {
	const [ isModalOpen, setIsModalOpen ] = useState(false);
	const { state: userState } = useContext(UserContext);

	return (
		<RenderIf condition={!userState.isLogged}>
			<S.LoginWarningWrapper>
				<S.LoginWarningIcon>
					<UserPlus size={24} color="#fff" />
				</S.LoginWarningIcon>

				<S.LoginWarningText>
					Atenção! Agora, para comprar seus ingressos, é necessário estar logado na sua conta! <br />
					<S.LoginWarningLink onClick={() => setIsModalOpen(true)}>Clique aqui para cadastrar ou entrar.</S.LoginWarningLink>
				</S.LoginWarningText>
			</S.LoginWarningWrapper>

			<ModalAuth
				hasCloseOption
				needAuth={!userState.isLogged}
				isModalOpen={isModalOpen}
				closeModalFn={() => setIsModalOpen(() => false)}
			/>
		</RenderIf>
	);
}

export default LoginWarning;
