import styled from 'styled-components';
import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';
import Variables from 'styles/Variables';

export const EventDataWrapper = styled.div`
	margin-top: 30px;
`;

export const EventName = styled.h1`
	font-weight: 700;
	margin-bottom: 20px;
	font-size: 1.5rem;

	${breakAt(BreakpointsSizes.lg)} {
		font-size: 2rem;
	}
`;

export const EventStatusBadge = styled.div`
	display: inline-block;
	font-size: 12px;
	padding: 4px 8px;
	border: 2px solid;
	border-radius: 15px;
	font-weight: 400;
	letter-spacing: 0.5px;
	color: #fff;
	white-space: nowrap;
	border-color: ${Variables.colors.info};
	background-color: ${Variables.colors.info};
	margin-bottom: 20px;
`;

export const TextCentralized = styled.div`
	margin-bottom: 15px;
	display: flex;
	align-items: center;
`;

export const DateWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;

	${breakAt(BreakpointsSizes.md)} {
		flex-direction: row;
	}
`;

export const Date = styled.div``;

export const DateSeparator = styled.div`
	${breakAt(BreakpointsSizes.md)} {
		height: 2px;
		width: 15px;
		background-color: #212121;
		opacity: 0.5;
		margin: 0 10px;
	}
`;

