import styled from 'styled-components';
import Variables from 'styles/Variables';

export const CardHeader = styled.div`
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
`;

export const CardTitle = styled.p`
	font-weight: 600;
	padding-left: 1rem;
	margin-bottom: 0.5rem;
	position: relative;

	&:before {
		background-color: ${Variables.backgrounds.main};
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		width: 0.375rem;
	}
`;

export const CardTitleSuccess = styled(CardTitle)`
	background: url("${process.env.PUBLIC_URL}/icons/icon-success-round.svg") no-repeat top left;
    background-size: 1.25rem;
	padding-left: 1.5rem;

	&:before {
		display: none;
	}
`;

export const ActionButton = styled.button`
	color: #5F5F5F;
	margin-left: 0.5rem;

	&:hover {
		color: ${Variables.colors.main};
	}
`;

export const ParticipantAdded = styled.div`
	align-items: center;
	border: 2px solid #399C54;
	border-radius: 0.5rem;
	color: #399C54;
	display: flex;
	font-size: 0.875rem;
    font-weight: 600;
	justify-content: center;
    letter-spacing: 0.5px;
	padding: 0.65rem;
	width: 100%;
`;

export const ParticipantAddedIcon = styled.span`
	background: url("${process.env.PUBLIC_URL}/icons/icon-success-round.svg") no-repeat top left;
    background-size: 1.25rem;
	display: inline-block;
    height: 1.25rem;
	padding-left: 2.5rem;
    width: 1.25rem;
`;

export const ParticipantAddedText = styled.span``;

export const RemoveParticipantText = styled.p`
	margin-bottom: 1rem;
`;
