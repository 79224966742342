import axios from 'axios';
import { eventApi } from './Api';

const salesApi = axios.create({
	baseURL: process.env.REACT_APP_SALES_URL,
});

salesApi.interceptors.request.use(config => {
	config.headers['Content-Type'] = 'application/json';
	config.headers['tenant_id'] = process.env.REACT_APP_TENANT_ID;
	return config;
});

const AccreditationService = (() => {
	function getCurrentStep(eventId: string, apiKey: string) {
		const headers = { 'x-api-key': apiKey };
		return salesApi.get(`/public/event/${eventId}/accreditation/step`, { headers });
	}

	function validateCpf(eventId: string, cpf: string) {
		const params = { cpf };
		return salesApi.post(`/public/event/${eventId}/accreditation/send-code`, {}, { params });
	}

	function validateCode(eventId: string, cpf: string, code: string) {
		const params = { cpf, code };
		return salesApi.post(`/public/event/${eventId}/accreditation/validate-code`, {}, { params });
	}

	function getReservationData(eventId: string, reservation: string, apiKey: string) {
		const headers = { 'x-api-key': apiKey };
		return salesApi.get(`/public/event/${eventId}/accreditation/${reservation}`, { headers });
	}

	function confirmReservation(eventId: string, reservation: string, apiKey: string) {
		const headers = { 'x-api-key': apiKey };
		return salesApi.post(`/public/event/${eventId}/accreditation/${reservation}/finish`, {}, { headers });
	}

	function sendImage(eventId: string, apiKey: string, payload: any) {
		const headers = { 'x-api-key': apiKey, 'Content-Type': 'multipart/form-data' };
		return eventApi.put(`/public/event/${eventId}/accreditation/face-match`, payload, { headers });
	}

	function addParticipant(eventId: string, reservation: string, apiKey: string, payload: any) {
		const headers = { 'x-api-key': apiKey };
		return salesApi.post(`/public/event/${eventId}/accreditation/${reservation}/attendee`, payload, { headers });
	}

	function removeParticipant(eventId: string, reservation: string, apiKey: string, id: string) {
		const headers = { 'x-api-key': apiKey };
		return salesApi.delete(`/public/event/${eventId}/accreditation/${reservation}/attendee/${id}`, { headers });
	}

	function sendParticipantsInvite(eventId: string, reservation: string, apiKey: string) {
		const headers = { 'x-api-key': apiKey };
		return salesApi.post(`/public/event/${eventId}/accreditation/${reservation}/attendee/send-invites`, {}, { headers });
	}

	return {
		getCurrentStep: getCurrentStep,
		validateCpf: validateCpf,
		validateCode: validateCode,
		getReservationData: getReservationData,
		confirmReservation: confirmReservation,
		sendImage: sendImage,
		addParticipant: addParticipant,
		removeParticipant: removeParticipant,
		sendParticipantsInvite: sendParticipantsInvite,
	}
})();

export default AccreditationService;
