import React from 'react';

import { CategoryProps } from './types';
import * as S from './styles';

const Category = ({ text, background, grayscale }: CategoryProps) => (
	<S.Root>
		<S.Icon background={background} grayscale={grayscale} />
		<S.Text>{text}</S.Text>
	</S.Root>
);

export default Category;
