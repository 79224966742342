import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Variables from 'styles/Variables';
import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';
import { StyleProps } from './types';

export const Root = styled(Link)`
	background: ${(props: StyleProps) => `url('${props.banner}')`}, ${Variables.colors.main};
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
    flex-direction: column;
	height: 30vw;
	margin: 20px 0 0;
	min-height: 200px;
	position: relative;
	overflow: hidden;
    justify-content: flex-end;
	user-select: none;
	width: 100%;

	&:before {
		background: linear-gradient(
			to top,
			rgba(0, 0, 0, 0.5) 0%,
			rgba(0, 0, 0, 0.3) 40%,
			transparent 100%
		);
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	${breakAt(BreakpointsSizes.exl)} {
		//height: 560px;
		margin: 50px 0 0;
		max-height: 560px;
	}
`;

export const EventTitle = styled.div`
	color: #fff;
	font-size: 1.25rem;
	font-weight: 700;
	letter-spacing: 0.5px;
	margin-left: auto;
	margin-right: auto;
	max-width: 1230px;
	padding: 0 20px;
	position: relative;
	width: 100%;
	z-index: 1;
`;

export const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	max-width: 1230px;
	margin: 0 auto;
	padding: 10px 20px;
	width: 100%;

	${breakAt(BreakpointsSizes.md)} {
		align-items: flex-end;
		flex-direction: row;
		justify-content: flex-start;
	}
`;

export const EventDateTime = styled.div`
	align-items: center;
	color: #fff;
	display: flex;
	font-size: 0.75rem;
	margin-bottom: 5px;
	position: relative;
	z-index: 1;
`;

export const EventAddress = styled.div`
	align-items: center;
	color: #fff;
	display: flex;
	font-size: 0.75rem;
	margin-bottom: 5px;
	position: relative;
	z-index: 1;
`;

export const Text = styled.span`
	color: #fff;
	padding: 0 10px;
	text-align: left;
`;
