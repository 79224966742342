import React, { useContext, useRef, useState } from 'react';
import Webcam from 'react-webcam';

import * as S from '../styles';
import { Button, ButtonOutline } from 'styles/Form';
import AccreditationService from 'services/AccreditationService';
import { createFile } from 'utils/appUtils';
import AccreditationContext from 'context/accreditation';
import { Bold } from 'styles/Commons';
import { Checkbox } from 'components/form';

const SendAccreditationImage = ({ event, onSend }: any) => {
	const { state } = useContext(AccreditationContext);
	const webcamRef = useRef(null) as any;
	const [previewImage, setPreviewImage] = useState('');
	const [hasCamera, setHasCamera] = useState(true);
	const [isSendingImage, setIsSendingImage] = useState(false);
	const [isTermsAccepted, setIsTermsAccepted] = useState(false);

	const takePhoto = () => {
		const imageSrc = webcamRef.current.getScreenshot();
		setPreviewImage(imageSrc);
	}

	const handleFile = (file: File) => {
		if (file) {
			const reader = new FileReader();
			reader.onload = (event: any) => setPreviewImage(event.target.result);
			reader.readAsDataURL(file);
		}
	}

	const sendImage = async () => {
		setIsSendingImage(true);
		const formData: any = new FormData();
		formData.append('file', await createFile(previewImage));
		AccreditationService.sendImage(event.id, state.apiKey, formData)
			.then(() => onSend())
			.finally(() => setIsSendingImage(false));
	}

	return (
		<>
			{hasCamera && (
				<>
					{previewImage && (<S.AccreditationImageTip>Confira sua selfie: verifique se é possível visualizar seu rosto. <Bold>Lembre-se que ela estará visível em seu ingresso.</Bold></S.AccreditationImageTip>)}
					<S.AccreditationImageContainer>
						{!previewImage && (
							<Webcam
								onUserMediaError={() => setHasCamera(false)}
								style={{width: '100%', maxWidth: '600px'}}
								mirrored={true}
								ref={webcamRef}
								screenshotFormat="image/jpeg"
							/>
						)}
						{previewImage && (
							<>
								<img src={previewImage} style={{width: '100%', maxWidth: '600px'}} />
							</>
						)}
					</S.AccreditationImageContainer>

					{!previewImage && <Button width="100%" onClick={takePhoto} margin="3rem 0 0">Tirar foto</Button>}
					{previewImage && (
						<>
							<S.AccreditationImageTerms>
								<Checkbox id="acceptTerms" onChangeFn={(isChecked: boolean) => setIsTermsAccepted(() => isChecked)} />
								<S.AccreditationImageTermsLabel htmlFor="acceptTerms">
									Concordo com a <S.TermsLink href={`${process.env.REACT_APP_FAQ_URL}/politicas-de-privacidade`} target="_blank">Política de Privacidade</S.TermsLink> e uso da minha imagem
								</S.AccreditationImageTermsLabel>
							</S.AccreditationImageTerms>
							<S.AccreditationImageActions>
								<ButtonOutline width="100%" onClick={() => setPreviewImage('')}>Tirar outra</ButtonOutline>
								<S.AccreditationImageActionSeparator />
								<Button width="100%" loading={isSendingImage} disabled={!isTermsAccepted} onClick={sendImage}>Usar foto</Button>
							</S.AccreditationImageActions>
						</>
					)}
				</>
			)}

			{!hasCamera && (
				<>
					{previewImage && (<S.AccreditationImageTip>Confira sua selfie: verifique se é possível visualizar seu rosto. <Bold>Lembre-se que ela estará visível em seu ingresso.</Bold></S.AccreditationImageTip>)}
					<S.AccreditationImageContainer>
						{!previewImage && <img src={`${process.env.PUBLIC_URL}/images/fallback-user.svg`} style={{width: '100%', maxWidth: '600px', maxHeight: '300px'}} />}
						{previewImage && (<img src={previewImage} style={{width: '100%', maxWidth: '600px'}} />)}
					</S.AccreditationImageContainer>

					{/* @ts-ignore */}
					<S.AccreditationImageInput id="userImage" type="file" onChange={($event) => handleFile($event.target.files[0])} />
						{!previewImage && <Button as="label" htmlFor="userImage" width="100%" margin="3rem 0 0">Selecionar foto</Button>}
						{previewImage && (
							<>
								<S.AccreditationImageTerms>
									<Checkbox id="acceptTerms" onChangeFn={(isChecked: boolean) => setIsTermsAccepted(() => isChecked)} />
									<S.AccreditationImageTermsLabel htmlFor="acceptTerms">
										Concordo com a <S.TermsLink href={`${process.env.REACT_APP_FAQ_URL}/politicas-de-privacidade`} target="_blank">Política de Privacidade</S.TermsLink> e uso da minha imagem
									</S.AccreditationImageTermsLabel>
								</S.AccreditationImageTerms>
								<S.AccreditationImageActions>
									<ButtonOutline as="label" htmlFor="userImage" width="100%">Selecionar outra</ButtonOutline>
									<S.AccreditationImageActionSeparator />
									<Button width="100%" loading={isSendingImage} disabled={!isTermsAccepted} onClick={sendImage}>Usar foto</Button>
								</S.AccreditationImageActions>
							</>
						)}
				</>
			)}
		</>
	);
}

export default SendAccreditationImage;
