import styled from 'styled-components';

export const Root = styled.div`
	padding: 30px 0;
`;

export const SearchTerm = styled.div`
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 30px;
`;

export const EventsFound = styled.div`
	font-weight: bold;
	opacity: 0.5;
`;
