import styled from 'styled-components';
import Variables from 'styles/Variables';
import { StyleProps } from './types';

export const Title = styled.h1`
	display: block;
	margin-bottom: ${({ marginBottom }: StyleProps) => `${marginBottom || 0}px`};
	margin-top: ${({ marginTop }: StyleProps) => `${marginTop || 0}px`};
	text-align: ${({ align }: StyleProps) => align || 'left'};
	color: ${({ color }: StyleProps) => color || Variables.colors.main};
	font-size: ${({ size }: StyleProps) => `${size || 16}px`};
	text-shadow: ${({ shadow }: StyleProps) => shadow ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none'};
	font-weight: ${({ weight }: StyleProps) => `${weight || 400}`};

`;
