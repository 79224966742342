import { CategoriesTypes, Category } from './types';

export const getCategories = () => ({
	type: CategoriesTypes.GET_CATEGORIES,
});

export const getCategoriesSuccess = (categories: Category[]) => ({
	type: CategoriesTypes.GET_CATEGORIES_SUCCESS,
	payload: { categories },
});

export const getCategoriesFailure = () => ({
	type: CategoriesTypes.GET_CATEGORIES_FAILURE
});
