// ACTION TYPES
export enum TicketsTypes {
	GET_TICKETS = '@tickets/GET_TICKETS',
	GET_TICKETS_ACTIVE_SUCCESS = '@tickets/GET_TICKETS_ACTIVE_SUCCESS',
	GET_TICKETS_ACTIVE_FAILURE = '@tickets/GET_TICKETS_ACTIVE_FAILURE',
	GET_TICKETS_CLOSED_SUCCESS = '@tickets/GET_TICKETS_CLOSED_SUCCESS',
	GET_TICKETS_CLOSED_FAILURE = '@tickets/GET_TICKETS_CLOSED_FAILURE',
	DOWNLOAD_ALL_TICKETS = '@tickets/DOWNLOAD_ALL_TICKETS',
	DOWNLOAD_UNIQUE_TICKET = '@tickets/DOWNLOAD_UNIQUE_TICKET',
}

//DATA TYPES
export interface Ticket {
	attendeeCode: string,
	attendeeStatus: string,
	paymentStatus: string,
	purchaseStatus: string,
	total: number,
	event: TicketEvent,
}

export interface TicketEvent {
	name: string,
	url: string,
	startDate: string,
	endDate: string,
	thumbnail: string,
	status: string,
	locationType: string,
	fullAddress: string,
}

// STATE TYPE
export interface TicketsState {
	readonly activeTickets: Ticket[];
	readonly closedTickets: Ticket[];
	readonly activeLoading: boolean;
	readonly activeError: boolean;
	readonly hasMoreActive: boolean;
	readonly closedLoading: boolean;
	readonly closedError: boolean;
	readonly hasMoreClosed: boolean;
}
