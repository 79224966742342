import styled from 'styled-components';
import { BreakpointsSizes, breakAt } from 'styles/Breakpoint';
import Variables from 'styles/Variables';
import { StyleProps } from './types';

export const OrganizationInfoWrapper = styled.section`
	display: flex;
	margin: 25px 0;
`;

export const OrganizationProfilePicture = styled.div`
	height: 70px;
	min-width: 70px;
	border-radius: 50%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-image: url(${({ imageProfile }: StyleProps) => imageProfile || `${process.env.PUBLIC_URL}/images/fallback-user.svg`});
	box-shadow: ${Variables.boxShadows.level2};

	${breakAt(BreakpointsSizes.md)} {
		height: 120px;
		width: 120px;
	}
`;

export const OrganizationProfileInfo = styled.div`
	flex-grow: 1;
	margin-left: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	${breakAt(BreakpointsSizes.md)} {
		margin-left: 25px;
	}
`;

export const OrganizationName = styled.h1`
	font-weight: 700;
    margin-bottom: 10px;
    font-size: 1.5rem;

	${breakAt(BreakpointsSizes.md)} {
		font-size: 2rem;
	}
`;

export const OrganizationSocialList = styled.div`
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(auto-fit, minmax(35px, 35px));
`;

export const OrganizationLink = styled.a`
	height: 35px;
	min-width: 35px;
	border-radius: 50%;
	background-image: url(${process.env.PUBLIC_URL}/images/social-links.jpeg);
	background-repeat: no-repeat;
	background-position: 0;
	background-size: cover;
	margin-right: 15px;

	&[data-type="FACEBOOK"]{background-position: 0;}
	&[data-type="LINKEDIN"]{background-position: calc(35px * -1)};
	&[data-type="YOUTUBE"]{background-position: calc(35px * -2)};
	&[data-type="PINTEREST"]{background-position: calc(35px * -3)};
	&[data-type="TWITTER"]{background-position: calc(35px * -4)};
	&[data-type="WEBSITE"]{background-position: calc(35px * -5)};
`;
