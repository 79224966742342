// ACTION TYPES
export enum CategoriesTypes {
	GET_CATEGORIES = '@categories/GET_CATEGORIES',
	GET_CATEGORIES_SUCCESS = '@categories/GET_CATEGORIES_SUCCESS',
	GET_CATEGORIES_FAILURE = '@categories/GET_CATEGORIES_FAILURE',
}

export interface Category {
	id: number,
	name: string,
	imgUrl: string,
}

// STATE TYPE
export interface CategoriesState {
	readonly categoryList: Array<Category>,
	readonly loading: boolean,
	readonly error: boolean,
}
