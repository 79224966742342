import { format } from 'date-fns';

export const serverFormat = "yyyy-MM-dd'T'HH:mm:ss";
export const frontFormat = 'dd/MM/yyyy HH:mm';

/* Ex. yyyy-MM-dd -> yyyy/MM/dd */
export function dateGlobalPattern(date: string): string {
	return date.replace(/-/g, '/').replace('T', ' ');
}

/* Ex. dd/MM/yyyy hh:mm -> yyyy-MM-ddThh:mm */
export function toServerDate(date: string): string {
	const day = date.substring(0, 2);
	const month = date.substring(3, 5);
	const year = date.substring(6, 10);
	const time = date.substring(13);

	return `${year}-${month}-${day}T${time}:00`;
}

export function getFrontDate(date: string): string {
	return format(new Date(dateGlobalPattern(date)), frontFormat);
}
