import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import * as S from '../styles';
import { InputWrapper, Label, Button, ButtonOutline, MaskInput } from 'styles/Form';
import { Card } from 'styles/Commons';
import { isValidCpf } from 'utils/appUtils';
import AccreditationService from 'services/AccreditationService';

const ValidateAccreditationCpf = ({ event, onValidate }: any) => {
	const supportNumber = process.env.REACT_APP_SUPPORT_WHATSAPP || null;
	const [cpf, setCpf] = useState({ value: '', hasError: false });
	const [error, setError] = useState({ hasError: false, errorType: '' });
	const [isValidating, setIsValidating] = useState(false);

	const changeCpf = (ev: any) => {
		ev.persist();

		const cleanValue = ev.target.value.replace(/[^0-9]/g, '').substring(0, 11);
		setCpf(() => ({
			hasError: cleanValue.length > 0 && !isValidCpf(cleanValue),
			value: cleanValue
		}));
	}

	const validateCpf = () => {
		if (!cpf.hasError) {
			setIsValidating(true);
			AccreditationService.validateCpf(event.id, cpf.value)
				.then(({ data }) => onValidate({ cpf: cpf.value, email: data.email, phone: data.phone }))
				.catch(({ response }: any) => setError(() => ({ hasError: true, errorType: response.data.errorType })))
				.finally(() => setIsValidating(false));
		}
	}

	return (
		<Card>
			<S.AccreditationCardEventBanner image={event.banner} />
			<S.AccreditationCardTitle>{event.name}</S.AccreditationCardTitle>
			{error.hasError ? (
				<>
					{error.errorType === 'accreditation_failure' && (
						<>
							<S.AccreditationCardText>Ops, não encontramos o CPF no cadastro de credenciados!</S.AccreditationCardText>
							<S.AccreditationCardText>Por isso, não é possível prosseguir com o seu credenciamento.</S.AccreditationCardText>

							<Button as={Link} to={`/${event.url}`} width="100%" margin="7rem 0 1rem">Voltar para o evento</Button>
							<ButtonOutline width="100%" margin="0 0 1rem" onClick={() => setError(() => ({ hasError: false, errorType: '' }))}>Tentar novamente</ButtonOutline>
						</>
					)}

					{error.errorType === 'accreditation_already_purchase_failure' && (
						<>
							<S.AccreditationCardText>O CPF já encontra-se credenciado para o evento {event.name}.</S.AccreditationCardText>
							<S.AccreditationCardText>Obtenha seus ingressos pelo login ou pelo email/telefone cadastrado.</S.AccreditationCardText>

							<Button as={Link} to={`/${event.url}`} width="100%" margin="7rem 0 1rem">Voltar para o evento</Button>
						</>
					)}

					<S.AccreditationCardTextLight>Caso não utilize o email e telefone acima, entre em contato com o suporte pelo número {supportNumber}.</S.AccreditationCardTextLight>
				</>
			) : (
				<>
					<S.AccreditationCardText>Para participar do evento preencha seu CPF abaixo:</S.AccreditationCardText>

					<InputWrapper>
						<Label>CPF</Label>
						<MaskInput id="cpf" mask="999.999.999-99" value={cpf.value} maskPlaceholder={null} onChange={changeCpf} />
					</InputWrapper>

					<Button width="100%" margin="7rem 0 0" loading={isValidating} disabled={cpf.hasError || !cpf.value || isValidating} onClick={validateCpf}>Continuar</Button>
				</>
			)}
		</Card>
	);
};

export default ValidateAccreditationCpf;
