import React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const Wrapper = styled.div`
	height: ${isMobile ? '360px' : '560px'};
	margin-top: 50px;
	width: 100%;
	background-image: url(${process.env.REACT_APP_BUCKET_URL}/images/banner-home-fallback.jpg);
	background-position: center;
	background-size: cover;
`;

const BannerFallback = () => (
	<Wrapper />
);

export default BannerFallback;
