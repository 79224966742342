// ACTION TYPES
export enum CheckoutTypes {
	LOAD_SUCCESS = '@checkout/LOAD_SUCCESS',
	LOAD_FAILURE = '@checkout/LOAD_FAILURE',
}

// STATE TYPE
export interface CheckoutState {
	readonly loading: boolean;
	readonly error: boolean;
}
