import styled from 'styled-components';
import Variables from 'styles/Variables';

type AccreditationStyleProps = {
	image?: string;
}
export const AccreditationCardEventBanner = styled.div`
	background: url(${({ image }: AccreditationStyleProps) => image || '' }) no-repeat center;
	background-size: cover;
	border-radius: 1rem;
	height: 150px;
	margin-bottom: 1rem;
	width: 100%;
`;

export const AccreditationCardTitle = styled.h4`
	font-weight: 600;
	margin-bottom: 1rem;
`;

export const AccreditationPhotoTitle = styled.p`
	background: url("${process.env.PUBLIC_URL}/icons/icon-camera.svg") no-repeat center left;
    background-size: 1.15rem;
    border-top: 1px solid #BDBDBD;
    font-weight: 600;
	padding: 1rem 0 1rem 1.75rem;
`;

export const AccreditationCardText = styled.p`
	margin-bottom: 1rem;
`;

export const AccreditationCardTextLight = styled.p`
	color: #BDBDBD;
	margin-bottom: 1rem;
`;

export const AccreditationResendCodeWrapper = styled.div`
	font-size: 0.875rem;
`;

export const AccreditationResendCodeText = styled.span``;

export const AccreditationResendCodeLink = styled.a`
	color: #4598CF;
	cursor: pointer;
`;

export const AccreditationCardList = styled.ul``;

export const AccreditationCardListItem = styled.li`
	list-style: disc;
	margin-bottom: 1rem;
	margin-left: 1.5rem;
`;

export const AccreditationImageContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const AccreditationImageTip = styled.p`
	color: #5F5F5F;
	font-size: 0.875rem;
	margin-bottom: 1rem;
`;

export const AccreditationImageInput = styled.input`
	display: none;
`;

export const AccreditationImageActions = styled.div`
	display: flex;
`;

export const AccreditationImageTerms = styled.div`
	align-items: flex-start;
	display: flex;
	margin: 3rem 0 1rem;
	width: 100%;
`;

export const AccreditationImageTermsLabel = styled.label`
	font-size: 0.75rem;
`;

export const TermsLink = styled.a`
	color: ${Variables.colors.main};
	font-weight: 600;
`;

export const AccreditationImageActionSeparator = styled.div`
	min-width: 1rem;
`;

export const EventInfo = styled.div`
	margin-bottom: 1.5rem;
`;

export const EventName = styled.p`
	font-size: 1.125rem;
	font-weight: 700;
	margin-bottom: 0.75rem;
`;

export const EventTextWrapper = styled.div`
	align-items: flex-start;
	display: flex;
	margin-bottom: 0.5rem;
`;

export const EventText = styled.p`
	margin-left: 0.5rem;
`;

export const CardHeader = styled.div`
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
`;

export const CardTitle = styled.p`
	font-weight: 600;
	padding-left: 1rem;
	margin-bottom: 0.5rem;
	position: relative;

	&:before {
		background-color: ${Variables.backgrounds.main};
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		width: 0.375rem;
	}
`;

export const AccreditationResume = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const AccreditationResumeTitle = styled.div`
	font-size: 0.875rem;
	font-weight: 600;
	padding-bottom: 0.25rem;
`;

export const AccreditationResumeItem = styled.div`
	font-size: 0.875rem;
	padding-bottom: 0.25rem;
`;

export const AccreditationResumeTotal = styled.div`
	border-top: 1px solid #BDBDBD;
	color: ${Variables.colors.main};
	font-size: 0.875rem;
	font-weight: 600;
	padding-top: 0.5rem;
	text-align: right;
`;

export const InviteParticipantActions = styled.div`
    display: grid;
    grid-gap: 1rem;
    margin: 0 0 3.5rem;
`;

export const InviteParticipantFeedbackWrapper = styled.div`
    margin: 5rem 0;
`;

export const FeedbackIcon = styled.div`
    background: url("${process.env.PUBLIC_URL}/icons/icon-success-round.svg") no-repeat center;
    height: 2.25rem;
    margin: 0 auto 1.25rem;
    width: 2.25rem;
`;

export const FeedbackTitle = styled.h4`
    color: #5F5F5F;
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
    text-align: center;
`;

export const FeedbackMessage = styled.p`
    color: #5F5F5F;
    text-align: center;
`;
