import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { PhoneRegister, RegularRegister, Seo } from 'components/standalone';
import AuthService from 'services/AuthService';
import { RenderIf } from 'components/layout';
import AnalyticsService from 'services/AnalyticsService';

const RegisterContainer = styled.div`
	margin: 2rem auto;
	max-width: 600px;
	padding: 0 1rem;
	width: 100%;
`;

const RegisterPage = () => {
	const history: any = useHistory();

	useEffect(() => {
		if (AuthService.isAuthenticated() && AuthService.readUserData()) {
			history.push({ pathname: '/' });
		}
	}, []);

	const registerSuccess = (type: string) => {
		AnalyticsService.sendRegisterEvent(type);
		history.push({ pathname: '/entrar' });
	}

	return (
		<>
			<Seo title={`${process.env.REACT_APP_NAME} - Registrar`} />
			<RegisterContainer>
				<RenderIf condition={process.env.REACT_APP_AUTH_TYPE === 'EMAIL'}>
					<RegularRegister showBackButton={false} registerSuccessFn={registerSuccess} />
				</RenderIf>

				<RenderIf condition={process.env.REACT_APP_AUTH_TYPE === 'PHONE'}>
					<PhoneRegister showBackButton={false} registerSuccessFn={registerSuccess} />
				</RenderIf>
			</RegisterContainer>
		</>
	);
}

export default RegisterPage;
