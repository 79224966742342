import React, { useEffect, useState } from 'react';

import * as S from './styles';

function Timer({ time, endTimeFn }: any) {
	const [remainTime, setRemainTime] = useState(time);
	const [counter, setCounter] = useState<any>(null);

	const addLeftZero = (rawValue: string | number) => {
		const value = rawValue.toString();
		return value.length === 1 ? `0${value}` : value;
	};

	const convertTime = () => {
		const hours = Math.floor(remainTime / 3600);
		const minutes = Math.floor((remainTime % 3600) / 60);
		const seconds = (remainTime % 3600) % 60;
		return `${addLeftZero(hours)}:${addLeftZero(minutes)}:${addLeftZero(seconds)}`
	}

	useEffect(() => {
		setCounter(setInterval(() => {
			setRemainTime((prevState: any) => prevState - 1);
		}, 1000));
	}, [])

	useEffect(() => {
		if (remainTime <= 0) {
			clearInterval(counter);
			endTimeFn();
		}
	}, [remainTime, counter, endTimeFn])

	useEffect(() => {
		setRemainTime(time);
	}, [time])

	return (
		<S.Timer>{convertTime()}</S.Timer>
	);
};

export default Timer;
