import React from 'react';

import * as S from './styles';
import { SearchStatusProps } from './types';

const SearchStatus = ({ searchTerm, eventsFound }: SearchStatusProps) => (
	<S.Root>
		{searchTerm && (<S.SearchTerm>"{searchTerm}"</S.SearchTerm>)}
		<S.EventsFound data-test="SearchResultText">{eventsFound > 0 ? `${eventsFound} Evento(s) encontrado(s)` : 'Nenhum evento encontrado'}</S.EventsFound>
	</S.Root>
);

export default SearchStatus;
