import styled from 'styled-components';
import Variables from 'styles/Variables';

type ResendCodeProps = {
	disabled?: boolean;
	displayOptions?: boolean;
}

export const Wrapper = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 1.5rem 1rem;
`;

export const Timer = styled.span`
	font-size: 0.875rem;
	padding: 0 0.5rem;
	text-align: center;
	width: 60px;
`;

export const Text = styled.a`
	color: ${Variables.colors.main};
	cursor: pointer;
	font-size: 0.875rem;
	text-decoration: underline;
	${({ disabled }: ResendCodeProps) => disabled && `
		cursor: not-allowed;
		filter: brightness(0);
		opacity: 0.25;
		pointer-events: none;
	`}
`;

export const OptionsWrapper = styled.div`
	background-color: rgba(0, 0, 0, 0.25);
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;

	z-index: 10000;

	${({ displayOptions }: ResendCodeProps) => displayOptions && `
		top: 0;
	`}
`;

export const OptionsBox = styled.div`
	background-color: #fff;
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
	display: flex;
    flex-direction: column;
	left: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	transition: 0.3s ease;
	z-index: 10001;

	@media(max-width: 799px) {
		bottom: 0;
		max-height: 0;

		${({ displayOptions }: ResendCodeProps) => displayOptions && `
			max-height: 150px;
			padding: 0.5rem;
		`}
	}

	@media(min-width: 800px){
		border-radius: 0.5rem;
		margin: auto;
		max-width: 400px;
		padding: 0.5rem;
		top: 5rem;
	}
`;

export const OptionItem = styled.button`
	border-radius: 0.5rem;
	display: block;
	padding: 0.5rem;
	text-align: center;

	&:hover {
		background-color: #f3f3f3;
	}
`;
