import React, { useEffect, useRef, useState } from 'react';

import Select from 'react-select';

import * as S from './styles';
import { Modal } from 'components/layout';
import UserService from 'services/UserService';
import { Button, Input, InputErrorMessage, InputWrapper, Label, MaskInput } from 'styles/Form';
import { GENDER, STATE } from 'config/enums';
import AddressService from 'services/AddressService';
import { transformDate } from 'utils/appUtils';
import { toast } from 'react-toastify';

const CompleteUserForm = ({ isOpen, showClose = true, closeFn, successFn }: any) => {
	const stateSelectRef = useRef(null);
	const citySelectRef = useRef(null);
	const [genderList, setGenderList] = useState<any>([]);
	const [stateList, setStateList] = useState<any>([]);
	const [cityList, setCityList] = useState<any>([]);
	const [birthDate, setBirthDate] = useState({ value: '', hasError: false });
	const [gender, setGender] = useState({ value: '', hasError: false });
	const [document, setDocument] = useState({ value: '', hasError: false });
	const [zipcode, setZipcode] = useState({ value: '', hasError: false });
	const [street, setStreet] = useState({ value: '', hasError: false, disabled: false });
	const [number, setNumber] = useState({ value: '', hasError: false });
	const [complement, setComplement] = useState('');
	const [neighborhood, setNeighborhood] = useState({ value: '', hasError: false, disabled: false });
	const [state, setState] = useState({ value: '', hasError: false });
	const [city, setCity] = useState({ value: '' as any, hasError: false });
	const [isGettingCities, setIsGettingCities] = useState(false);

	const [isStateDisabled, setIsStateDisabled] = useState(false);
	const [isCityDisabled, setIsCityDisabled] = useState(false);

	const [formValid, setFormValid] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const selectStyle = {
		control: (baseStyles: any) => ({
			...baseStyles,
			borderColor: '#E0E0E0',
			borderRadius: '0.5rem',
			minHeight: '44px'
		}),
		menuPortal: (provided: any) => ({
			...provided,
			zIndex: 100
		})
	}

	useEffect(() => {
		getUserDetails();
		getStateList();
		getGenderList();
	}, []);

	useEffect(() => {
		if ((!birthDate.value || birthDate.hasError) ||
			(!gender.value || gender.hasError) ||
			(!zipcode.value || zipcode.hasError) ||
			(!street.value || street.hasError) ||
			(!number.value || number.hasError) ||
			(!neighborhood.value || neighborhood.hasError) ||
			(!state.value || state.hasError) ||
			(!city.value || city.hasError)) {
				setFormValid(false);
		} else {
			setFormValid(true);
		}
	}, [birthDate, gender, zipcode, street, number, neighborhood, state, city]);

	const getUserDetails = () => {
		UserService.getUserDetails()
			.then(({ data }) => {
				// setBirthDate({ value: '', hasError: false });
				setGender({ value: data.gender, hasError: false });
				setDocument({ value: data.document, hasError: false });
				setZipcode({ value: data.address?.zipcode, hasError: false });
				setStreet({ value: data.address?.street, hasError: false, disabled: false });
				setNumber({ value: data.address?.number, hasError: false });
				setComplement(data.address?.complement);
				setNeighborhood({ value: data.address?.neighborhood, hasError: false, disabled: false });
				setState({ value: data.address?.state, hasError: false });
			})
	}

	const getStateList = () => {
		setStateList(Object.keys(STATE).map((state: string) => ({ label: state, value: state })))
	}

	const getGenderList = () => {
		setGenderList([
			{ label: 'Masculino', value: GENDER.MALE },
			{ label: 'Feminino', value: GENDER.FEMALE },
			{ label: 'Outros', value: GENDER.OTHERS },
			{ label: 'Prefiro não responder', value: GENDER.NO_ANSWER },
		])
	}

	const changeDocument = (ev: any) => {
		ev.persist();

		const cleanValue = ev.target.value.replace(/\D/g,'').substring(0, 11);
		setDocument(() => ({
			hasError: cleanValue.length === 0 || cleanValue.length < 11,
			value: cleanValue
		}));
	}

	const changeBirthDate = (ev: any) => {
		ev.persist();
		const value = ev.target.value;

		setBirthDate(() => ({
			hasError: value.length < 10,
			value: value
		}));
	}

	const changeGender = (ev: any) => {
		setGender(() => ({
			hasError: !ev,
			value: ev.value
		}));
	}

	const changeZipCode = (ev: any) => {
		ev.persist();
		const value = ev.target.value;

		if (value.length === 9) {
			const zipCode = value.replace(/[^0-9]/g, '');
			getAddressByZipCode(zipCode);
		} else {
			setNeighborhood((old) => ({ ...old, disabled: false }));
			setStreet((old) => ({ ...old, disabled: false }));
			setIsStateDisabled(false);
			setIsCityDisabled(false);
		}

		setZipcode(() => ({
			hasError: value.length < 9,
			value: value
		}));
	}

	const getAddressByZipCode = (zipCode: string) => {
		AddressService.getAddressByZipCode(zipCode)
			.then(({ data }) => {
				if (!('erro' in data)) {
					setNeighborhood(() => ({ hasError: false, value: data.bairro, disabled: true }));
					setState({ hasError: false, value: data.uf });
					setIsStateDisabled(true);
					//@ts-ignore
					stateSelectRef.current.setValue({ label: data.uf, value: data.uf, disabled: true });
					setStreet({ hasError: false, value: data.logradouro, disabled: true });

					getUniqueCity(data.uf, data.localidade);
				}
			})
	}

	const changeStreet = (ev: any) => {
		ev.persist();
		const value = ev.target.value;

		setStreet(() => ({
			hasError: value.length === 0,
			value: value,
			disabled: false
		}));
	}

	const changeNumber = (ev: any) => {
		ev.persist();
		const value = ev.target.value;

		setNumber(() => ({
			hasError: value.length === 0,
			value: value
		}));
	}

	const changeNeighborhood = (ev: any) => {
		ev.persist();
		const value = ev.target.value;

		setNeighborhood(() => ({
			hasError: value.length === 0,
			value: value,
			disabled: false
		}));
	}

	const changeState = (ev: any) => {
		getCities(ev.value);
		setState(() => ({
			hasError: !ev,
			value: ev.value,
			disabled: false
		}));
	}

	const getCities = (state: string, cityName: string = '') => {
		setIsGettingCities(true);
		const params = { state, query: cityName }
		AddressService.getCities(params)
			.then(({ data }) => {
				setCityList(() => data.content.map((city: any) => ({ label: city.name, value: city.id })));
			})
			.finally(() => setIsGettingCities(false))
	}

	const getUniqueCity = (state: string, cityName: string) => {
		const params = { state, query: cityName }
		AddressService.getCities(params)
			.then(({ data }) => {
				let option = { label: '', value: null };
				if (data.content && data.content.length > 0) {
					const optionSelected = data.content.find((city: any) => city.name == params.query)
					option = { label: optionSelected.name, value: optionSelected.id};
				}
				setCityList([option]);
				//@ts-ignore
				citySelectRef.current.setValue(option);
				setIsCityDisabled(true);
			});
	}

	const changeCity = (ev: any) => {
		setCity(() => ({
			hasError: !ev,
			value: ev,
			disabled: false
		}));
	}

	const save = () => {
		setIsSaving(true);
		const payload = {
			document: document.value,
			// phone: '',
			birthDate: transformDate(birthDate.value),
			gender: gender.value,
			address: {
				zipcode: zipcode.value.replace(/[^0-9]/g, ''),
				street: street.value,
				number: number.value,
				neighborhood: neighborhood.value,
				state: state.value,
				cityId: city.value.value
			}
		}

		UserService.updateUserData(payload)
			.then(({ data }) => {
				console.log(data)
				successFn();
			})
			.catch(({ data }: any) => toast(data.message, { type: toast.TYPE.ERROR }))
			.finally(() => setIsSaving(false))
	}

	const searchCities = (inputValue: string) => {
		if (inputValue.length >= 3) {
			getCities(state.value, inputValue);
		}
	}

	const validateDisableField = (field: string): boolean => {
		return (!!zipcode.value) || (zipcode.value != null && field == null)
	}

	return (
		<Modal isOpen={isOpen} showHeader={true} showClose={showClose} title="Complete seu cadastro" maxWidth="800px" onClose={closeFn}>
			<S.CompleteUserFormContent>
				<S.CompleteUserRow>
					<InputWrapper hasError={birthDate.hasError}>
						<Label data-required>Data de nascimento</Label>
						<MaskInput id="birthDate" mask="99/99/9999" value={birthDate.value} maskPlaceholder={null} onChange={changeBirthDate} />
						<InputErrorMessage>Insira sua data de nascimento</InputErrorMessage>
					</InputWrapper>

					<InputWrapper hasError={gender.hasError}>
						<Label data-required>Gênero</Label>
						<Select
							defaultValue={gender.value}
							onChange={changeGender}
							options={genderList}
							placeholder=""
							styles={selectStyle}
						/>
						<InputErrorMessage>Selecione seu gênero</InputErrorMessage>
					</InputWrapper>
				</S.CompleteUserRow>

				<S.CompleteUserRow>
					<InputWrapper hasError={document.hasError}>
						<Label>CPF</Label>
						<MaskInput id="document" mask="999.999.999-99" value={document.value} maskPlaceholder={null} onChange={changeDocument} />
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>

					<InputWrapper hasError={zipcode.hasError}>
						<Label data-required>CEP</Label>
						<MaskInput id="zipcode" mask="99999-999" value={zipcode.value} maskPlaceholder={null} onChange={changeZipCode} />
						<InputErrorMessage>Insira um CEP válido</InputErrorMessage>
					</InputWrapper>
				</S.CompleteUserRow>

				<InputWrapper hasError={street.hasError}>
					<Label data-required>Rua</Label>
					<Input id="street" value={street.value} disabled={false} onChange={changeStreet} />
					<InputErrorMessage>Insira sua rua</InputErrorMessage>
				</InputWrapper>

				<S.CompleteUserRow>
					<InputWrapper hasError={number.hasError}>
						<Label data-required>Número</Label>
						<MaskInput id="number" mask="99999" value={number.value} maskPlaceholder={null} onChange={changeNumber} />
						<InputErrorMessage>Insira o número do endereço</InputErrorMessage>
					</InputWrapper>

					<InputWrapper>
						<Label>Complemento</Label>
						<Input id="complement" value={complement} onChange={($event) => setComplement($event.target.value)} />
					</InputWrapper>
				</S.CompleteUserRow>

				<InputWrapper hasError={neighborhood.hasError}>
					<Label data-required>Bairro</Label>
					<Input id="neighborhood" value={neighborhood.value} disabled={!validateDisableField(neighborhood.value)} onChange={changeNeighborhood} />
					<InputErrorMessage>Insira seu bairro</InputErrorMessage>
				</InputWrapper>

				<S.CompleteUserRow>
					<InputWrapper hasError={state.hasError}>
						<Label data-required>Estado</Label>
						<Select
							ref={stateSelectRef}
							defaultValue={state.value}
							onChange={changeState}
							options={stateList}
							isDisabled={isStateDisabled}
							noOptionsMessage={() => 'Nenhum estado encontrado'}
							maxMenuHeight={100}
							placeholder=""
							styles={selectStyle}
						/>
						<InputErrorMessage>Selecione seu estado</InputErrorMessage>
					</InputWrapper>

					<InputWrapper hasError={city.hasError}>
						<Label data-required>Cidade</Label>
						<Select
							ref={citySelectRef}
							defaultValue={city.value}
							onChange={changeCity}
							onInputChange={searchCities}
							options={cityList}
							placeholder=""
							noOptionsMessage={() => 'Nenhuma cidade encontrada'}
							isDisabled={!state.value || isCityDisabled}
							isLoading={isGettingCities}
							loadingMessage={() => 'Buscando cidades'}
							maxMenuHeight={100}
							styles={selectStyle}
						/>
						<InputErrorMessage>Selecione sua cidade</InputErrorMessage>
					</InputWrapper>
				</S.CompleteUserRow>

				<S.CompleteUserFormButtonWrapper>
					<Button
						width="200px"
						margin="1rem 0 0"
						disabled={!formValid || isSaving}
						loading={isSaving}
						onClick={save}>Salvar</Button>
				</S.CompleteUserFormButtonWrapper>
			</S.CompleteUserFormContent>
		</Modal>
	)
}

export default CompleteUserForm;
