import { LinkButton } from 'components/standalone';
import React from 'react';

import * as S from './styles';

const CollaboratorInviteResponse = ({error, message}: any) => (
	<S.InviteResponseWrapper >
		<S.InviteResponseImage image={error ? 'image-warning.svg' : 'image-success.svg'} />
		<S.InviteMessage>{message}</S.InviteMessage>
		<LinkButton to="/">Ir para Home</LinkButton>
	</S.InviteResponseWrapper>
);

export default CollaboratorInviteResponse;
