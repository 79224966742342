import React, { useContext, useEffect, useState } from 'react';
import { Calendar, MapPin } from 'react-feather';

import { IconWrapper } from 'styles';
import * as S from '../styles';
import { Input, InputWrapper, Label, Button, MaskInput } from 'styles/Form';
import { Card } from 'styles/Commons';
import AccreditationService from 'services/AccreditationService';
import { getFrontDate } from 'utils/dateUtils';
import { formatCurrency } from 'utils/currencyUtils';
import { Loading } from 'components/standalone';
import AccreditationContext from 'context/accreditation';

const ValidateAccreditationUserData = ({ event, onValidate }: any) => {
	const { state } = useContext(AccreditationContext);
	const [userData, setUserData] = useState({ purchaseTicket: { name: '', totalPrice: '' }, category: { guestQuantity: 0, name: '' }, purchaseCode: '', userFirstName: '', userEmail: '', userPhone: '', userCpf: '' });
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		getReservationData();
	}, []);

	const getReservationData = () => {
		AccreditationService.getReservationData(event.id, state.reservation, state.apiKey)
			.then(({ data }) => setUserData(() => ({ ... data })))
			.finally(() => setIsLoading(false))
	}

	const confirmReservation = () => {
		AccreditationService.confirmReservation(event.id, state.reservation, state.apiKey)
			.then(() => onValidate({ purchaseCode: userData.purchaseCode, guestQuantity: userData.category?.guestQuantity ?? 0 }));
	}

	return (
		<>
			{isLoading
				? <Loading height='200px' />
				: (
					<>
						<S.EventInfo>
							<S.EventName>{event.name}</S.EventName>
								<S.EventTextWrapper>
									<IconWrapper height={18} width={18}>
										<MapPin size={18} color="gray" />
									</IconWrapper>
									<S.EventText>{getFrontDate(event.startDate)}</S.EventText>
								</S.EventTextWrapper>

								<S.EventTextWrapper>
									<IconWrapper height={18} width={18}>
										<Calendar size={18} color="gray" />
									</IconWrapper>
									<S.EventText>{event.fullAddress || 'Virtual'}</S.EventText>
								</S.EventTextWrapper>
						</S.EventInfo>

						<Card padding="1.5rem" margin="0 0 1.5rem">
							<S.CardHeader>
								<S.CardTitle>Resumo da compra</S.CardTitle>
							</S.CardHeader>

							<S.AccreditationResume>
								<S.AccreditationResumeTitle>Ingresso</S.AccreditationResumeTitle>
								<S.AccreditationResumeTitle>Preço</S.AccreditationResumeTitle>
							</S.AccreditationResume>

							<S.AccreditationResume>
								<S.AccreditationResumeItem>{userData.purchaseTicket.name}</S.AccreditationResumeItem>
								<S.AccreditationResumeItem style={{whiteSpace: 'nowrap', paddingLeft: '0.5rem'}}>{formatCurrency(userData.purchaseTicket.totalPrice)}</S.AccreditationResumeItem>
							</S.AccreditationResume>

							<S.AccreditationResumeTotal>Total {formatCurrency(userData.purchaseTicket.totalPrice)}</S.AccreditationResumeTotal>
						</Card>

						<Card padding="1.5rem" margin="0 0 1.5rem">
							<S.CardHeader>
								<S.CardTitle>Dados do participante</S.CardTitle>
							</S.CardHeader>

							<InputWrapper>
								<Label>Nome completo</Label>
								<Input id="userFIrstName" value={userData.userFirstName} disabled />
							</InputWrapper>

							<InputWrapper>
								<Label>CPF</Label>
								<MaskInput id="userCpf" mask="999.999.999-99" value={userData.userCpf} maskPlaceholder={null} disabled />
							</InputWrapper>

							<InputWrapper>
								<Label>E-mail</Label>
								<Input id="userEmail" value={userData.userEmail} disabled />
							</InputWrapper>

							<InputWrapper>
								<Label>Telefone</Label>
								<MaskInput id="userPhone" mask="(99) 99999-9999" value={userData.userPhone} maskPlaceholder={null} disabled />
							</InputWrapper>
						</Card>

						<Button width="100%" onClick={confirmReservation}>Continuar</Button>
					</>
				)}
		</>
	);
};

export default ValidateAccreditationUserData;
