import React, { useEffect, useContext } from 'react';

import AnalyticsContext from 'context/analytics';
import EventService from 'services/EventService';

let ga4Code: string;

const AnalyticsManagement = ({eventId}: any) => {
	const { state, setState } = useContext(AnalyticsContext);

	useEffect(() => () => {
		//@ts-ignore
		window[`ga-disable-${ga4Code}`] = true;
	}, [])

	useEffect(() => {
		if (state.active && state.code && state.name === eventId.toString()) {
			// @ts-ignore
			window[`ga-disable-${state.code}`] = false;
		}
		EventService.getMarketingData(eventId)
			.then(({data}: any) => {
				const index = data.findIndex((el: any) => el.type === 'ANALYTICS');

				if (index !== -1) {
					setAnalyticsValues(data[index], eventId.toString());

					if (data[index].status === 'ENABLED' && data[index].options.code) {
						ga4Code = data[index].options.code;
						initAnalytics(data[index].options.code, eventId.toString());
					}
				}
			})
	}, [eventId]);

	const setAnalyticsValues = (analytics: any, name: string) => {
		setState({
			active: (analytics.status === 'ENABLED'),
			code: analytics.options.code,
			name: name,
		})
	}

	const initAnalytics = (code: string, name: string) => {
		window.gtag('config', code);
	}

	return (<></>);
}

export default AnalyticsManagement;
